<div class="component"
     *ngIf="legend">

    <div class="units">
        %
    </div>

    <div *ngFor="let item of legend.items.slice(0).reverse()"
         class="item">
        <div class="text">{{getPercent(item)}}</div>
        <div class="block"
             [ngStyle]="{ 'background-color': item.color }"></div>
    </div>

</div>
