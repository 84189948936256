import { Solar } from "@websolar/ng-websolar";

export class ProjectHelper {

    public static getZoomLevel(projectSize: Solar.ProjectSize): number {
        if (projectSize == "3x") {
            return 18;
        }
        else if (projectSize == "2x") {
            return 19;
        }
        else {
            // default
            return 20;
        }
    }

    public static getDemBoxSize(projectSize: Solar.ProjectSize): number {
        let boxSize: number;
        if (projectSize == "3x") {
            boxSize = 300;
        }
        else if (projectSize == "2x") {
            boxSize = 200;
        }
        else {
            boxSize = 50;
        }

        return boxSize;
    }
}