<div class="component">
    <app-navbar></app-navbar>
    <div class="w-container">

        <div class="project-wrapper">
            <div class="project-details">
                <h1>{{"Project details" | translate}}</h1>


                <div class="form"
                     *ngIf="!isLoading">

                    <mat-form-field>
                        <mat-label>{{"Project name" | translate}}</mat-label>
                        <input matInput
                               [(ngModel)]="project.name"
                               [placeholder]="'Enter the project name' | translate">
                    </mat-form-field>
                    <mat-form-field class="hide-sm">
                        <mat-label>{{"Description" | translate}}</mat-label>
                        <input matInput
                               [(ngModel)]="project.description"
                               [placeholder]="'Enter the project description' | translate">
                    </mat-form-field>
                    <div class="search-wrapper">
                        <app-location-search [placeholder]="'Enter the project address' | translate"
                                             [address]="address"
                                             (locationChange)="onInputLocationChange($event)"></app-location-search>
                    </div>
                    <mat-form-field>
                        <input matInput
                               [(ngModel)]="lngLat"
                               (keydown.enter)="onLngLatEnter()"
                               maxlength="22"
                               [placeholder]="'Enter longitude,latitude' | translate">
                    </mat-form-field>
                    <div class="format">
                        {{"Format" | translate}}: 120.360612,29.324325
                    </div>

                    <div *ngIf="this.project.type != 'ground'"
                         class="commercial-option hide-sm">
                        <mat-form-field>
                            <mat-label>{{"Project Size" | translate}}</mat-label>
                            <mat-select [(ngModel)]="project.projectSize">
                                <mat-option *ngFor="let r of projectSizes"
                                            [value]="r.id">
                                    {{r.name | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="sumbit">
                        <button mat-flat-button
                                color="primary"
                                [disabled]="!isValid()"
                                (click)="save()">
                            {{"Continue" | translate}}
                        </button>
                    </div>
                </div>


                <div class="loader-wrapper"
                     *ngIf="isLoading">
                    <mat-spinner></mat-spinner>
                </div>
            </div>

            <div class="location-wrapper">
                <app-map [location]="project.location"
                         [projectSize]="project.projectSize"
                         (locationChange)="onMapLocationChange($event)"></app-map>
                <div class="banner hide-sm"
                     *ngIf="bannerText">
                    <mat-icon>place</mat-icon>
                    <span>{{bannerText | translate}}</span>
                </div>
            </div>

        </div>


    </div>

</div>
