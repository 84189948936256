<div class="component">
    <mat-form-field>
        <input type="text"
               matInput
               [placeholder]="placeholder"
               [formControl]="control"
               [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete"
                          [displayWith]="showName">
            <mat-option *ngFor="let group of resultOptions | async"
                        [value]="group">
                {{group.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>
