import * as THREE from "three";
import { Solar } from "@websolar/ng-websolar";

export class Geometry {
    /**
     * get polygon area
     * @param points 
     * @returns 
     */
    public static getArea(points: Solar.Point[]): number {
        let total = 0;

        for (let i = 0, l = points.length; i < l; i++) {
            let addX = points[i].x;
            let addY = points[i == points.length - 1 ? 0 : i + 1].y;
            let subX = points[i == points.length - 1 ? 0 : i + 1].x;
            let subY = points[i].y;

            total += (addX * addY * 0.5);
            total -= (subX * subY * 0.5);
        }

        return Math.abs(total);
    }

    public static getDistance(p1: Solar.Point, p2: Solar.Point) {
        return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
    }

    public static getBbox3(points: Solar.Point[]): THREE.Box3 {
        const bbox = new THREE.Box3();
        for (const p of points) {
            bbox.expandByPoint(new THREE.Vector3(p.x, p.y, p.z || 0))
        }
        return bbox;
    }

}