<mat-form-field (click)="openSearch($event)">
    <mat-label>{{"Solar panel" | translate}}</mat-label>
    <mat-icon matPrefix>search</mat-icon>
    <input matInput
           [ngModel]="moduleName"
           readonly="readonly"
           type="search"
           [placeholder]="'Click to search panel' | translate">

    <button (click)="clear($event)"
            matSuffix
            mat-icon-button
            color="primary"
            [matTooltip]="'Clear' | translate">
        <mat-icon fontSet="material-icons-outlined">clear</mat-icon>
    </button>

    <button matSuffix
            mat-icon-button
            color="primary">
        <mat-icon fontSet="material-icons-outlined">folder_open</mat-icon>
    </button>

</mat-form-field>
