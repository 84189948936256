<div class="component">

    <h2>{{"Target Capacity Ratio" | translate}}</h2>
    <mat-form-field>
        <input matInput
               [(ngModel)]="capacityRatio"
               (ngModelChange)="onCapacityRatioChange()"
               (keydown)="preventDecimal($event)"
               type="number"
               [min]="0"
               [max]="1000">
        <span matSuffix>%</span>
    </mat-form-field>
    <div class="actual-ratio">
        {{"Actual capacity ratio" | translate}}: {{ actualCapacityRatio }}%
    </div>

    <div class="voltate-option">
        <mat-form-field>
            <mat-label>{{"Voltage level" | translate}}</mat-label>
            <mat-select [(ngModel)]="project.voltageLevel"
                        (ngModelChange)="onVoltageChange()">
                <mat-option *ngFor="let volt of voltageList"
                            [value]="volt.id">
                    {{volt.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <h2 class="title">{{"Inverter Configuration" | translate}}</h2>

    <div class="btns">
        <button mat-flat-button
                color="primary"
                (click)="autoPlacement()"
                [disabled]="isProcessing">
            <span *ngIf="!isProcessing">{{"Auto" | translate}}</span>
            <span *ngIf="isProcessing">{{"Processing..." | translate}}</span>
        </button>
        <button mat-flat-button
                color="primary"
                (click)="manualPlacement()"
                [disabled]="isProcessing">
            {{"Manual" | translate}}
        </button>
    </div>

    <div class="stat-info"
         *ngIf="invertersCount > 0">
        <div class="stat-content">
            <span>{{"Total" | translate}}</span>

            <app-icon [src]="'icons/module.svg' | assetUrl"
                      class="large-icon"></app-icon>
            <span>{{modulesInString}}/{{totalModules}}</span>

            <app-icon [src]="'icons/string.svg' | assetUrl"
                      class="large-icon"></app-icon>
            <span>{{completedStrings}}/{{totalStrings}}</span>

            <app-icon [src]="'icons/optimizer.svg' | assetUrl"
                      class="large-icon"></app-icon>
            <span>{{completedOptimizerCount}}/{{optimizerCount}}</span>
        </div>

        <button mat-icon-button
                [matTooltip]="'Highlight unmapped modules' | translate"
                (click)="highlightUnmapped()">
            <mat-icon class="large-icon">tips_and_updates</mat-icon>
        </button>
    </div>

    <!-- Inverters -->

    <div class="inverters-groups"
         *ngIf="invertersCount > 0">

        <div class="inverter-group"
             *ngFor="let group of groups">

            <div class="group"
                 (click)="group.expanded = !group.expanded">
                <app-icon class="large-icon"
                          [src]="'icons/inverter.svg' | assetUrl"></app-icon>
                <div class="group-content">
                    <span>{{group.inverters.length}} X {{group.name}}</span>
                </div>
                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="group.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!group?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>

            <!-- Inverter List -->
            <div class="inverters-list"
                 *ngIf="group.expanded">
                <div *ngFor="let invItem of group.inverters; let idx = index;"
                     class="inverter">
                    <div class="inverter-wrapper"
                         [class.highlight]="invItem.object.id == pickedObjectId"
                         (click)="toggleState(invItem)">
                        <div class="inverter-content"
                             [matTooltip]="'Change inverter description' | translate"
                             (click)="editInverter($event, invItem)">
                            <app-icon class="inv-icon"
                                      [src]="'icons/inverter.svg' | assetUrl"></app-icon>
                            <span>
                                {{formatIndex(idx + 1)}} {{getInverter(invItem).name}}</span>
                        </div>

                        <app-icon (click)="editOptimizer($event, invItem)"
                                  [src]="'icons/optimizer.svg' | assetUrl"
                                  class="large-icon"></app-icon>

                        <mat-icon inline="true"
                                  class="large-icon"
                                  [matTooltip]="'Delete the inverter' | translate"
                                  (click)="deleteInverter($event, invItem);">delete</mat-icon>

                        <div class="expander"
                             [matTooltip]="(itemState[invItem.object.id].expanded ? 'Hide the MPPT connection' : 'Show the MPPT connection') | translate">
                            <mat-icon inline="true"
                                      color="primary"
                                      *ngIf="itemState[invItem.object.id].expanded">
                                expand_less
                            </mat-icon>
                            <mat-icon inline="true"
                                      color="primary"
                                      *ngIf="!itemState[invItem.object.id].expanded">
                                expand_more
                            </mat-icon>
                        </div>
                    </div>
                    <div class="inverter-details">
                        <span *ngIf="getInverter(invItem).color"
                              [ngStyle]="{ 'background-color': getInverter(invItem).color }"
                              class="color-item"></span>


                        <span>
                            <app-icon [src]="'icons/string.svg' | assetUrl"></app-icon>
                            <span>{{getWiredStrings(invItem)}}/{{getStringCount(invItem)}}</span>
                        </span>

                        <span>
                            <app-icon [src]="'icons/module.svg' | assetUrl"></app-icon>
                            <span>{{getModules(invItem)}}</span>
                        </span>

                        <span>
                            <app-icon [src]="'icons/capacity-ratio.svg' | assetUrl"></app-icon>
                            <span>{{getCapacity(invItem)}}%</span>
                        </span>

                        <span *ngIf="getOptimizer(invItem)">
                            <div class="divider"></div>
                            <app-icon [src]="'icons/optimizer.svg' | assetUrl"></app-icon>
                            <div>{{getOptimizer(invItem)?.model}}</div>
                        </span>
                    </div>

                    <!-- MPPT LIST -->
                    <div class="mppt-list"
                         *ngIf="itemState[invItem.object.id].expanded">

                        <div class="mppt"
                             *ngFor="let mppt of invItem.children; let mpptIdx = index;">

                            <div class="mppt-wrapper"
                                 (click)="toggleState(mppt)">
                                <div class="mppt-content">
                                    <span>{{mppt.name | translate}} {{formatIndex(mpptIdx + 1)}}</span>
                                </div>
                                <!-- Strings List -->
                                <div class="strings-list">
                                    <div *ngFor="let strTreeItem of strTreeItems[mppt.object.id]; let strIdx = index;">
                                        <div class="string"
                                             [class.highlight]="strTreeItem.attachedString?.object?.id == pickedObjectId"
                                             (click)="highlightOnDesign(strTreeItem.attachedString)">
                                            <div class="string-wrapper">
                                                <div class="string-content"
                                                     [matTooltip]="(strTreeItem.attachedString ? 'Highlight the string' : '') | translate">
                                                    <div class="string-dot"></div>
                                                    <app-icon [src]="'icons/string.svg' | assetUrl"></app-icon>
                                                    <span>{{formatIndex(strIdx + 1)}}</span>

                                                    <ng-container *ngIf="strTreeItem.attachedString">

                                                        <app-icon [src]="'icons/module.svg' | assetUrl"></app-icon>
                                                        <span>{{getModules(strTreeItem.attachedString)}}</span>

                                                        <mat-icon inline="true">bolt</mat-icon>
                                                        <span>{{getIsc(strTreeItem.attachedString)}}A</span>

                                                        <mat-icon inline="true">bolt</mat-icon>
                                                        <span>{{getVdc(strTreeItem.attachedString)}}Vdc</span>

                                                        <span>{{strTreeItem.attachedString.name}}</span>
                                                    </ng-container>
                                                </div>

                                                <ng-container *ngIf="strTreeItem.attachedString">
                                                    <app-icon [src]="'icons/disconnect.svg' | assetUrl"
                                                              [matTooltip]="'Disconnect the string' | translate"
                                                              (click)="disconnectString($event, mppt, strTreeItem.attachedString)"></app-icon>
                                                    <mat-icon inline="true"
                                                              [matTooltip]="'Delete the string' | translate"
                                                              (click)="deleteString($event, strTreeItem.attachedString)">delete</mat-icon>
                                                </ng-container>

                                                <ng-container *ngIf="!strTreeItem.attachedString">

                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item
                                                                *ngFor="let availString of availStrings"
                                                                [matTooltip]="'Select string add to the MPPT' | translate"
                                                                (click)="attachString(invItem, mppt, availString.string)">
                                                            {{availString.name}}
                                                        </button>
                                                    </mat-menu>

                                                    <app-icon [src]="'icons/hands.svg' | assetUrl"
                                                              [matTooltip]="'Pick string add to the MPPT' | translate"
                                                              (click)="pickString(invItem, mppt)"></app-icon>
                                                    <mat-icon inline="true"
                                                              [matTooltip]="'Select string add to the MPPT' | translate"
                                                              [matMenuTriggerFor]="menu">add</mat-icon>
                                                </ng-container>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>


    <!-- Optimizer -->
    <h2 class="title">{{ "Optimizer configuration" | translate}}</h2>
    <div class="actions-wrapper">
        <button mat-stroked-button
                color="primary"
                [disabled]="!hasOptimizer || isOptimizerPlacing || isOptimizerRunning"
                (click)="placeOptimizer()">
            <mat-icon>cable</mat-icon>
            {{"Place Optimizer" | translate}}
        </button>

        <button mat-stroked-button
                color="primary"
                [disabled]="!hasOptimizer || isOptimizerPlacing || isOptimizerRunning"
                (click)="deleteOptimizer()">
            <mat-icon>clear</mat-icon>
            {{"Delete Optimizer" | translate}}
        </button>

        <button mat-stroked-button
                color="primary"
                [disabled]="!hasOptimizer || isOptimizerPlacing || isOptimizerRunning"
                (click)="runAutoOptimizers()">
            <mat-icon>build</mat-icon>
            {{"Auto Placement" | translate}}
        </button>

        <div *ngIf="isOptimizerRunning">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>


</div>
