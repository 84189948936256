import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService, Solar, WebSolarBatteryService } from '@websolar/ng-websolar';
import { CloneTool } from 'src/app/core/clone.tool';


@Component({
    selector: 'app-bom-record-edit',
    templateUrl: './bom-record-edit.component.html',
    styleUrls: ['./bom-record-edit.component.scss']
})
export class BOMRecordEditComponent {

    public record: Solar.BOMRecord;

    private _project: Solar.Project;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: {
            record: Solar.BOMRecord,
            project: Solar.Project
        },
        private _dialogRef: MatDialogRef<BOMRecordEditComponent>,
        private _notify: NotifyService,
        private _translate: TranslateService
    ) {
        this._project = _data.project;
        this.record = CloneTool.clone(_data.record || this.getDefault());
    }

    private getDefault(): Solar.BOMRecord {
        return {
            name: this._translate.instant("Device"),
            model: "",
            quantity: 1,
            quantityUnit: "",
            totalUnit: "",
            price: 0,
            userDefined: true
        }
    }

    public async save() {
        try {

            if (!this._project) {
                throw `project not defined`
            }
            if (!this._project.finance) {
                throw `finance not defined`
            }
            if (!this._project.finance.userDefinedRecords) {
                this._project.finance.userDefinedRecords = [];
            }
            // get index of original record
            const idx = this._project.finance.userDefinedRecords.indexOf(this._data.record);
            if (idx >= 0) {
                // update the record
                this._project.finance.userDefinedRecords[idx] = this.record;
            }
            else {
                // add new
                this._project.finance.userDefinedRecords.push(this.record);
            }

            this._dialogRef.close(this.record);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public isCanSave() {
        if (!this.record.price ||
            !this.record.quantity ||
            !this.record.name) {
            return false;
        }

        return true;
    }

    public close() {
        this._dialogRef.close(null);
    }

    public getTotalCost() {
        return (this.record.price || 0) * (this.record.quantity || 0);
    }

}
