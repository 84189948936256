<div class="component">
    <div class="component-content">
        <h2>
            {{"Terrain Categories" | translate}}
        </h2>
        <div class="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>{{"Terrain Category" | translate}}</th>
                        <th>{{"Description" | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>A</td>
                        <td>
                            {{"Sea surface, islands, coasts, lake shores and desert areas" | translate}}
                        </td>
                    </tr>
                    <tr>
                        <td>B</td>
                        <td>
                            {{"Fields, countryside, jungles, hills and towns with sparse houses" | translate}}
                        </td>
                    </tr>
                    <tr>
                        <td>C</td>
                        <td>
                            {{"Densely built urban areas" | translate}}
                        </td>
                    </tr>
                    <tr>
                        <td>D</td>
                        <td>
                            {{"Urban areas with dense buildings and tall houses" | translate}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="btn-wrapper">
            <button mat-stroked-button
                    mat-dialog-close
                    color="primary">
                {{"Close" | translate}}
            </button>
        </div>
    </div>
</div>
