import * as XLSX from "xlsx";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Solar, SolarInstance, WebSolarReportService, NotifyService, WebSolarShadingService, WebSolarEventsService, WebSolarProjectService, WebSolarLossesService, WebSolarBatteryService } from '@websolar/ng-websolar';
import { DialogService } from 'src/app/services/dialog.service';
import { AIKO } from 'src/app/types/aiko.types';
import { environment } from "src/environments/environment";
import { FinanceService } from "src/app/services/finance.service";
import moment from "moment";
import { TranslateService } from "@ngx-translate/core";


@Component({
    selector: 'app-battery-panel',
    templateUrl: './battery-panel.component.html',
    styleUrls: ['./battery-panel.component.scss']
})
export class BatteryPanelComponent implements OnChanges, OnDestroy {

    @Input() project!: AIKO.ProjectExt;

    @Input() instance!: SolarInstance;

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    @Output() navigationRequest = new EventEmitter<AIKO.MenuMode>();

    public consumption!: Solar.ConsumptionOutput;

    public isReady = true;

    public showWarn = false;

    public errorMessage = "";

    public isLoading = false;

    public region = environment.region;

    private _isInit = false;

    private _cancellation: Solar.Cancellation = { cancelled: false }

    constructor(
        private _reportService: WebSolarReportService,
        private _financeService: FinanceService,
        private _notify: NotifyService,
        private _shadingService: WebSolarShadingService,
        private _eventService: WebSolarEventsService,
        private _projectService: WebSolarProjectService,
        private _lossesService: WebSolarLossesService,
        private _batteryService: WebSolarBatteryService,
        private _dialogService: DialogService,
        private _translate: TranslateService
    ) {
        this.attachToEvents();
    }

    /**
     * Attaches event listeners to handle new project and project closed events.
     * If either event occurs, the shading analysis is cancelled.
     */
    private attachToEvents() {
        this._eventService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "new_project" || opt.name == "project_closed") {
                // cancel the shading analysis
                this._cancellation.cancelled = true;
            }
        });
    }

    public ngOnDestroy(): void {
        // cancel the simulation
        if (this._cancellation) {
            this._cancellation.cancelled = true;
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.project) {
            this.init();
        }
    }

    private async init() {
        try {
            if (this._isInit) {
                // already initalized
                return;
            }
            this._isInit = true;

            this.isLoading = true;


            this.isReady = this.project.simulationStatus == "done";
            if (!this.isReady) {
                // run simulation
                await this.run();
            }

            this.consumption = this._financeService.getConsumptionOutput(this.project);

            this.createIrradiance();

            // 
            // Batteries
            //
            const battery = this.project.electrical.battery;
            if (battery) {
                const batteriesDb = await this._batteryService.find({ id: battery._id })
                const batteryDb = batteriesDb[0];
                if (!batteryDb) {
                    this.project.electrical.battery = undefined;
                    this.project.electrical.batteryCount = 0;

                    await this._dialogService.confirm({
                        title: ``,
                        text: `The 'Battery' you selected has been deleted, please choose a new one`,
                        hideCancel: true
                    })
                }
                else {
                    // update the reference
                    this.project.electrical.battery = batteryDb;
                }
            }


        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Runs the shading service for the current project and instance.
     * Updates the simulation status and saves the project if it has an ID.
     * Handles errors and cancels the simulation if necessary.
     */
    public async run() {
        try {
            this.isReady = false;

            this._cancellation = { cancelled: false }

            const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
            const hasTiltSegments = segments.find(s => s.racking == "fixed_tilt" && s.module && s.layoutGrouping?.enabled);

            await this._shadingService.run({
                project: this.project,
                instance: this.instance,
                cancellation: this._cancellation,
                saveResults: true,
                showPercentage: true,
                useModules: Boolean(hasTiltSegments)
            });

            // mark the simulation as done
            this.project.simulationStatus = "done";

            // load DC losses
            try {
                this.project.dcLosses = await this._lossesService.getDesignLosses(this.instance.getObjects({}), this.project);
            }
            catch (err) {
                console.error(`failed get DC losses`, err)
            }

            if (this.project._id) {
                // save the project
                const objects = this.instance.getObjects({});
                this._projectService.save({
                    project: this.project,
                    objects: objects
                })
            }

            this.isReady = true;
        }
        catch (err) {
            // don't show error when simulation cancelled
            if (err != "operation cancelled") {
                this._notify.error(err);
                this.errorMessage = "Simulation failed";
            }
        }
    }

    /**
     * Creates the irradiance for the current instance.
     * If the instance is not available, the function returns early.
     * The irradiance is restored from the modules and assigned to the legend variable.
     * If the legend is not available, the function returns early.
     * The irradiance layer is then shown on the instance.
     */
    private createIrradiance() {
        if (!this.instance) {
            return;
        }

        // restore the irradiance from modules
        this._shadingService.createIrradianceFromDesign({
            project: this.project,
            instance: this.instance,
            legendMin: 0,
            legendSegmentation: true,
            showPercentage: true,
            cancellation: { cancelled: false }
        })

        // show irradiance layer
        //
        this.instance.layers.irradiance = true;
        this.instance.layers.update();
    }

    public onChange() {
        if (this.project) {
            this.consumption = this._financeService.getConsumptionOutput(this.project);
        }
    }

    public onBatteryChange() {
        this.onChange();
    }

    /**
     * Check if user ready for the next step
     * @returns 
     */
    public isNextStepReady() {
        if (this.project.electrical) {
            return true;
        }
        return false;
    }

    public downloadConsumption() {
        if (!this.project) {
            return;
        }

        let workbook: XLSX.WorkBook | undefined;
        if (this.project.importedConsumption) {
            // Prepare data for export
            const data = this.project.importedConsumption.map((item) => ({
                'Data date': `${item.month}/${item.day}/${item.year}`,
                'Time': `${item.hr}:${item.min}`,
                'Total active power (kW)': item.value
            }));

            // Create a new workbook and worksheet
            const worksheet = XLSX.utils.json_to_sheet(data);
            workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, this._translate.instant('Consumption'));
        }
        else if (this.project.consumption) {
            // Prepare data for export
            const total = this.project.consumption.monthsConsumptions.reduce((prev, cur) => prev + cur.value, 0);
            const hoursConsumption = this._reportService.getHoursConsumptionsPerProfile(
                this.project.consumption.profile,
                total,
                moment().startOf("year").toDate(),
                moment().endOf("year").toDate()
            );

            // Prepare data for export
            const year = moment().format("YYYY");
            const data = hoursConsumption.map((item) => ({
                'Data date': `${item.month}/${item.day}/${year}`,
                'Time': `${item.hour}:00`,
                'Total active power (kW)': item.value
            }));

            // Create a new workbook and worksheet
            const worksheet = XLSX.utils.json_to_sheet(data);
            workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, this._translate.instant('Consumption'));
        }

        if (!workbook) {
            return;
        }

        // Generate XLSX file and trigger download
        const xlsxData = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([xlsxData], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${this.project.name} + ${this._translate.instant("consumption data table")}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

}
