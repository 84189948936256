import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotifyService, Solar, WebSolarCustomMapComponent, WebSolarTexturedMeshUploadComponent } from '@websolar/ng-websolar';
import { AMapMapService } from 'src/app/services/amap.map.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-map-picker',
    templateUrl: './map-picker.component.html',
    styleUrls: ['./map-picker.component.scss']
})
export class MapPickerComponent {

    public type: "google" | "amap" = "google";

    @Input() project: Solar.Project | undefined;

    constructor(
        private _matDialog: MatDialog,
        private _notify: NotifyService,
        private _amapService: AMapMapService
    ) {
        this.type = environment.region == "cn" ? "amap" : "google";
    }

    public async uploadCustomMap() {
        try {
            if (!this.project) {
                return;
            }
            if (this.project.customMap &&
                this.project.customMap.id) {
                if (this.project.mapType != "intergrated") {
                    if (this.project.mapType != "custom") {
                        this.project.mapType = "custom";
                        return;
                    }
                }
            }

            let background = "";
            let backgroundSize = 0;
            if (this.type == "amap") {
                const result = await this._amapService.getMapScreenshotAsCanvas(this.project);
                background = result.canvas.toDataURL();
                backgroundSize = result.boxSize;
            }

            const dialogRef = this._matDialog.open(WebSolarCustomMapComponent, {
                autoFocus: false,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    project: this.project,
                    background: background,
                    backgroundSize: backgroundSize
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.project.mapType = "custom";
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async uploadTexturedMesh() {
        try {
            if (!this.project) {
                return;
            }
            if (this.project.demObj) {
                if (this.project.mapType != "textured_mesh") {
                    this.project.mapType = "textured_mesh";
                    return;
                }
            }

            const dialogRef = this._matDialog.open(WebSolarTexturedMeshUploadComponent, {
                autoFocus: false,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    project: this.project
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.project.mapType = "textured_mesh";
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Sets the AMap for the current project.
     * If the project is not defined, the function returns early.
     */
    public async setAMap() {
        if (!this.project) {
            return;
        }
        if (this.project.mapType == "custom") {
            // upload the amap
            await this._amapService.uploadStaticImage(this.project);
        }
        this.project.mapType = "intergrated";
    }


}
