<div class="component"
     *ngIf="project && project.permit">

    <h2>
        {{"Solar Permit Package" | translate}}
    </h2>


    <div class="text">{{"A permit package includes the following pages" | translate}}</div>

    <div class="list">
        <div>
            <mat-icon>done</mat-icon>
            {{"Project summary" | translate}}
        </div>
        <div>
            <mat-icon>done</mat-icon>
            {{"SITE PLAN" | translate}}
        </div>
        <div><mat-icon>done</mat-icon>
            {{"Single-line diagram" | translate}}
        </div>
        <div><mat-icon>done</mat-icon>
            {{"Electrical Calculations" | translate}}
        </div>
        <div><mat-icon>done</mat-icon>
            {{"PV safety labels" | translate}}
        </div>
        <div><mat-icon>done</mat-icon>
            {{"Attachment plan(s)" | translate}}
        </div>
    </div>


    <ng-container *ngIf="isSigned">

        <div *ngIf="hasErrors()"
             class="error-wrapper">
            <mat-icon>warning</mat-icon>
            <div class="text">
                {{getErrorMessage() | translate}}
            </div>
        </div>

        <div class="properties">

            <mat-form-field>
                <mat-label>{{"Owner Name" | translate}}</mat-label>
                <input matInput
                       [(ngModel)]="project.permit.owner">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"Phone" | translate}}</mat-label>
                <input matInput
                       [(ngModel)]="project.permit.phone">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"Address" | translate}}</mat-label>
                <input matInput
                       [(ngModel)]="project.permit.address">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{"Utility Company" | translate}}</mat-label>
                <input matInput
                       [(ngModel)]="project.permit.utilityCompany">
            </mat-form-field>

            <div>
                <mat-checkbox [(ngModel)]="isContractorAvail"
                              (ngModelChange)="onContractroChange()">
                    {{"I have contractor/installer" | translate}}
                </mat-checkbox>
            </div>

            <ng-container *ngIf="isContractorAvail">
                <mat-form-field>
                    <mat-label>{{"Contractor Name" | translate}}</mat-label>
                    <input matInput
                           [(ngModel)]="project.permit.contractorName">
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{"Contractor Phone" | translate}}</mat-label>
                    <input matInput
                           [(ngModel)]="project.permit.contractorPhone">
                </mat-form-field>

            </ng-container>
        </div>

        <div class="btn-wrapper">
            <button (click)="run()"
                    mat-flat-button
                    color="primary"
                    [disabled]="hasErrors()"
                    *ngIf="!isLoading">
                <mat-icon fontSet="material-icons-outlined">play_arrow</mat-icon>
                {{"Generate Package" | translate}}
            </button>
        </div>

        <div *ngIf="isLoading"
             class="loader-wrapper">
            <div>{{"Building permit package..." | translate}}</div>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </ng-container>


</div>
