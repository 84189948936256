import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    NotifyService, Solar, SolarInstance,
    WebSolarAuthService, WebSolarEventsService,
    WebSolarProjectService, WebSolarProjectStateService,
    WebSolarShareService, WebSolarTransactionService
} from '@websolar/ng-websolar';
import { AIKO } from '../types/aiko.types';
import { StateService } from '../services/state.service';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnChanges {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Input() disabledTools = false;

    @Input() control!: AIKO.ToolbarControl;

    @Output() projectSaved = new EventEmitter<void>();

    public isAutoModeling = true;

    public isSigned = false;

    public isAutoFix = false;

    public isSaving = false;

    public isAdmin = false;

    public isDemo = false;

    public showPremium = false;

    public isDEMAvailable = false;

    public isTexturedMesh = false;

    public isGroundSystem = false;

    constructor(
        private _auth: WebSolarAuthService,
        private _webSolarState: WebSolarProjectStateService,
        private _router: Router,
        private _notify: NotifyService,
        private _eventsService: WebSolarEventsService,
        private _projectService: WebSolarProjectService,
        private _transactionService: WebSolarTransactionService,
        private _state: StateService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["instance"] && this.instance) {
            // subscribe to events
            this.onInstance();
        }


        this.isSigned = this._auth.isSigned();
        this.isAdmin = this._auth.isAdmin();

        this.isAutoModeling = this._webSolarState.isAutoModeling;

        if (this.project) {
            this.isGroundSystem = this.project.type == "ground";
            this.isDemo = this.project.shareId == "demo";

            if (this._auth.isPremium()) {

                if (this._auth.getAuthData()?.username == "demo@websolar.cloud") {
                    // allow saving for super admin
                    this.isDemo = false;
                    this._webSolarState.isDemo = false;
                }

                this.showPremium = false;
            }
            else {
                this.showPremium = true;
            }
        }
    }

    @HostListener('window:keydown.meta.z', ['$event'])
    public handleUndoWithCommandKey(event: KeyboardEvent) {
        this.undo();
    }

    @HostListener('window:keydown.control.z', ['$event'])
    public handleUndoKey(event: KeyboardEvent) {
        this.undo();
    }

    @HostListener('window:keydown.control.y', ['$event'])
    public handleRedoKey(event: KeyboardEvent) {
        this.redo();
    }

    @HostListener('window:keydown.control.shift.z', ['$event'])
    public handleRedoWithShift(event: KeyboardEvent) {
        this.redo();
    }

    private onInstance() {
        //
    }


    public async onSaveProject() {
        try {
            this.isSaving = true;

            await this.saveProjectNow();

            // send event to parent
            this.projectSaved.emit();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isSaving = false;
        }
    }


    /**
    * Save the current project
    */
    private async saveProjectNow() {
        console.log("save");
        try {
            let objects: Solar.Object[] | undefined;
            if (this.instance) {
                objects = this.instance.getObjects({});
            }

            await this._projectService.save({
                project: this.project,
                objects: objects
            });

            this._notify.info(`Project successfully saved`);

            this._eventsService.send({ name: "project_saved", params: null });
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async signIn() {
        this._router.navigate(["/sign-in"], { queryParams: { cleanup: true } })
    }

    public signOut() {
        this._auth.logout();
        this._router.navigate(["/sign-in"], { queryParams: { cleanup: true } })
    }

    public activateTool(type: Solar.ToolType) {
        this.instance.activateTool({ type: type, params: null }, this.project);
    }

    public async copy() {


        try {
            if (this.instance.getActivedToolName() == "copy") {
                // already activated
                return;
            }
            const solarObjects = this.instance.getSelectedObjects();
            if (!solarObjects.length) {
                throw `Please select the object(s) first`
            }

            // will handeled by design-page
            this._eventsService.send({ name: "copy_tool_activated", params: null });

            await this.instance.activateTool({
                type: "copy", params: {
                    id: solarObjects.map(o => o.id),
                    inputMode: "xy"
                }
            }, this.project);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public zoomToExtent() {
        this.instance.zoomToFit(false);
    }

    public toggle3d() {
        // toggle visibility
        this.instance.layers.objects3d = !this.instance.layers.objects3d;

        // put the same visibility to 3d edges as well
        this.instance.layers.objects3dEdges = this.instance.layers.objects3d;

        // update layers
        this.instance.layers.update();
    }


    public print() {
        window.print();
    }

    public updateLayers() {
        this.instance.layers.update();
    }

    public autoModellingChange() {
        try {
            this._webSolarState.setAutoModeling(this.isAutoModeling);
            console.log("auto", this.isAutoModeling)
        }
        catch (err) {
            console.error(err);
        }
    }

    public goToLink(link: string) {
        this._router.navigate([link]);
    }

    public undo() {
        if (!this.canUndo()) {
            return;
        }
        this._transactionService.undo(this.instance);
    }

    public redo() {
        if (!this.canRedo()) {
            return;
        }
        this._transactionService.redo(this.instance);
    }

    public canUndo(): boolean {
        if (!this.instance) {
            return false;
        }
        return this._transactionService.canUndo();
    }

    public canRedo(): boolean {
        if (!this.instance) {
            return false;
        }
        return this._transactionService.canRedo(this.instance);
    }

    public async activateRuler() {
        try {
            await this.instance.activateTool({ type: "ruler", params: { keepResultOnScreen: true } }, this.project);
        }
        catch (err) {
            this._notify.error(err);
        }

    }
}
