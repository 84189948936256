import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportFinanceComponent } from './report-finance/report-finance.component';
import { ReportOverviewComponent } from './report-overview/report-overview.component';
import { ReportsPanelComponent } from './reports-panel/reports-panel.component';
import { AppMaterialModule } from '../app.material';
import { NgWebsolarModule } from '@websolar/ng-websolar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from '../app.shared.module';
import { ReportLossesComponent } from './report-losses/report-losses.component';
import { ReportLossesBarComponent } from './report-losses-bar/report-losses-bar.component';
import { ReportConsumptionPipeComponent } from './report-consumption-pipe/report-consumption-pipe.component';
import { ReportProductionComponent } from './report-production/report-production.component';
import { ReportBillPipeComponent } from './report-bill-pipe/report-bill-pipe.component';
import { ReportDesignLayoutComponent } from './report-design-layout/report-design-layout.component';
import { BOMRecordEditComponent } from './bom-record-edit/bom-record-edit.component';

@NgModule({
    declarations: [
        ReportFinanceComponent,
        ReportOverviewComponent,
        ReportsPanelComponent,
        ReportLossesComponent,
        ReportLossesBarComponent,
        ReportConsumptionPipeComponent,
        ReportProductionComponent,
        ReportBillPipeComponent,
        ReportDesignLayoutComponent,
        BOMRecordEditComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        NgWebsolarModule,
        NgxChartsModule,
        FormsModule,
        AppSharedModule
    ],
    exports: [
        ReportFinanceComponent,
        ReportOverviewComponent,
        ReportsPanelComponent,
        ReportConsumptionPipeComponent,
        ReportProductionComponent,
        ReportBillPipeComponent
    ],
    providers: [],
})
export class AppReportModule {}
