import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyService, Solar } from '@websolar/ng-websolar';
import { AnnexService } from 'src/app/services/annex.service';
import { SnowZoneService } from 'src/app/services/snowZones.service';
import { WindZoneService } from 'src/app/services/windZones.service';
import { AIKO } from 'src/app/types/aiko.types';


@Component({
    selector: 'app-zone-map',
    templateUrl: './zone-map.component.html',
    styleUrls: ['./zone-map.component.scss']
})
export class ZoneMapComponent implements OnInit {

    @ViewChild("mapElement") _mapElement!: ElementRef<HTMLElement>;

    public isLoading = true;

    public noData = false;

    public legend: { val: string, color: string }[] = [];

    public mapType = "";

    public title = "";

    private _map!: google.maps.Map;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: {
            project: Solar.Project,
            type: "wind" | "snow"
        },
        private _notify: NotifyService,
        private _windService: WindZoneService,
        private _snowService: SnowZoneService,
        private _annexService: AnnexService
    ) {
        this.mapType = this._data.type;
        this.title = `${this.mapType} Zones`
    }

    ngOnInit(): void {
        this.load();
    }

    public async load() {
        try {
            this.isLoading = true;

            this.legend = [];

            const annex = await this._annexService.findOne({ country: this._data.project.country });
            if (!annex) {
                return;
            }

            let zones: { name: string, geometry?: AIKO.ZoneGeometry }[] = [];
            if (this._data.type == "wind") {
                zones = await this._windService.find({ id: annex.windZoneIds })
            }
            else {
                zones = await this._snowService.find({ id: annex.snowZoneIds })
            }
          
            if (zones.length == 0) {
                this.noData = true;
                return;
            }

            this._map = this.createMap(this._mapElement.nativeElement) as google.maps.Map;
            this._map.setOptions({
                maxZoom: 9
            })

            // get color map
            const colorMap: { [key: string]: string } = this.getColorPallete(zones);

            // add zones to map
            for (const zone of zones) {
                if (!zone.geometry ||
                    !zone.geometry.coordinates) {
                    continue;
                }

                const val = zone.name;
                if (!val) {
                    continue;
                }

                const color = colorMap[val] || "#ff0000";

                if (!this.legend.find(l => l.val == val)) {
                    this.legend.push({
                        val: val,
                        color: color
                    });
                }

                let rings = [];

                if (zone.geometry.type == "MultiPolygon") {
                    const coords = zone.geometry.coordinates as number[][][][];
                    rings = coords.map(r => r[0]);
                }
                else {
                    const coords = zone.geometry.coordinates as number[][][];
                    rings.push(coords[0]);
                }

                for (const ring of rings) {
                    const coords = ring.map(c => ({ lng: c[0], lat: c[1] }))
                    new google.maps.Polygon({
                        paths: coords,
                        strokeColor: "transparent",
                        strokeWeight: 0,
                        fillColor: color,
                        fillOpacity: 0.35,
                        map: this._map
                    });
                }
            }

            // add marker
            const pos = new google.maps.LatLng(
                this._data.project.location.lat,
                this._data.project.location.lng);
            new google.maps.Marker({
                position: pos,
                map: this._map,
                title: "Location",
                draggable: true
            });

            // zoom to project
            this._map.setCenter(pos);
            this._map.setZoom(6);

            // sort legend
            this.legend.sort((a, b) => {
                return a.val.localeCompare(b.val);
            });
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private createMap(element: HTMLElement) {
        const center: google.maps.LatLngLiteral = { lat: 30, lng: -110 };

        return new google.maps.Map(element, {
            center,
            zoom: 2,
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            panControl: false,
            rotateControl: false,
            tilt: 0,
            maxZoom: 30,
            gestureHandling: "greedy"
        });
    }

    /**
     * Returns a color palette based on the provided zones.
     * @param zones - An array of Solar.LoadZone objects.
     * @returns An object representing the color palette, where the keys are zone values and the values are corresponding colors.
     */
    private getColorPallete(zones: { name: string, geometry?: AIKO.ZoneGeometry }[]): { [key: string]: string; } {
        const pallete: { [key: string]: string } = {};
        const legend = [
            "#afc4f9", "#698fee", "#153690",
            "#ffc800", "#f38888", "#f84040",
            "#ff7f50", "#6495ed", "#008b8b",
            "#b8860b", "#32cd32", "#8b008b",
            "#556b2f", "#ff8c00", "#2f4f4f",
            "#9400d3", "#ffd700", "#4b0082",
            "#7cfc00", "#ff00ff", "#800000",
            "#008080", "#000080", "#808000"
        ];

        const vals: string[] = [];
        for (const zone of zones) {
            const val = zone.name;
            if (!val || vals.includes(val)) {
                continue;
            }
            vals.push(val);
        }
        vals.sort((a, b) => a.localeCompare(b));
        let idx = 0;
        for (const val of vals) {
            idx++;
            if (idx >= legend.length) {
                idx = 0;
            }
            pallete[val] = legend[idx];
        }

        return pallete;
    }



}
