import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-report-losses-bar',
    templateUrl: './report-losses-bar.component.html',
    styleUrls: ['./report-losses-bar.component.scss']
})
export class ReportLossesBarComponent {

    @Input() title = "";

    @Input() value = "";

    @Input() percent = 100;

    @Input() offset = 0;

    @Input() primary = false;

}
