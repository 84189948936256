import * as THREE from "three";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { NotifyService, Solar, SolarInstance, WebSolarEventsService } from '@websolar/ng-websolar';
import { Subscription } from "rxjs";

@Component({
    selector: 'app-report-design-layout',
    templateUrl: './report-design-layout.component.html',
    styleUrls: ['./report-design-layout.component.scss']
})
export class ReportDesignLayoutComponent implements OnDestroy {

    @Input() project?: Solar.Project;

    @Input() objects?: Solar.Object[];

    @Output() instanceReady = new EventEmitter<SolarInstance>();

    public isLoading = true;

    private _instance?: SolarInstance;

    private _subscriptions: Subscription[] = [];

    constructor(
        private _notify: NotifyService,
        private _events: WebSolarEventsService
    ) {
        const sub = this._events.eventsAsObservable.subscribe((evt) => {
            if (evt.name == "resize_report_layout") {
                if (this._instance) {
                    this._instance.resize();
                }
            }
        });
        this._subscriptions.push(sub);
    }

    public ngOnDestroy(): void {
        for (const sub of this._subscriptions) {
            sub.unsubscribe();
        }
    }

    /**
     * Callback function triggered when the SolarInstance is ready.
     * @param inst The SolarInstance object.
     */
    public onInstanceReady(inst: SolarInstance) {
        this._instance = inst;
        this._instance.disableEditing();
        // disable interactive
        this._instance.zoomToBox(new THREE.Box3(
            new THREE.Vector3(-5, -5, 0),
            new THREE.Vector3(5, 5, 0),
        ), false);
        this._instance.cubeControl.disable();

        this.instanceReady.emit(this._instance);
    }

    /**
     * Callback function that is triggered when the load operation is completed.
     * Sets the isLoading flag to false.
     */
    public onLoadCompleted() {
        this.isLoading = false;
    }

}
