<div *ngIf="calcRes && project.weather"
     class="component">
    <h2>
        {{"Loads Calculation Results" | translate}}
    </h2>
    <div class="table-wrapper">

        <div class="note"
             *ngIf="project.measurement == 'metric'">
            {{"The calculation was performed according to European standards" | translate}}
        </div>

        <table>
            <thead>
                <th>{{'Name' | translate}}</th>
                <th>{{'Description' | translate}}</th>
                <th>{{'Value' | translate}}</th>
            </thead>
            <tbody>
                <tr class="tr-header">
                    <td colspan="3">
                        {{"Wind Loads" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let item of calcRes.windItems">
                    <td>{{item.name | translate}}</td>
                    <td>
                        <div class="formula">
                            <div *ngFor="let line of item.formula| splitString">
                                {{line | translate}}
                            </div>
                        </div>
                        <div class="desc">
                            <div *ngFor="let line of item.description| splitString">
                                • {{line | translate}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="value">
                            {{item.value}} {{item.unit}}
                        </div>
                    </td>
                </tr>

                <tr class="tr-header">
                    <td colspan="3">
                        {{"Snow Loads" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let item of calcRes.snowItems">
                    <td>{{item.name | translate}}</td>
                    <td>
                        <div class="formula">
                            <div *ngFor="let line of item.formula| splitString">
                                {{line | translate}}
                            </div>
                        </div>
                        <div class="desc">
                            <div *ngFor="let line of item.description| splitString">
                                • {{line | translate}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="value">
                            {{item.value}} {{item.unit}}
                        </div>
                    </td>
                </tr>

                <tr class="tr-header">
                    <td colspan="3">
                        {{"Dead Loads" | translate}}
                    </td>
                </tr>
                <tr *ngFor="let item of calcRes.deadLoadItems">
                    <td>{{item.name | translate}}</td>
                    <td>
                        <div class="formula">
                            <div *ngFor="let line of item.formula| splitString">
                                {{line | translate}}
                            </div>
                        </div>
                        <div class="desc">
                            <div *ngFor="let line of item.description| splitString">
                                • {{line | translate}}
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="value">
                            {{item.value}} {{item.unit}}
                        </div>
                    </td>
                </tr>


                <ng-container *ngIf="calcRes.combinationsItems && calcRes.combinationsItems.length">
                    <tr class="tr-header">
                        <td colspan="3">
                            {{"Load Combinations" | translate}}
                        </td>
                    </tr>
                    <tr *ngFor="let item of calcRes.combinationsItems">
                        <td>{{item.name | translate}}</td>
                        <td>
                            <div class="formula">
                                <div *ngFor="let line of item.formula| splitString">
                                    {{line | translate}}
                                </div>
                            </div>
                            <div class="desc">
                                <div *ngFor="let line of item.description| splitString">
                                    • {{line | translate}}
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="value">
                                {{item.value}} {{item.unit}}
                            </div>
                        </td>
                    </tr>
                </ng-container>

                <ng-container *ngIf="calcRes.subrailBearingCapacity && calcRes.subrailBearingCapacity.length">
                    <ng-container *ngFor="let category of calcRes.subrailBearingCapacity">
                        <tr class="tr-header">
                            <td colspan="3">
                                {{category.title | translate}}
                            </td>
                        </tr>
                        <tr *ngFor="let item of category.items">
                            <td>{{item.name | translate}}</td>
                            <td colspan="2">
                                <div class="value">
                                    {{item.value}} {{item.unit}}
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>

            </tbody>
        </table>
    </div>
    <div class="btn-wrapper">
        <button mat-stroked-button
                mat-dialog-close
                color="primary">
            {{"Close" | translate}}
        </button>
    </div>
</div>
