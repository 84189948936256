import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyService, Solar, WebSolarInverterService } from '@websolar/ng-websolar';
import { UserStorageService } from 'src/app/services/user.storage.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-inverter-edit-window',
    templateUrl: './inverter-edit-window.component.html',
    styleUrls: ['./inverter-edit-window.component.scss']
})
export class InverterEditWindowComponent {

    public inverter: AIKO.InverterExt;

    public isLoading = false;

    public stringsPerMPPTFormatted = "";

    public isFav = false;

    public voltageList = [
        { id: "380/220", name: "220V/380V 3W / N + PE" },
        { id: "380", name: "380V, three-phase" },
        { id: "220", name: "220V, single-phase" }
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { inverter: AIKO.InverterExt },
        private _dialogRef: MatDialogRef<InverterEditWindowComponent>,
        private _notify: NotifyService,
        private _inverterService: WebSolarInverterService,
        private _userStorage: UserStorageService
    ) {
        this.inverter = data.inverter || this.getDefault();
        if (!this.inverter.voltageLevel) {
            this.inverter.voltageLevel = "380/220";
        }
        this.initStringsPerMppt();
        this.updateFav();

    }

    private async updateFav() {
        const favModulesIds = await this._userStorage.getValue("fav_inverters") as string[] || [];
        if (this.inverter._id) {
            this.isFav = favModulesIds.includes(this.inverter._id);
        }
    }

    private initStringsPerMppt() {
        // Different MPPTs under the inverter may have different string access limits, 
        // and this situation needs to be compatible when entering data in the background. Use the format "2/2/1" to enter, 
        // and use "/" to distinguish the string limits of different MPPTs.
        // Case 1, there are 10 MPPTs, only enter "2", indicating that the string access limit of all MPPTs is 2
        // Case 2, there are 3 MPPTs, enter "2/2/1", indicating that the string limit of two MPPTs is 2, and the limit of one MPPT is 1
        // Case 3, there are 3 MPPTs, illegal input "2/2", this time submission fails, 
        // and prompts the error message "Strings per MPPT input format should be 2 or 2/2/1"
        if (this.inverter.stringsPerMPPTLimits &&
            this.inverter.stringsPerMPPTLimits.length
        ) {
            this.stringsPerMPPTFormatted = this.inverter.stringsPerMPPTLimits.join("/");
        }
        else if (this.inverter.stringsPerMPPT) {
            this.stringsPerMPPTFormatted = `${this.inverter.stringsPerMPPT}`;
        }
    }

    private getDefault(): Solar.Inverter {
        return {
            model: "",
            manufacturer: "",
            description: "",
            maxPower: 3,
            nominal: 200,
            efficiency: 96,
            minVoltage: 100,
            maxVoltage: 600,
            maxInputDC: 700,
            powerLevel: 3,
            price: 2000,
            microinverter: false,
            hybrid: false,
            maxPVInputCurrent: 0
        }
    }

    public async save() {
        try {
            this.isLoading = true;

            this.updateStringPerMpptChange();

            const id = await this._inverterService.save(this.inverter);

            await this.saveFavState(id);

            this._notify.success(`Inverter created`);

            this._dialogRef.close(true);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isCanSave() {
        if (!this.inverter.manufacturer ||
            !this.inverter.model ||
            typeof this.inverter.maxPower != "number" ||
            typeof this.inverter.nominal != "number" ||
            typeof this.inverter.minVoltage != "number" ||
            typeof this.inverter.maxVoltage != "number" ||
            typeof this.inverter.efficiency != "number") {
            return false;
        }

        return true;
    }


    /**
     * Handles the change event for the strings per MPPT input field.
     * Validates the input and updates the inverter's stringsPerMPPT and stringsPerMPPTLimits properties.
     * Displays an error notification if the input is invalid.
     */
    private updateStringPerMpptChange() {
        // Different MPPTs under the inverter may have different string access limits, 
        // and this situation needs to be compatible when entering data in the background. Use the format "2/2/1" to enter, 
        // and use "/" to distinguish the string limits of different MPPTs.
        // Case 1, there are 10 MPPTs, only enter "2", indicating that the string access limit of all MPPTs is 2
        // Case 2, there are 3 MPPTs, enter "2/2/1", indicating that the string limit of two MPPTs is 2, and the limit of one MPPT is 1
        // Case 3, there are 3 MPPTs, illegal input "2/2", this time submission fails, 
        // and prompts the error message "Strings per MPPT input format should be 2 or 2/2/1"

        try {
            if (!this.stringsPerMPPTFormatted) {
                this.inverter.stringsPerMPPT = null;
                this.inverter.stringsPerMPPTLimits = null;
                return;
            }

            if (typeof this.inverter.numberMPPT != "number" ||
                this.inverter.numberMPPT == 0) {
                throw `The number of MPPT is not defined`
            }

            // Assuming this.inverter.stringsPerMPPT contains the string to check
            const pattern = /^\d+(\/\d+)*$/; // Regular expression to match the pattern
            if (!pattern.test(this.stringsPerMPPTFormatted)) {
                throw `The format of strings per MPPT is incorrect`;
            }

            const items = this.stringsPerMPPTFormatted.split("/");

            this.inverter.stringsPerMPPTLimits = [];
            if (items.length == 1) {
                const nr = parseFloat(items[0]);
                if (isNaN(nr)) {
                    throw `The format of strings per MPPT is incorrect`;
                }
                this.inverter.stringsPerMPPTLimits.push(nr)
            }
            else {
                if (items.length != this.inverter.numberMPPT) {
                    let possibleValues = items.slice(0, this.inverter.numberMPPT);
                    for (let idx = items.length; idx < this.inverter.numberMPPT; idx++) {
                        possibleValues.push("1");
                    }

                    throw `Strings per MPPT input format should be ${items[0]} or ${possibleValues.join("/")}`;
                }

                for (const item of items) {
                    const nr = parseFloat(item);
                    if (isNaN(nr)) {
                        throw `The format of strings per MPPT is incorrect`;
                    }
                    this.inverter.stringsPerMPPTLimits.push(nr);
                }
            }
        }
        catch (err) {
            this.inverter.stringsPerMPPT = null;
            this.inverter.stringsPerMPPTLimits = null;

            throw err;
        }
    }

    public close() {
        this._dialogRef.close(null);
    }

    private async saveFavState(id: string) {
        let favModulesIds = await this._userStorage.getValue("fav_inverters") as string[] || [];
        if (this.isFav) {
            if (!favModulesIds.includes(id)) {
                favModulesIds.push(id);
            }
        }
        else {
            favModulesIds = favModulesIds.filter(m => m != id);
        }
        await this._userStorage.setValue({ id: "fav_inverters", val: favModulesIds });
    }
}
