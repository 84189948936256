<div class="project"
     *ngIf="project && segment">

    <websolar-number-input [label]="'Building Height' | translate"
                           [(value)]="segment.mountingSystem.buildingHeight"
                           (valueChange)="onOptionsChange()"
                           type="distance"
                           size="large"></websolar-number-input>

    <mat-form-field *ngIf="terrainCategories.length">
        <mat-label><span class="required-sign">*</span>{{"Terrain Category" | translate}}</mat-label>
        <mat-icon matPrefix
                  class="info-icon"
                  (click)="showTerrainHelp($event)">info_outline</mat-icon>
        <mat-select [(ngModel)]="project.mountingSystem.terrainCategory"
                    (ngModelChange)="onOptionsChange()">
            <mat-option *ngFor="let category of terrainCategories"
                        [value]="category._id">
                {{category.category| translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="windZonesVisible">
        <mat-label>{{"Wind Load Zone" | translate}}</mat-label>
        <mat-icon matPrefix
                  class="info-icon"
                  (click)="showWindMap($event)">info_outline</mat-icon>
                  
        <mat-select [(ngModel)]="project.mountingSystem.windZone"
                    (ngModelChange)="onOptionsChange()"
                    [disabled]="true">
            <mat-option *ngFor="let zone of windZones"
                        [value]="zone">
                {{"Zone" | translate}} {{zone}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <ng-container *ngIf="snowZonesVisible">
        <mat-form-field>
            <mat-label>{{"Snow Load Zone" | translate}}</mat-label>
            <mat-icon matPrefix
                      class="info-icon"
                      (click)="showSnowMap($event)">info_outline</mat-icon>
            <mat-select [(ngModel)]="project.mountingSystem.snowZone"
                        (ngModelChange)="onOptionsChange()"
                        [disabled]="true">
                <mat-option *ngFor="let zone of snowZones"
                            [value]="zone">
                    {{"Zone" | translate}} {{zone}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <websolar-number-input [label]="'Velocity Pressure' | translate"
                           [(value)]="project.mountingSystem.windLoad"
                           [disabled]="true"
                           (valueChange)="onOptionsChange()"
                           type="load"></websolar-number-input>

    <websolar-number-input [label]="'Snow Load' | translate"
                           [(value)]="project.mountingSystem.snowLoad"
                           (valueChange)="onOptionsChange()"
                           [disabled]="true"
                           type="load"></websolar-number-input>

</div>
