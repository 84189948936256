import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Solar, SolarInstance, WebSolarAuthService, NotifyService, WebSolarProjectService, WebSolarPermitService, WebSolarWiringService } from '@websolar/ng-websolar';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-panel',
    templateUrl: './permit-panel.component.html',
    styleUrls: ['./permit-panel.component.scss']
})
export class PermitPanelComponent {
    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Output() navigationRequest = new EventEmitter<AIKO.MenuMode>();

    public isContractorAvail = true;

    public isSigned = false;

    public isLoading = false;

    constructor(
        private _auth: WebSolarAuthService,
        private _notify: NotifyService,
        private _projectService: WebSolarProjectService,
        private _wiringService: WebSolarWiringService,
        private _permitService: WebSolarPermitService,
        private _router: Router
    ) {
        this.isSigned = this._auth.isSigned();
    }

    public async run() {
        try {
            this.isLoading = true;

            // sync wiring config
            this._wiringService.sync(this.instance, this.project);

            // save project
            let objects = this.instance.getObjects({});
            const res = await this._projectService.save({
                project: this.project,
                objects: objects
            });

            // open permit package
            this._router.navigate([`/permit/${res.id}`]);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onContractroChange() {
        if (!this.project ||
            !this.project.permit) {
            return;
        }
        if (!this.isContractorAvail) {
            this.project.permit.contractorPhone = ""
            this.project.permit.contractorLicenseNumber = ""
            this.project.permit.contractorName = ""
        }
    }

    public hasErrors() {
        return Boolean(this.getErrorMessage());
    }

    public getErrorMessage(): string {
        // do the basic checking
        const errorMsg = this._permitService.verifyProject(this.instance, this.project);
        if (errorMsg) {
            return errorMsg;
        }

        // perform the custom checking
        const allItems = this._wiringService.getFlatTree(this.project.electrical.stringsConfig.items)
        const servPanel = allItems.find(i => i.object.type == "main_service_panel");
        if (!servPanel) {
            return "Electrical configuration is not completed. Please add service panel to Electrical Configuration"
        }
        const inverter = allItems.find(i => i.object.type == "inverter");
        if (!inverter) {
            return "Electrical configuration is not completed. Please add inverter to Electrical Configuration"
        }

        // check the inverters
        const inverterItems = allItems.filter(i => i.object.type == "inverter");
        for (const inverterItem of inverterItems) {
            if (!this._wiringService.findParent(this.project.electrical.stringsConfig, inverterItem, "main_service_panel")) {
                return "Electrical configuration is not completed. Please connect each inverter to Service Panel"
            }
        }

        return "";
    }


}
