import { AIKO } from "../types/aiko.types";

export class ToolbarTool {
    /**
     * Disables all toolbar controls.
     * @param control - The toolbar control object.
     */
    public static disableAll(control: AIKO.ToolbarControl) {
        control.save = false;
        control.zoom = false;
        control.autoModelling = false;
        control.copy = false;
        control.delete = false;
        control.undoRedo = false;
        control.view3d = false;
        control.reset = false;
    }
}