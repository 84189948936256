import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NotifyService, Solar, SolarInstance, WebSolarEventsService, WebSolarShadingService } from '@websolar/ng-websolar';
import moment from 'moment-timezone';
import { AppTime } from 'src/app/components/time-picker/time';
import { CustomLegendBuilder } from 'src/app/rendering/custom.legend';


@Component({
    selector: 'app-shadows-options',
    templateUrl: './shadows-options.component.html',
    styleUrls: ['./shadows-options.component.scss']
})
export class ShadowsOptionsComponent implements OnDestroy, OnChanges {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    public date = new Date();

    public startTime: AppTime = { hr: 9, min: 0 };

    public endTime: AppTime = { hr: 15, min: 0 };

    public isCalculating = false;

    public isAnimating = false;

    public animationTime = 1;

    public isOutlineVisible = false;

    public isTimeSettingsVisible = false;

    public useLossesFormat = false;

    public hasIrradiance = false;

    private _cancellation!: Solar.Cancellation;

    private _animationTimer: unknown;

    constructor(
        private _notify: NotifyService,
        private _shadingService: WebSolarShadingService,
        private _eventService: WebSolarEventsService
    ) {
        this._eventService.eventsAsObservable.subscribe((opt) => {
            try {
                if (opt.name == "tool_completed" ||
                    opt.name == "undo" ||
                    opt.name == "redo") {
                    this.cleanup();
                }
            }
            catch (err) {
                console.error(err);
            }
        })
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["segment"]) {
            this.restoreTimeData();
        }
    }

    public ngOnDestroy(): void {
        try {
            this.cleanup();
        }
        catch (err) {
            console.error(err);
        }
    }

    private cleanup() {
        try {
            this.cancelShading();
            this.stopAnimation();
            if (this.instance) {
                this.instance.layers.irradiance = false;
                this.instance.layers.update();
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    public async toggleShadowOutline() {
        if (!this.isOutlineVisible) {
            this.showShadowOutline();
        }
        else {
            this.hideShadowOutline();
        }
    }

    private hideShadowOutline() {
        // cancel previous calculation
        this.cancelShading();

        // delete the previous irradiance result
        this.instance.removeObjects({ types: ["irradiance"] })

        this.instance.disableShadows();

        this.isOutlineVisible = false;
    }

    private async showShadowOutline() {
        try {
            if (this.isAnimating) {
                this.stopAnimation();
            }

            this.isCalculating = true;

            let date = this.date;
            if (!date || !this.startTime || !this.endTime) {
                throw `date or time is empty`
            }
            if (!this.instance) {
                throw `Render engine is not ready`
            }
            if (!this.project) {
                throw `Project is empty`
            }
            if (this.startTime.hr == this.endTime.hr) {
                throw `The start time can't be equal to end time. Please choose the correct date range.`
            }

            // cancel previous calculation
            this.cancelShading();
            // create new cancellation object
            this._cancellation = { cancelled: false };

            // the default time from 9am to 16pm for shadows
            let startHr = this.startTime.hr;
            let endHr = this.endTime.hr;

            console.log(`run simulation for date : ${moment(date).format("DD MMM")} from ${startHr} to ${endHr}`)

            const start = moment(date).tz(this.project.timezone).startOf("day").add(startHr, "hour").toDate();
            const end = moment(date).tz(this.project.timezone).startOf("day").add(endHr, "hour").toDate();

            // show outline for range
            this.instance.showShadowsForRange(
                start,
                end,
                this.project.location
            )

            // delete the previous irradiance result
            this.instance.removeObjects({ types: ["irradiance"] })

            // run simulation
            //

            const startUTC = moment(date).utcOffset(0).startOf("day").add(startHr, "hour").toDate();
            const endUTC = moment(date).utcOffset(0).startOf("day").add(endHr, "hour").toDate();

            const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
            const hasTiltSegments = segments.find(s => s.racking == "fixed_tilt" && s.module && s.layoutGrouping?.enabled);

            const shadingOptions = {
                instance: this.instance,
                project: this.project,
                dateFrom: startUTC,
                dateTo: endUTC,
                cancellation: this._cancellation,
                legendMin: 0,
                legendUseLosses: true,
                showPercentage: true,
                saveResults: false,
                useModules: Boolean(hasTiltSegments)
            };

            const output = await this._shadingService.run(shadingOptions)
            console.log("shading output:", output);

            // activate the irradiance layer
            this.instance.layers.irradiance = true;

            if (output) {

                this.updateIrradiance();

                // send the event that simulation legend is ready
                this._eventService.events.next({ name: "simulation_legend_ready", params: null });
            }

            this.isOutlineVisible = true;
        }
        catch (err) {
            if (err != "operation cancelled") {
                this._notify.error(err);
            }
        }
        finally {
            this.isCalculating = false;
        }
    }

    public toggleAnimation() {
        this.isAnimating = !this.isAnimating;
        if (this.isAnimating) {
            this.startAnimation();
        }
        else {
            this.stopAnimation();
        }
    }


    public startAnimation() {
        this.instance.highlight.clear();

        if (this._animationTimer) {
            clearInterval(this._animationTimer as number);
            this._animationTimer = 0;
        }

        this._animationTimer = setInterval(() => {
            this.renderAnimationStep();
        }, 100);
    }


    public stopAnimation() {
        if (this._animationTimer) {
            clearInterval(this._animationTimer as number);
            this._animationTimer = 0;
        }
        this.instance.disableShadows();
    }

    public onTimestampChange() {
        this.stopAnimation();
        this.renderAnimationStep();
    }


    private renderAnimationStep() {
        if (!this.instance ||
            !this.project) {
            return;
        }

        const startOffset = this.startTime ? this.startTime.hr : 9;

        this.animationTime += 10;

        if (this.startTime && this.endTime) {
            let timelineInMinutes = (this.endTime.hr - this.startTime.hr) * 60;
            if (this.animationTime > timelineInMinutes) {
                this.animationTime = 1;
            }
        }
        else if (this.animationTime > (60 * 8)) {
            this.animationTime = startOffset;
        }
        else if (this.animationTime < startOffset) {
            this.animationTime = startOffset;
        }


        let startDate = this.date;
        if (!startDate) {
            // the user can choose the date and time by themselves.If they didn't do that, 
            // the dafault date is 21th Dec of the last year
            startDate = new Date(2000, 11, 21);
        }

        const timezone = this.project.timezone || "UTC";

        const date = moment(startDate).tz(timezone)
            .startOf("day")
            .add(startOffset, "hours")
            .add(this.animationTime, "minutes");

        this.instance.showShadowsAt(date.toDate(), this.project.location, true);
    }


    /**
     * Cancels the shading operation.
     */
    private cancelShading() {
        if (this._cancellation) {
            this._cancellation.cancelled = true;
        }
        if (this.instance) {
            this.instance.disableShadows();
        }
    }

    public startTimeChanged() {
        if (this.startTime && this.endTime) {
            if (this.startTime.hr > this.endTime.hr) {
                const endHr = Math.min(24, this.startTime.hr + 1);
                this.endTime = { hr: endHr, min: this.startTime.min };
            }
        }
    }

    public endTimeChanged() {
        if (this.startTime && this.endTime) {
            if (this.startTime.hr > this.endTime.hr) {
                const startHr = Math.max(0, this.endTime.hr - 1);
                this.startTime = { hr: startHr, min: this.endTime.min };
            }
        }
    }

    /**
     * Handles the event when the date is changed.
     */
    public dateChanged() {
        // if (!this.segment.userData) {
        //     this.segment.userData = {};
        // }

        // if (this.date) {
        //     this.segment.userData["shadow_date"] = this.date.toISOString();
        // }

        // if (this.startTime) {
        //     this.segment.userData["shadow_start_time"] = this.startTime;
        // }

        // if (this.endTime) {
        //     this.segment.userData["shadow_end_time"] = this.endTime;
        // }
    }

    /**
     * Restores the time data for the shadows options component.
     * If the segment or segment userData is not available, the function returns early.
     */
    private restoreTimeData() {
        if (!this.date) {
            // the user can choose the date and time by themselves.If they didn't do that, 
            // the default date is the winter solstice of the last year 
            // (for example:the user create the project in 2024,and the dafault time is the winter solstice in 2023= 22th Dec 2023)
            this.date = moment().subtract(1, "year").set({ month: 11, date: 22 }).toDate();
        }
    }

    public updateIrradiance() {
        if (!this.instance) {
            return;
        }
        const legendBuilder = new CustomLegendBuilder();

        legendBuilder.updateIrradiance(this.instance, this.useLossesFormat);

        this.hasIrradiance = this.instance.getObjects({ types: ["irradiance"] }).length > 0;
    }

    private getIrradianceOutput(moduleIds: string[]) {
        const irradiances = this.instance.getObjects({ types: ["irradiance"], ownerId: moduleIds }) as Solar.ObjectIrradiance[];
        if (!irradiances.length) {
            return null;
        }

        const total = Math.round(irradiances.reduce((prev, i) => prev + i.value, 0) * 1000) / 1000;
        const maxoutput = this._shadingService.getLegendMaximum(irradiances.map(i => i.value));
        console.log(`total: ${total}, max output: ${maxoutput} , irradiances: ${irradiances.map(i => i.value).sort()}`);

        const legendBuilder = new CustomLegendBuilder();
        const legend = legendBuilder.getLegend(100, 0);
        return { legend, maxoutput, irradiances };
    }
}
