import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Solar, NotifyService } from '@websolar/ng-websolar';
import { Observable } from 'rxjs';
import { MountingService } from 'src/app/services/mounting.service';
import { CnTerrainCategoryHelpComponent } from '../cn-terrain-category-help/cn-terrain-category-help.component';


@Component({
    selector: 'app-mounting-cn',
    templateUrl: './mounting-cn.component.html',
    styleUrls: ['./mounting-cn.component.scss']
})
export class MountingCnComponent {


    /**
     * The project input for the MountingEuropeComponent.
     */
    @Input() project!: Solar.Project;

    /**
     * The segment input for the MountingEuropeComponent.
     */
    @Input() segment!: Solar.ObjectRooftopSegment;

    /**
     * The events input property.
     * It is an Observable that emits objects with a name and params property.
     */
    @Input() events!: Observable<{ name: string, params: unknown }>;

    /**
     * The calculation result
     */
    @Input() calcRes: Solar.BaseMountingCalculation | null = null;

    /**
     * Event emitter for the onChange event.
     */
    @Output() onChange = new EventEmitter<void>();

    /**
     * Indicates whether the calculation is in progress.
     */
    @Input() isCaclulating = false;

    /**
     * Represents the terrain categories
     * Each category has an ID and a name.
     */
    public terrainCategories: { id: Solar.TerrainCategory, name: string }[] = [
        { id: "I", name: "A" },
        { id: "II", name: "B" },
        { id: "III", name: "C" },
        { id: "IV", name: "D" }
    ];


    constructor(
        private _mountingService: MountingService,
        private _matDialog: MatDialog,
        private _notify: NotifyService
    ) { }



    /**
     * Event handler for when the options change.
     * Emits the change event.
     */
    public onOptionsChange() {
        this.onChange.emit();
    }


    /**
     * Shows the terrain help dialog.
     * 
     * @param evt - The mouse event that triggered the function.
     * @returns A promise that resolves when the dialog is closed.
     */
    public async showTerrainHelp(evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(CnTerrainCategoryHelpComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw"
            });
            await dialogRef.afterClosed().toPromise()
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
