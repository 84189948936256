import { Solar, SolarInstance } from "@websolar/ng-websolar";
import { NumberTool } from "../core/number.tool";

export class CustomLegendBuilder {
    /**
     * Retrieves the legend for a given range of values.
     * @param max The maximum value of the range.
     * @param min The minimum value of the range.
     * @returns The legend object containing color, min, and max values.
     */
    public getLegend(max: number, min: number) {
        const round = (max < 1) ? 4 : (max < 10) ? 2 : 0;

        const legend: Solar.Legend = { items: [], units: "kWh/m2" };

        const steps = [
            { current: 0, next: 25, color: "#011DFC" },
            { current: 25, next: 50, color: "#00429d" },
            { current: 50, next: 60, color: "#4c73b5" },
            { current: 60, next: 70, color: "#7f97b6" },
            { current: 70, next: 80, color: "#abaca5" },
            { current: 80, next: 85, color: "#cfb288" },
            { current: 85, next: 90, color: "#e9aa63" },
            { current: 90, next: 95, color: "#f9923b" },
            { current: 95, next: 99.6, color: "#ff6a14" },
            { current: 99.6, next: 100, color: "#ff0000" }
        ];
        for (const step of steps) {
            legend.items.push({
                color: step.color,
                min: step.current,
                max: step.next
            })
        }

        return legend;
    }

    /**
     * Gets the color corresponding to the given value based on the legend.
     * @param legend - The legend object containing color ranges.
     * @param value - The value for which to retrieve the color.
     * @returns The color corresponding to the given value.
     */
    public getColor(legend: Solar.Legend, value: number): string {
        const item = legend.items.find(i => value >= i.min && value <= i.max);
        if (item) {
            return item.color;
        }

        if (value < legend.items[0].min) {
            // pick lowest
            return legend.items[0].color;
        }
        else {
            // pick highest
            const last = legend.items[legend.items.length - 1];
            return last.color;
        }
    }


    public updateIrradiance(instance: SolarInstance, useLossesFormat: boolean) {
        if (!instance) {
            return;
        }
        const legendBuilder = new CustomLegendBuilder();

        const arrays = instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        for (const arr of arrays) {
            const modulesIds = instance.getObjects({ types: ["module"], ownerId: arr.id }).map(m => m.id);
            if (!modulesIds.length) {
                continue;
            }

            const output = this.getIrradianceOutput(instance, modulesIds);
            if (!output) {
                return;
            }

            const maxoutput = output.maxoutput;
            const legend = output.legend;
            const irradiances = output.irradiances;

            for (const irradiance of irradiances) {
                const precentage = Math.round(irradiance.value / maxoutput * 100);

                if (useLossesFormat) {
                    irradiance.percentage = precentage - 100;

                    if (irradiance.percentage == 0) {
                        irradiance.showPercentage = false;
                    }
                    else {
                        irradiance.showPercentage = true;
                    }
                }
                else {
                    irradiance.percentage = precentage;
                    irradiance.showPercentage = true;
                }

                irradiance.percentage = Math.round(irradiance.percentage);


                // update color
                irradiance.color = legendBuilder.getColor(legend, precentage);

                const obj3d = instance.createObject(irradiance);
                if (obj3d) {
                    instance.removeObjects({ id: irradiance.id });
                    instance.scene.add(obj3d);
                }
            }
        }
    }

    private getIrradianceOutput(instance: SolarInstance, moduleIds: string[]) {
        const irradiances = instance.getObjects({ types: ["irradiance"], ownerId: moduleIds }) as Solar.ObjectIrradiance[];
        if (!irradiances.length) {
            return null;
        }

        const total = Math.round(irradiances.reduce((prev, i) => prev + i.value, 0) * 1000) / 1000;
        const maxoutput = irradiances.map(i => i.value).reduce((prev, cur) => Math.max(prev, cur), 0.001);
        console.log(`total: ${total}, max output: ${maxoutput} , irradiances: ${irradiances.map(i => i.value).sort()}`);

        const legendBuilder = new CustomLegendBuilder();
        const legend = legendBuilder.getLegend(100, 0);
        return { legend, maxoutput, irradiances };
    }
}