import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Solar, WebSolarSunPathComponent } from '@websolar/ng-websolar';
import { ModuleConfigService } from 'src/app/services/module.config.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-segment-options',
    templateUrl: './segment-options.component.html',
    styleUrls: ['./segment-options.component.scss']
})
export class SegmentOptionsComponent implements OnChanges {

    @Input() segment!: Solar.ObjectRooftopSegment;

    @Input() project!: Solar.Project;

    @Output() segmentChange = new EventEmitter<Solar.ObjectRooftopSegment>();

    @Output() toolActivated = new EventEmitter<Solar.ToolOptions>();

    public assignTutorId = false;

    public region = environment.region;

    private _timer: unknown;

    public rackings = [
        { id: "fixed_tilt", name: "Fixed Tilt" },
        { id: "flush_mount", name: "Flush Mount" }
    ];

    public orientations = [
        { id: "horz", name: "Horizontal" },
        { id: "vert", name: "Vertical" }
    ];

    public aligns = [
        { id: "grid", name: "Vertical & Horizontal" },
        { id: "row", name: "Horizontal" }
    ];

    /**
     * The previous selected flush row spacing value.
     */
    private _prevFlushRowSpacing = -1;

    /**
     * The previous selected fixed row spacing value.
     */
    private _prevFixedRowSpacing = -1;

    constructor(
        private _matDialog: MatDialog,
        private _moduleConfigService: ModuleConfigService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["segment"] && this.segment) {
            // reset the saved 
            this._prevFlushRowSpacing = -1;
            this._prevFixedRowSpacing = -1;
            if (!this.segment.layoutGrouping) {
                this.segment.layoutGrouping = {
                    enabled: false,
                    rows: 2,
                    rowSpacing: 0.4,
                    columns: 8,
                    columnSpacing: 0.8
                }
            }
            this.segment.layoutGrouping.onlyFullfiled = true;
        }
    }

    /**
     * Handles the change event for the azimuth value.
     * Sets the `isAzimuthAuto` property to `false` and triggers the `onChange` method.
     */
    public onChangeAzimuth() {
        this.segment.isAzimuthAuto = false;
        this.onChange();
    }

    /**
     * Handles the orientation change event.
     * This method is called when the orientation of the segment changes.
     * It triggers the onChange event and applies default spacing and resets mounting settings.
     */
    public onOrientationChange() {
        this.onChange();

        // apply the defaults
        this._moduleConfigService.setDefaultSpacing(this.segment);

        // reset mounting settings
        if (this.segment.mountingSystem) {
            this.segment.mountingSystem.railSpan = 0;
            this.segment.mountingSystem.hookSpan = 0;
            this.segment.mountingSystem.rafterSpan = 0.4;
        }
    }

    /**
     * Handles the change event when the grouping option is toggled.
     * If grouping is enabled, sets the row spacing to 0.1 and triggers the onChange event.
     */
    public onGroupingChange() {
        if (this.segment.layoutGrouping?.enabled) {
            this.segment.rowSpacing = 0.1;
        }
        this.onChange();
    }

    /**
     * Handles the change event for the segment options.
     * This method updates the segment properties based on the user's input.
     */
    public onChange() {

        // align the mounting system
        this.segment.mountingSystem.orientation = this.segment.orientation == "horz" ? "vert" : "horz";

        if (this.segment.racking == "flush_mount") {
            this.segment.tilt = 0;
        }

        if (this.segment.tilt >= 80) {
            this.segment.tilt = 80;
        }
        else if (this.segment.tilt < 0) {
            this.segment.tilt = 0;
        }

        if (this.segment.setback < 0) {
            this.segment.setback = 0;
        }

        if (this.segment.moduleBottomOffset < 0) {
            this.segment.moduleBottomOffset = 0;
        }

        if (this.segment.moduleSpacing > 100) {
            this.segment.moduleSpacing = 100;
        } else if (this.segment.moduleSpacing < 0) {
            this.segment.moduleSpacing = 0;
        }

        if (this.segment.rowSpacing > 100) {
            this.segment.rowSpacing = 100;
        } else if (this.segment.rowSpacing < 0) {
            this.segment.rowSpacing = 0;
        }

        if (this.segment.azimuth > 360) {
            this.segment.moduleSpacing = 360;
        } else if (this.segment.moduleSpacing < -360) {
            this.segment.moduleSpacing = -360;
        }

        this.notifyChange();
    }


    public onRackingChange() {
        if (this.segment.module) {

            if (this.segment.racking == "fixed_tilt") {
                if (!this.segment.tilt) {
                    // default tilt
                    this.segment.tilt = 20;
                }

                // store the previous row spacing 
                this._prevFlushRowSpacing = this.segment.rowSpacing;

                // try to restore the value from the memory
                if (this._prevFixedRowSpacing && this._prevFixedRowSpacing > 0) {
                    this.segment.rowSpacing = this._prevFixedRowSpacing;
                }
                else {
                    // calculate recommended raw spacing
                    this.segment.rowSpacing = this.getRecommendedRawSpacing();
                }
            }
            else if (this.segment.racking == "flush_mount") {

                // store the previous row spacing 
                this._prevFixedRowSpacing = this.segment.rowSpacing;

                // try to restore the value from the memory
                if (this._prevFlushRowSpacing && this._prevFlushRowSpacing > 0) {
                    this.segment.rowSpacing = this._prevFlushRowSpacing;
                }
            }

        }
        this.onChange();
    }

    public onHeightChange() {
        this.onChange();
    }

    public onSlopeChange() {
        this.onChange();
    }


    private notifyChange() {
        if (this._timer) {
            clearTimeout(this._timer as number);
        }

        this._timer = setTimeout(() => {
            this.segmentChange.emit(this.segment);
        }, 500);
    }

    public setBottomEdge(evt: MouseEvent) {
        evt.stopPropagation();

        this.toolActivated.emit({ type: "set_bottom_edge", params: this.segment })
    }

    private getRecommendedRawSpacing(): number {
        if (!this.segment.module) {
            // return as is
            return this.segment.rowSpacing;
        }

        if (this.segment.layoutGrouping?.enabled) {
            return 100;
        }

        // TODO: need to use sun path
        // Height Difference = Sin (Tilt Angle) x Module Width
        // Module Row Spacing = Height Difference / Tan(solarElevationAngle)
        // Minimum Module Row Spacing = Module Row Spacing x Cos(Azimuth Correction Angle)

        // angle between sun and horizontal
        // calc approximate angle for December
        const q = ((90 - Math.abs(this.project.location.lat) - 23.4) / 180) * Math.PI;
        const tilt = (this.segment.tilt / 180) * Math.PI;

        const size = this.getModuleSize(this.segment);
        const len = size.height;
        const rawSpace = (len * Math.sin(tilt)) / Math.tan(q);
        // for safety we can 1.2 offset, because we do approximate calculation
        return Math.round(rawSpace * 1.2 * 1000) / 1000;
    }

    private getModuleSize(segment: Solar.ObjectRooftopSegment): Solar.Size {
        let width: number = 1;
        let height: number = 1;

        if (segment.module) {
            if (segment.orientation == "vert") {
                width = segment.module.height;
                height = segment.module.width;
            }
            else {
                width = segment.module.width;
                height = segment.module.height;
            }
        }

        return {
            width: width,
            height: height
        };
    }

    public async showSunPath() {
        try {
            const dialogRef = this._matDialog.open(WebSolarSunPathComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    project: this.project,
                    segment: this.segment
                }
            });
            const result = await dialogRef.afterClosed().toPromise();
            if (typeof result != "number" ||
                result <= 0) {
                return;
            }
            this.segment.rowSpacing = result;
        }
        catch (err) {
            console.error(err);
        }
    }
}
