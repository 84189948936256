import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { WebSolarAuthService, NotifyService, WebSolarConfiguration } from "@websolar/ng-websolar";

@Injectable()
export class HttpService {

    constructor(
        private _auth: WebSolarAuthService,
        private _notify: NotifyService,
        private _http: HttpClient) { }

    public async post<T>(url: string, data?: unknown, cache?: boolean) {

        let httpOptions = {
            timeout: 60000,
            headers: new HttpHeaders({
                Authorization: this._auth.getToken()
            })
        };
        let _url = `${WebSolarConfiguration.service}${url}`

        try {
            const res = await this._http.post(_url,
                data ? data : {},
                httpOptions).toPromise();
            return res as T;
        }
        catch (err) {
            const errHttp = err as { status: number };
            if (errHttp.status == 401) {
                console.error(errHttp);
                this._notify.error(`You don't have access to the given API`)
            }
            throw err;
        }
    }

    public async get<T>(url: string, timeout?: number) {

        let httpOptions = {
            timeout: timeout || 60000,
            headers: new HttpHeaders()
        };

        if (url.includes(WebSolarConfiguration.service)) {
            // add the service token
            httpOptions.headers = new HttpHeaders({
                Authorization: this._auth.getToken()
            });
        }
        try {
            const res = await this._http.get(`${url}`, httpOptions).toPromise();
            return res as T;
        } catch (err) {
            const errHttp = err as { status: number };
            if (errHttp.status == 401) {
                console.error(err);
                this._notify.error(`You don't have access to the given API`)
            }
            throw err;
        }
    }


    /**
     * upload file to cloud storage
    */
    public async uploadFile(url: string, file: File): Promise<unknown> {
        const formData = new FormData();
        formData.append("file", file);

        const data = await this.post(url, formData);
        if (!data) {
            throw "Uploading failed"
        }

        return data;
    }
}