import { Injectable } from "@angular/core";

@Injectable()
export class GoogleMapService {

    public createMap(element: HTMLElement) {
        const center: google.maps.LatLngLiteral = { lat: 30, lng: -110 };

        return new google.maps.Map(element, {
            center,
            zoom: 2,
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            panControl: false,
            rotateControl: false,
            disableDoubleClickZoom: true,
            tilt: 0,
            maxZoom: 30,
            gestureHandling: "greedy"
        });
    }

    public getCurrentLocation() {
        return new Promise<google.maps.LatLng | null>((resolve, reject) => {
            try {
                if (!navigator.geolocation) {
                    resolve(null);
                }
                navigator.geolocation.getCurrentPosition(
                    (position: GeolocationPosition) => {
                        const pos = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                        resolve(pos);
                    },
                    () => {
                        resolve(null);
                    }
                );
            }
            catch (err) {
                reject(err);
            }
        })
    }

}