<div class="component">
    <div class="component-content">
        <h2>
            {{"Terrain Categories" | translate}}
        </h2>
        <div class="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th>{{"Terrain Category" | translate}}</th>
                        <th>{{"Description" | translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let terrain of terrains">
                        <td>{{terrain.category | translate}}</td>
                        <td>
                            {{terrain.description| translate}}
                        </td>
                        <td>
                            <img [src]="terrain.image">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="btn-wrapper">
            <button mat-stroked-button
                    mat-dialog-close
                    color="primary">
                {{"Close" | translate}}
            </button>
        </div>
    </div>
</div>
