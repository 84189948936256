<div class="component"
     *ngIf="record">
    <div class="header">
        <div>
            {{"Edit Device" | translate}}
        </div>
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="form">

        <mat-form-field>
            <mat-label>{{"Device" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="record.name"
                   [disabled]="!record.userDefined">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Model" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="record.model"
                   [disabled]="!record.userDefined">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Quantity" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="record.quantity"
                   [disabled]="!record.userDefined">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Price" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="record.price">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Total Cost" | translate}}</mat-label>
            <input matInput
                   [disabled]="true"
                   type="number"
                   [ngModel]="getTotalCost()">
        </mat-form-field>


        <div class="save-btn-wrapper">
            <button (click)="save()"
                    [disabled]="!isCanSave()"
                    mat-flat-button
                    color="primary">
                {{"Save" | translate}}
            </button>
        </div>


    </div>
</div>
