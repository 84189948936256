import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent {
    /**
     * The geographical location data. This is an input property and it's required.
     */
    @Input() location!: Solar.GeoLocation;

    /**
     * The size of the project. This is an input property and it's required.
     */
    @Input() projectSize!: Solar.ProjectSize;

    /**
     * Emits an event when the location changes.
     * @event locationChange
     * @type {EventEmitter<Solar.GeoLocation>}
     */
    @Output() locationChange = new EventEmitter<Solar.GeoLocation>();

    public type: "google" | "amap" = "google";

    constructor() {
        this.type = environment.region == "cn" ? "amap" : "google";
    }

    public onLocationChange(location: Solar.GeoLocation) {
        this.locationChange.emit(location);
    }

}
