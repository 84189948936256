import { Component, Input, OnChanges } from '@angular/core';
import { NotifyService, Solar } from '@websolar/ng-websolar';
import { BomService } from 'src/app/services/bom.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-materials-mounting',
    templateUrl: './permit-materials-mounting.component.html',
    styleUrls: ['./permit-materials-mounting.component.scss']
})
export class PermitMaterialsMountingComponent implements OnChanges {

    @Input() permitScope!: AIKO.PermitScope;

    public records: AIKO.MountingBOMRecord[] = [];

    constructor(
        private _notify: NotifyService,
        private _bomService: BomService
    ) { }

    ngOnChanges(): void {
        if (this.permitScope) {
            this.init();
        }
    }

    private async init() {
        try {
            if (!this.permitScope) {
                return;
            }

            const segments = this.permitScope.objects
                .filter(o => o.type == "segment")
                .map(o => o as Solar.ObjectRooftopSegment)
                .filter(o => o.module && o.mountingSystem);

            this.records = await this._bomService.getMountingSystemBOM(this.permitScope.project.mountingSystem, segments);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
