<div *ngIf="project"
     class="component">

    <mat-form-field>
        <mat-label>{{"Project Name" | translate}}</mat-label>
        <input matInput
               [(ngModel)]="project.name">
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{"Measurement" | translate}}</mat-label>
        <mat-select [(ngModel)]="project.measurement"
                    (ngModelChange)="onMeasurementChange()">
            <mat-option *ngFor="let item of measurements"
                        [value]="item.id">
                {{item.name | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field *ngIf="project.currency">
        <mat-label>{{"Currency" | translate}}</mat-label>
        <mat-select [(ngModel)]="currencyCode"
                    (ngModelChange)="currencyChanged()">
            <mat-option *ngFor="let item of currencies"
                        [value]="item.code">
                {{item.name | translate}} ({{item.code}})
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="title">
        {{"System Losses (%)" | translate}}
    </div>

    <div class="loses-wrapper">

        <mat-form-field>
            <mat-label>{{"Electrical losses / Mismatch (%)" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="project.systemLoses.mismatch"
                   appDigitsLimit
                   type="number"
                   [min]="0"
                   [max]="90">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Soling (%)" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="project.systemLoses.soling"
                   appDigitsLimit
                   type="number"
                   [min]="0"
                   [max]="90">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Snow (%)" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="project.systemLoses.snow"
                   appDigitsLimit
                   type="number"
                   [min]="0"
                   [max]="90">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"The Impact of Light on Panel Longevity (%)" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="project.systemLoses.lightInducedDegradation"
                   appDigitsLimit
                   type="number"
                   [min]="0"
                   [max]="90">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"Availability/Maintenance (%)" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="project.systemLoses.availability"
                   appDigitsLimit
                   type="number"
                   [min]="0"
                   [max]="90">
        </mat-form-field>


        <mat-form-field>
            <mat-label>{{"AC Wiring (%)" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="project.systemLoses.wiring"
                   appDigitsLimit
                   type="number"
                   [min]="0"
                   [max]="90">
        </mat-form-field>        

    </div>

</div>
