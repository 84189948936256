import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnChanges {

    @Input() length = 0;

    @Input() pageSize = 10;

    @Input() pageSizeOptions: number[] = [10, 30, 50, 100];

    @Input() pageIndex = 0;

    @Output() page = new EventEmitter<void>();

    public pageNr = 1;

    public goToPage?: number;

    public ngOnChanges(changes: SimpleChanges): void {
        this.updatePageNr();
    }

    public prev() {
        if (this.pageIndex == 0) {
            return;
        }

        this.pageIndex = this.pageIndex - 1;
        this.updatePageNr();
        this.notify();
    }

    public next() {
        const maxPage = Math.ceil(this.length / this.pageSize);
        if (this.pageIndex >= (maxPage - 1)) {
            return;
        }
        this.pageIndex = this.pageIndex + 1;
        this.updatePageNr();
        this.notify();
    }

    public onPageSizeChange() {
        this.pageIndex = 0;
        this.notify();
    }

    public goTo() {
        if (!this.goToPage) {
            return;
        }
        this.pageIndex = this.goToPage - 1;
        // align to range
        this.pageIndex = Math.max(this.pageIndex, 0);
        const maxPage = Math.ceil(this.length / this.pageSize);
        this.pageIndex = Math.min(this.pageIndex, maxPage - 1);

        this.updatePageNr();

        // remove go to number
        delete this.goToPage;

        this.notify();
    }

    private updatePageNr() {
        this.pageNr = this.pageIndex + 1;
    }

    private notify() {
        this.page.emit();
    }
}
