<div class="component">

    <h2 class="title">
        <span> {{"Select solar panel" | translate}}</span>

        <button mat-flat-button
                color="primary"
                (click)="add()">
            <mat-icon>add</mat-icon>
            {{"Add Panel" | translate}}
        </button>
    </h2>

    <mat-form-field>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput
               [(ngModel)]="searchText"
               (ngModelChange)="onSearch()"
               [placeholder]="'Type to search panel' | translate">
    </mat-form-field>

    <div class="table-wrapper">

        <table mat-table
               [dataSource]="pageModules">
            <!-- manufacturer -->
            <ng-container matColumnDef="manufacturer">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Manufacturer" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.manufacturer}} </td>
            </ng-container>

            <ng-container matColumnDef="model">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Model" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.model}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Description" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.description}} </td>
            </ng-container>

            <ng-container matColumnDef="power">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Pmax (W)" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.power}}</td>
            </ng-container>

            <ng-container matColumnDef="voc">
                <th mat-header-cell
                    *matHeaderCellDef> {{"VOC (V)" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.voc | number}}</td>
            </ng-container>

            <ng-container matColumnDef="tpmax">
                <th mat-header-cell
                    *matHeaderCellDef>{{"γPmax (%/C)" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.tpmax | number}}</td>
            </ng-container>

            <ng-container matColumnDef="technology">
                <th mat-header-cell
                    *matHeaderCellDef> {{"Technology" | translate}} </th>
                <td mat-cell
                    *matCellDef="let element"> {{element.technology}}</td>
            </ng-container>

            <ng-container matColumnDef="copy">
                <th mat-header-cell
                    *matHeaderCellDef> </th>
                <td mat-cell
                    *matCellDef="let element">
                    <button *ngIf="element.orgId == 'general'"
                            mat-button
                            [matTooltip]="'Copy module' | translate"
                            color="primary"
                            (click)="copy(element, $event)">
                        <mat-icon>content_copy</mat-icon>
                        {{"Copy" | translate}}
                    </button>
                    <button *ngIf="element.orgId != 'general'"
                            mat-button
                            [matTooltip]="'Edit module' | translate"
                            color="primary"
                            (click)="edit(element, $event)">
                        <mat-icon>edit</mat-icon>
                        {{"Edit" | translate}}
                    </button>
                </td>
            </ng-container>
            <ng-container matColumnDef="fav">
                <th mat-header-cell
                    *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let element">
                    <div class="fav-wrapper">
                        <mat-icon *ngIf="isFav(element)"
                                  color="primary"
                                  (click)="unsetFav(element, $event)"
                                  [matTooltip]="'Cancel pin the device' | translate">star</mat-icon>
                        <mat-icon *ngIf="!isFav(element)"
                                  color="primary"
                                  (click)="markAsFav(element, $event)"
                                  [matTooltip]="'Pin the device' | translate">star_border</mat-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row
                *matRowDef="let row; columns: displayedColumns;"
                (click)="onSelect(row)"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row"
                *matNoDataRow>
                <td class="mat-cell"
                    *ngIf="!isLoading"
                    colspan="10">{{"No data matching" | translate}}</td>
            </tr>
        </table>

        <div class="loader-wrapper"
             *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <mat-paginator #paginator
                   [length]="resultsLength"
                   [pageSize]="30"
                   [pageSizeOptions]="pageOptions"
                   (page)="loadPage()"></mat-paginator>
</div>
