import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Solar, WebSolarConfiguration } from '@websolar/ng-websolar';
import { AMapMapService } from 'src/app/services/amap.map.service';
import { AIKO } from 'src/app/types/aiko.types';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-permit-summary',
    templateUrl: './permit-summary.component.html',
    styleUrls: ['./permit-summary.component.scss']
})
export class PermitSummaryComponent implements OnChanges {

    @Input() permitScope!: AIKO.PermitScope;

    public mapUrlLocale = "";

    public dcPower = 0;

    public acOutput = "";

    constructor(private _amapService: AMapMapService) { }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     * @param changes - An object containing the changed properties and their current and previous values.
     */
    public async ngOnChanges(changes: SimpleChanges) {
        if (this.permitScope) {
            const project = this.permitScope.project;

            if (environment.region == "cn") {
                // get a amap screenshot
                const result = await this._amapService.getMapScreenshotAsCanvas(project);
                this.mapUrlLocale = result.canvas.toDataURL();
            }
            else {
                const baseUrl = `${WebSolarConfiguration.service}/map/static?lat=${project.location.lat}&lng=${project.location.lng}&` +
                    `width=560&height=560`;
                this.mapUrlLocale = `${baseUrl}&zoom=19&type=satellite&marker=true`
            }

            this.dcPower = this.getDCPower();

            this.acOutput = this.getACPower();
        }
    }

    /**
     * Calculates the total AC power output of the inverters.
     * @returns The total AC power output in kilowatts (kW).
     */
    private getACPower() {
        const inverters = this.permitScope.objects.filter(s => s.type == "inverter").map(d => (d as Solar.ObjectInverter).inverter);
        let acOutput = 0;
        for (let inverter of inverters) {
            acOutput += inverter.maxPower;
        }
        return `${Math.round(acOutput * 10) / 10}`;
    }

    /**
     * Calculates the DC power by summing up the power of all segments in the permit scope.
     * @returns The rounded DC power value.
     */
    private getDCPower() {
        const output = {
            area: 0,
            count: 0,
            power: 0
        }
        const segments = this.permitScope.objects.filter(o => o.type == "segment") as Solar.ObjectRooftopSegment[];
        for (const segment of segments) {
            if (!segment.output) {
                continue;
            }
            output.area += segment.output.area;
            output.power += segment.output.power;
            output.count += segment.output.count;
        }

        // round
        return Math.round(output.power * 10) / 10;
    }

}
