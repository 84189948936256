import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Solar } from '@websolar/ng-websolar';

@Component({
    selector: 'app-load-calculations-table',
    templateUrl: './load-calculations-table.component.html',
    styleUrls: ['./load-calculations-table.component.scss']
})
export class LoadCalculationsTableComponent {

    public project: Solar.Project;

    public calcRes: Solar.BaseMountingCalculation;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            project: Solar.Project,
            calcRes: Solar.BaseMountingCalculation
        }
    ) {
        this.project = data.project;
        this.calcRes = data.calcRes;
    }

}
