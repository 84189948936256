import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Color } from '@swimlane/ngx-charts';
import { Solar, SolarInstance, WebSolarReportService, NotifyService, WebSolarLossesService, WebSolarCurrencyService, WebSolarEventsService } from '@websolar/ng-websolar';
import { Subject, Observable } from 'rxjs';
import { BomService } from 'src/app/services/bom.service';
import { FinanceService } from 'src/app/services/finance.service';
import { AIKO } from 'src/app/types/aiko.types';
import { TOUCH } from 'three';


@Component({
    selector: 'app-report-overview',
    templateUrl: './report-overview.component.html',
    styleUrls: ['./report-overview.component.scss']
})
export class ReportOverviewComponent implements OnChanges {

    @Input() project!: Solar.Project;

    @Input() objects!: Solar.Object[];

    @Input() showConsumption = false;

    @Output() previewInstanceReady = new EventEmitter<SolarInstance>();

    public currency: Solar.Currency;

    public events = new Subject<{ name: string, params: unknown }>();

    public eventsAsObservable: Observable<{ name: string, params: unknown }>;

    public schemeBillSaving = {
        domain: ['#fa5002']
    } as Color;

    public info = {
        cost: 0,
        savingsTotal: 0,
        payback: 0,
        ROI: 0,
        area: 0,
        modules: 0,
        inverters: 0,
        power: 0,
        production: 0,
        consumption: 0,
        strings: 0,
        stringsLength: 0,
        moduleName: "N/A",
        inverterName: "N/A",
        batteryName: "",
        batteries: 0,
        oldBill: 0,
        newBill: 0
    }

    public consumption!: Solar.ConsumptionOutput;

    public bomSource: AIKO.BOMRecord[] = [];

    public isViewExpanded = false;

    public isMobileScreen: boolean;

    public netSaving: { name: string, value: number }[] = [];

    public bomColumns: string[] = [];

    public showNoteComulative = false;

    public showNoteProduction = false;

    public isSimulationDone = false;

    constructor(
        private _reportService: WebSolarReportService,
        private _notify: NotifyService,
        private _bomService: BomService,
        private _lossesService: WebSolarLossesService,
        private _currencyService: WebSolarCurrencyService,
        private _financeService: FinanceService,
        private _eventService: WebSolarEventsService
    ) {
        this.eventsAsObservable = this.events.asObservable();
        this.currency = this._currencyService.getDefault();
        this.isMobileScreen = window.innerWidth < 800;
        this.isViewExpanded = !this.isMobileScreen;
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (this.objects && this.project) {
            this.update();
        }
    }

    public async update() {
        try {
            const res = this._financeService.getSystemCost(this.project, this.objects);
            this.info.cost = res.netCost;
            this.info.payback = res.payback;
            this.info.ROI = res.ROI;
            this.info.savingsTotal = res.savingsTotal;
            this.netSaving = res.netSaving;
            this.info.oldBill = res.oldBill;
            this.info.newBill = res.newBill;

            if (this.project && this.project.finance) {
                if (this.project.finance.mode == "pricePerDevice") {
                    this.bomColumns = ['device', 'model', 'quantity', 'price', 'total'];
                }
                else {
                    this.bomColumns = ['device', 'model', 'quantity'];
                }
            }

            this.currency = this.project.currency;

            // load DC losses
            try {
                this.project.dcLosses = await this._lossesService.getDesignLosses(this.objects, this.project);
            }
            catch (err) {
                console.error(`failed get DC losses`, err)
            }

            this.bomSource = await this._bomService.getBOM(this.project, this.objects);

            if (!this.project.consumption) {
                this.project.consumption = this._reportService.getDefaultConsumption(this.project);
            }
            this.consumption = this._financeService.getConsumptionOutput(this.project);

            const segmentInfo = this._reportService.getSegmentsInfoWithObjects(this.objects);
            this.info.area = segmentInfo.area;
            this.info.modules = segmentInfo.count;
            this.info.power = segmentInfo.power;

            const wiringInfo = this._reportService.getWiringInfo(this.objects, this.project);
            if (wiringInfo) {
                this.info.inverters = 0;
                this.info.inverters = this._reportService.getInvertersCount(this.objects);


                this.info.strings = wiringInfo.strings;
                this.info.stringsLength = wiringInfo.stringsLength;
            }

            const inverters = this.objects.filter(i => i.type == "inverter").map(d => (d as Solar.ObjectInverter).inverter);
            if (inverters.length) {
                // TODO: add multiply inverters
                const inverter = inverters[0];
                this.info.inverterName = `${inverter.manufacturer} ${inverter.model}`;
            }

            const segments = this.objects.filter(i => i.type == "segment") as Solar.ObjectRooftopSegment[];
            const oneSegment = segments.find(s => s.module)
            if (oneSegment && oneSegment.module) {
                const module = oneSegment.module;
                this.info.moduleName = `${module.manufacturer} ${module.model}`;
            }

            if (this.project.electrical.battery &&
                this.project.electrical.batteryCount) {
                const battery = this.project.electrical.battery;
                this.info.batteryName = `${battery.manufacturer} ${battery.model}`;
                this.info.batteries = this.project.electrical.batteryCount;
            }

            const productionInfo = this._reportService.getProduction(this.project);
            // convert to MWh
            this.info.production = Math.round(productionInfo.productionPower / 10) / 100;

            this.info.consumption = 0;
            for (const month of this.project.consumption.monthsConsumptions) {
                this.info.consumption += month.value;
            }
            this.info.consumption = Math.round(this.info.consumption);

            this.isSimulationDone = this.project.simulationStatus == "done";
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public onConsumptionChange() {
        if (!this.project) {
            return;
        }
        this.consumption = this._financeService.getConsumptionOutput(this.project);
        this.events.next({ name: "rebuild", params: null })
    }

    public toggleView() {
        this.isViewExpanded = !this.isViewExpanded;
        setTimeout(() => {
            this._eventService.send({ name: "resize_report_layout", params: null });
        }, 1);
    }


    public customColors = (year: number) => {
        const val = this.netSaving.find(v => v.name == year.toString());
        if (!val || val.value < 0) {
            return "#fb8801";
        }
        else {
            return "#fa5002";
        }
    }

    public onPreviewInstanceReady(instance: SolarInstance) {

        instance.orbitControl.touches = {
            ONE: TOUCH.ROTATE,
            TWO: TOUCH.DOLLY_PAN
        }

        instance.zoomToFit(true);

        this.previewInstanceReady.emit(instance);
    }
}
