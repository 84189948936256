<div class="component"
     *ngIf="project && project.electrical">

    <h2 class="title">
        {{"Battery" | translate}}
    </h2>

    <div *ngIf="!isReady && !errorMessage"
         class="progress-wrapper">

        <div>
            {{"Simulation is in progress. Your results will be ready soon ..." | translate}}
        </div>

        <app-loader></app-loader>

    </div>

    <div *ngIf="errorMessage">
        <div class="error-box">
            <mat-icon fontSet="material-icons-outlined">error</mat-icon>
            {{errorMessage | translate}}
        </div>
    </div>

    <ng-container *ngIf="isReady && !isLoading">

        <div>
            <app-battery-picker [(battery)]="project.electrical.battery"
                                [project]="project"
                                (batteryChange)="onBatteryChange()"></app-battery-picker>
        </div>


        <ng-container *ngIf="project.electrical.battery">


            <mat-form-field>
                <mat-label>{{"Number of Batteries" | translate}}</mat-label>
                <input matInput
                       [(ngModel)]="project.electrical.batteryCount"
                       (ngModelChange)="onChange()"
                       type="number"
                       [min]="0"
                       [max]="100">
                <span matSuffix>{{"pcs" | translate}}</span>

            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="consumption">

            <h3 class="chart-title">{{"BATTERY OVERVIEW" | translate}}</h3>
            <div *ngIf="!isLoading">
                <websolar-report-battery [consumption]="consumption"
                                         [project]="project"></websolar-report-battery>
            </div>

            <h3 class="chart-title">{{"SYSTEM OVERVIEW" | translate}}</h3>

            <div>
                <app-report-consumption-pipe [consumption]="consumption">
                </app-report-consumption-pipe>
            </div>

            <h3 class="chart-title">{{"MONTHLY PERFORMANCE" | translate}}</h3>
            <div>
                <app-report-production [project]="project"
                                       [consumption]="consumption">
                </app-report-production>
            </div>

            <div class="download-btn-wrapper"
                 *ngIf="region == 'cn'">
                <button mat-flat-button
                        color="primary"
                        (click)="downloadConsumption()">
                    <div class="btn-content">
                        <app-icon [src]="'icons/export_excel.svg' | assetUrl"></app-icon>
                        {{"Export Consumption" | translate}}
                    </div>
                </button>
            </div>


        </ng-container>

    </ng-container>
</div>
