<div class="component-wrapper">
    <div class="header">
        <div *ngIf="!optimizer._id">
            {{"Create New Optimizer" | translate}}
        </div>
        <div *ngIf="optimizer._id">
            {{"Edit Optimizer" | translate}}
        </div>
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="form"
         *ngIf="!isLoading">

         <mat-form-field>
            <mat-label>{{"Manufacturer" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="optimizer.manufacturer">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Model" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="optimizer.model">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Description" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="optimizer.description">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Maximum Efficiency (%)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.efficiency">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"European weighted efficiency (%)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.euroEfficiency">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Rated module power (Watt)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.pmax">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Maximum input voltage" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.voc">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"MPPT voltage lower limit" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.vmpMin">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"MPPT voltage upper limit" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.vmpMax">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Maximum short-circuit current (A)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.isc">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Optimizer single price" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="optimizer.price">
        </mat-form-field>


        <mat-form-field>
            <mat-label>Support module number</mat-label>
            <input matInput
                   type="number"
                   [min]="1"
                   [max]="3"
                   [(ngModel)]="optimizer.maxNumberOfModules">
        </mat-form-field>

        <div class="inverters">
            <mat-form-field>
                <mat-label>Support inverter model</mat-label>
                <input matInput
                       [ngModel]="invertersNames"
                       [readonly]="true"
                       (click)="pickInverters($event)">
            </mat-form-field>
        </div>        

        <div>
            <mat-checkbox [(ngModel)]="optimizer.optimalMatching">
                {{"Optional matching" | translate}}
            </mat-checkbox>
        </div>

        <div class="save-btn-wrapper">
            <button (click)="save()"
                    [disabled]="!isCanSave()"
                    mat-flat-button
                    color="primary">
                {{"Save Optimizer" | translate}}
            </button>
        </div>


    </div>
    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
