<div class="component">
    <div class="new-button">
        <button mat-stroked-button
                color="primary"
                (click)="onNew()">
            <mat-icon>add</mat-icon>
            {{'New Electric Meter' | translate}}
        </button>
    </div>

    <div class="list-item"
         *ngFor="let meter of meters">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(meter)"
                      class="title-text">
                    {{meter.name || ("Meter" | translate)}}
                </span>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDelete(meter)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div class="info"
                 (click)="toggle(meter)">

                <div *ngIf="itemState[meter.id]"
                     class="info-data">
                    <mat-icon inline="true">border_outer</mat-icon>
                    <span>{{itemState[meter.id].item.clearance | distance }}</span>
                </div>


                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[meter.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[meter.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[meter.id]?.expanded"
             class="item-content">
            <mat-form-field>
                <mat-label>{{'Description' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="meter.name">
            </mat-form-field>


            <websolar-number-input [label]="'Clearance' | translate"
                                   [(value)]="meter.clearance"
                                   (valueChange)="onItemChange(meter)"
                                   [placeholder]="'Enter the clearance' | translate"></websolar-number-input>

        </div>
    </div>

    <div class="hint"
         *ngIf="meters.length == 0">
        {{"You don't have any meters yet" | translate}}
    </div>

</div>
