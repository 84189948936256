<div *ngIf="permitScope && segment"
     class="component">

    <div class="package-card">
        <div class="layout-wrapper">
            <div class="page-lbl">
                {{"Attachment Plan" | translate}}
            </div>

            <websolar-mounting-layout [segment]="segment"
                                      [project]="permitScope.project"
                                      [objects]="permitScope.objects"
                                      [options]="renderingOptions"
                                      [disableWheel]="true"></websolar-mounting-layout>
        </div>
    </div>
</div>
