import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModulePickerWindowComponent } from '../module-picker-window/module-picker-window.component';
import { Solar, NotifyService, WebSolarEventsService } from '@websolar/ng-websolar';


@Component({
    selector: 'app-module-picker',
    templateUrl: './module-picker.component.html',
    styleUrls: ['./module-picker.component.scss']
})
export class ModulePickerComponent implements OnChanges {

    @Input() module: Solar.Module | null = null;

    @Input() project: Solar.Project | null = null;

    @Output() moduleChange = new EventEmitter<Solar.Module | null>();

    public isLoading = false;

    public moduleName = "";

    constructor(
        private _matDialog: MatDialog,
        private _notify: NotifyService,
        private _eventService: WebSolarEventsService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["module"]) {
            this.moduleName = this.displayFn(this.module)
        }
    }

    public moduleChanged() {

    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public displayFn(obj: any) {
        if (!obj) {
            return "";
        }
        if (typeof obj == "string") {
            return obj;
        }
        const module = obj as Solar.Module;
        return `${module.manufacturer} ${module.model} [${module.power}W]`;
    }


    public async openSearch(evt: MouseEvent) {

        evt.preventDefault();
        evt.stopPropagation();

        try {
            this._eventService.send({ name: "module_dialog_opened", params: null })

            let panels: string[] = [];

            const dialogRef = this._matDialog.open(ModulePickerWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    manufacturer: panels
                }
            });
            const res = await dialogRef.afterClosed().toPromise() as Solar.Module;
            if (!res) {
                this._eventService.send({ name: "module_dialog_dismiss", params: null })
                return;
            }
            this.module = res;
            this.moduleChange.emit(res);

            this.moduleName = this.displayFn(this.module);

            this._eventService.send({ name: "module_dialog_selected", params: null })
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public clear(evt: MouseEvent) {
        evt.preventDefault();
        evt.stopPropagation();

        this.module = null;
        this.moduleChange.emit(null);

        this.moduleName = ""
    }
}
