import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class MemoryService {

    private _storage: { [key: string]: unknown } = {};

    public getItem(key: string): unknown {
        return this._storage[key];
    }

    public setItem(key: string, obj: unknown) {
        this._storage[key] = obj;
    }

    public clear() {
        this._storage = {};
    }
}