import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app.material';
import { NgWebsolarModule } from '@websolar/ng-websolar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { AppSharedModule } from '../app.shared.module';
import { PermitPageComponent } from './permit-page/permit-page.component';
import { PermitSummaryComponent } from './permit-summary/permit-summary.component';
import { LocalCssComponent } from '../components/local-css/local-css.component';
import { PermitSitePlanComponent } from './permit-site-plan/permit-site-plan.component';
import { PermitScaleComponent } from './permit-scale/permit-scale.component';
import { PermitBomComponent } from './permit-bom/permit-bom.component';
import { PermitSldComponent } from './permit-sld/permit-sld.component';
import { PermitConductorScheduleComponent } from './permit-conductor-schedule/permit-conductor-schedule.component';
import { PermitOcpdScheduleComponent } from './permit-ocpd-schedule/permit-ocpd-schedule.component';
import { PermitAttachmentPlanComponent } from './permit-attachment-plan/permit-attachment-plan.component';
import { PermitMaterialsMountingComponent } from './permit-materials-mounting/permit-materials-mounting.component';
import { PermitSafetySignsComponent } from './permit-safety-signs/permit-safety-signs.component';
import {AssetUrlPipe} from "../pipes/asset.url.pipe";

@NgModule({
    declarations: [
        PermitPageComponent,
        PermitSummaryComponent,
        LocalCssComponent,
        PermitSitePlanComponent,
        PermitScaleComponent,
        PermitBomComponent,
        PermitSldComponent,
        PermitConductorScheduleComponent,
        PermitOcpdScheduleComponent,
        PermitAttachmentPlanComponent,
        PermitMaterialsMountingComponent,
        PermitSafetySignsComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        NgWebsolarModule,
        NgxChartsModule,
        FormsModule,
        AppSharedModule
    ],
    exports: [
        PermitPageComponent
    ],
    providers: [],
})
export class AppPermitModule {}
