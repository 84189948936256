<div class="share-page"
     *ngIf="project && !isLoading">
    <app-reports-panel [project]="project"
                       [objects]="objects"
                       [shared]="true"
                       [showConsumption]="true"></app-reports-panel>
</div>


<div class="loading-wrapper"
     *ngIf="isLoading">
    <websolar-loading></websolar-loading>
</div>
