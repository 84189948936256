import * as XLSX from "xlsx"
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyService, Solar } from "@websolar/ng-websolar";
import moment from "moment";
import { AIKO } from "src/app/types/aiko.types";

@Component({
    selector: 'app-consumption-import',
    templateUrl: './consumption-import.component.html',
    styleUrls: ['./consumption-import.component.scss']
})
export class ConsumptionImportComponent {

    public fileTypes = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

    public filename = "";

    private _file?: File;

    private _project: AIKO.ProjectExt;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            project: AIKO.ProjectExt
        },
        private _dialogRef: MatDialogRef<ConsumptionImportComponent>,
        private _notify: NotifyService
    ) {
        this._project = data.project;
    }

    public async onFileInput(evt: Event) {
        const target: HTMLInputElement | null = evt.target as HTMLInputElement;
        if (target && target.files) {
            await this.handleFiles(target.files);
        }
    }

    public async handleFiles(files: FileList): Promise<void> {
        this.filename = files[0].name;
        this._file = files[0];
    }

    public onClear() {
        this.filename = '';
    }

    public async uploadFile() {
        try {
            if (!this._file) {
                throw `file is not available`
            }

            const data = await this._file.arrayBuffer();
            const workbook = XLSX.read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];


            // Get the range of the worksheet
            const range = XLSX.utils.decode_range(worksheet['!ref'] as string);

            // Iterate over each row and column within the range
            const rows: string[][] = [];
            // add +1 to skip a header
            for (let rowNum = range.s.r + 1; rowNum <= range.e.r; rowNum++) {
                const row = [];
                for (let colNum = 0; colNum < 3; colNum++) {
                    const cellAddress = { c: colNum, r: rowNum };
                    const cellRef = XLSX.utils.encode_cell(cellAddress);
                    const cell = worksheet[cellRef];
                    if (!cell) {
                        continue;
                    }
                    const val = cell.w ? cell.w : cell.v;
                    if (typeof val != "string") {
                        continue;
                    }
                    row.push(val);
                }
                // console.log(`Row ${rowNum + 1}:`, row);
                if (row.length) {
                    rows.push(row);
                }
            }


            // verify rows
            const consumptions: { month: number; value: number; }[] = [];
            const importedConsumption: AIKO.ConsumptionRecord[] = [];

            for (const row of rows) {
                if (row.length != 3) {
                    throw `found invalid row: ${row}`
                }
                let date = moment(row[0], "MM/DD/YY");
                if (!date.isValid()) {
                    // try other format
                    date = moment(row[0], "YYYY/MM/DD");
                    if (!date.isValid()) {
                        throw `found invalid row: ${row}`
                    }
                }
                const time = row[1].split(":");
                if (time.length != 2) {
                    throw `found invalid row: ${row}`
                }
                const hr = parseInt(time[0]);
                const min = parseInt(time[1]);
                if (isNaN(hr) || isNaN(min)) {
                    throw `found invalid row: ${row}`
                }

                const power = parseFloat(row[2]);
                if (isNaN(power)) {
                    throw `found invalid row: ${row}`
                }

                const monthRecord = consumptions.find(c => c.month == date.month());
                if (monthRecord) {
                    monthRecord.value += power;
                }
                else {
                    consumptions.push({
                        month: date.month(),
                        value: power
                    })
                }

                importedConsumption.push({
                    month: date.month(),
                    day: date.day(),
                    year: date.year(),
                    hr: hr,
                    min: min,
                    value: power
                })
            }

            // console.log("parsed rows:", rows);

            if (rows.length < 35136) {
                this._notify.error(`Data is missing, please upload the electricity consumption data for 365 consecutive days`)
                this._dialogRef.close();
                return;
            }

            if (!this._project.consumption) {
                throw `no consutmption`
            }

            consumptions.sort((i1, i2) => { return i1.month - i2.month });

            this._project.consumption.monthsConsumptions = consumptions;
            this._project.importedConsumption = importedConsumption;

            this._dialogRef.close(true);
        }
        catch (err) {
            console.error(err);
            this._notify.error("File parsing error, please refer to the template to re-upload the file");
            this._dialogRef.close();
        }
    }
}
