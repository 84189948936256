import { Component, OnInit } from '@angular/core';
import { NotifyService, Solar, WebSolarColors, WebSolarConfiguration, WebSolarLangService, WebSolarReportService } from '@websolar/ng-websolar';
import { environment } from 'src/environments/environment';
import { QueryService } from './services/query.service';
import { HttpService } from './services/http.service';
import { MemoryService } from './services/memory.service';
import { MicroTool } from './core/micro.tool'
import { assetUrl } from "./pipes/asset.url.pipe";
import { LossesService } from './services/losses.service';
import { StringFactory } from './rendering/string.factory';
import { AikoModelStorage } from './rendering/aiko.model.storage';
import { OptimizerFactory } from './rendering/optimizer.factory';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(
        private _notify: NotifyService,
        private _langService: WebSolarLangService,
        private _queryService: QueryService,
        private _httpService: HttpService,
        private _memoryService: MemoryService,
        private _lossesService: LossesService
    ) { }

    async ngOnInit() {
        try {
            // setup SDK configuration
            //
            WebSolarConfiguration.service = environment.service;
            WebSolarConfiguration.logo = assetUrl(`/icons/aiko.png`);
            // IMPORTANT: the license will expire on 2025-06-10
            WebSolarConfiguration.setLicense("78757c0c697a77694166245602485e617662671a5b56593f");
            // activate the rebuild mode by default
            WebSolarConfiguration.features.autoRebuildMode = true;
            WebSolarConfiguration.features.rebuildOnTransaction = false;
            WebSolarConfiguration.features.allowGripTransform = false;

            if (environment.cloudStorage) {
                // set cloud storage url for the public resources
                WebSolarConfiguration.storageUrl = environment.cloudStorage;
            }
            else {
                // google cloud storage
                WebSolarConfiguration.storageUrl = "https://storage.googleapis.com/websolar_public";
            }

            // add custom rendering
            WebSolarConfiguration.customFactories.push(new StringFactory());
            WebSolarConfiguration.customFactories.push(new OptimizerFactory());

            // set custom colors
            WebSolarColors.segmentBorder = "#ffffff";
            WebSolarColors.gripPoint = "#ffffff";

            // load the rendering models
            await AikoModelStorage.init();

            if (environment.region == "cn") {
                // set the default lang
                await this._langService.setLang("zh-CN");
            }

            const query = this._queryService.getSnapshot();
            if (MicroTool.isInMicroApp()) {
                this._notify.onError.subscribe((error: unknown) => {
                    MicroTool.errorWatcher(error)
                })
                const data = window.microApp.getData()
                const lang = data.lang
                await this._langService.setLang(lang as string);
            } else {
                if (query["lang"]) {
                    console.log(`Detected the language: `, query["lang"])
                    await this._langService.setLang(query["lang"] as string);
                }

                if (environment.region != "cn") {
                    // Load google script
                    await this.loadGoogleScript();
                }
            }


            if (environment.region == "cn") {
                this.loadAMap();
            }
            else {
                // Load google API Key
                this.loadGoogleAPIKey();
            }
        }
        catch (err) {
            this._notify.error(err);
        }
    }



    private async loadAMap() {
        const key = await this._httpService.post(`/amap-frontend-key`) as { APIKey: string, Code: string };

        // note that this setting must be set before the JSAPI script is loaded, otherwise the setting will be invalid .
        window._AMapSecurityConfig = {
            securityJsCode: key.Code
        }

        this._memoryService.setItem("AMAP_API_KEY", key.APIKey);

        this.loadAMapScript(key.APIKey);
    }

    private async loadAMapScript(key: string) {
        // load script to DOM
        const script = document.createElement('script');
        script.src = `https://webapi.amap.com/maps?v=2.0&key=${key}&plugin=AMap.AutoComplete,AMap.CitySearch,AMap.Geocoder`;
        script.type = 'text/javascript';
        script.async = false;
        script.defer = true;
        document.head.appendChild(script);
    }


    private async loadGoogleScript() {
        // load script to DOM
        const script = document.createElement('script');
        script.src = `${environment.service}/google-script`;
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }

    private async loadGoogleAPIKey() {
        const key = await this._httpService.post(`/google-frontend-key`) as { APIKey: string };
        this._memoryService.setItem("GOOGLE_API_KEY", key.APIKey);
    }
}
