<div class="report-production-component"
     *ngIf="project && lineData.length && this.transform">
    <ngx-charts-chart [view]="[width, height]"
                      [showLegend]="false"
                      [legendOptions]="legendOptions"
                      [activeEntries]="activeEntries"
                      [animations]="false"
                      (legendLabelActivate)="onActivate($event, null, true)"
                      (legendLabelDeactivate)="onDeactivate($event, null, true)"
                      (legendLabelClick)="onClick($event)">
        <svg:g [attr.transform]="transform"
               class="bar-chart chart">
            <svg:g ngx-charts-grid-panel-series
                   [xScale]="groupScale"
                   [yScale]="valueScale"
                   [data]="results"
                   [dims]="dims"
                   [orient]="barOrientation.Vertical"></svg:g>
            <svg:g ngx-charts-x-axis
                   *ngIf="xAxis"
                   [xScale]="groupScale"
                   [dims]="dims"
                   [showLabel]="showXAxisLabel"
                   [trimTicks]="trimXAxisTicks"
                   [rotateTicks]="rotateXAxisTicks"
                   [maxTickLength]="maxXAxisTickLength"
                   [xAxisOffset]="dataLabelMaxHeight.negative"
                   (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
            <svg:g ngx-charts-y-axis
                   *ngIf="yAxis"
                   [yScale]="valueScale"
                   [dims]="dims"
                   [showGridLines]="showGridLines"
                   [showLabel]="showYAxisLabel"
                   [labelText]="yAxisLabel"
                   [trimTicks]="trimYAxisTicks"
                   [maxTickLength]="maxYAxisTickLength"
                   (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
            <svg:g>
                <svg:g ngx-charts-series-vertical
                       *ngFor="let group of results; let index = index; trackBy: trackBy"
                       [attr.transform]="groupTransform(group)"
                       [activeEntries]="activeEntries"
                       [xScale]="innerScale"
                       [yScale]="valueScale"
                       [colors]="colors"
                       [series]="(group | castAny).series"
                       [dims]="dims"
                       [gradient]="gradient"
                       [tooltipDisabled]="tooltipDisabled"
                       [tooltipTemplate]="tooltipTemplate"
                       [showDataLabel]="showDataLabel"
                       [seriesName]="group.name"
                       [roundEdges]="roundEdges"
                       [animations]="animations"
                       [noBarWhenZero]="noBarWhenZero"
                       (select)="onClick($event, group)"
                       (activate)="onActivate($event, group)"
                       (deactivate)="onDeactivate($event, group)"
                       (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)" />
            </svg:g>
        </svg:g>

        <svg:g [attr.transform]="transform"
               class="line-chart chart">
            <svg:g>
                <svg:g *ngFor="let series of lineChart">
                    <svg:path class="line"
                              [attr.d]="series.pathBg"
                              [attr.stroke]="'#ffffff'"
                              stroke-width="6px" />
                    <svg:path class="line"
                              [attr.d]="series.path"
                              [attr.stroke]="'#2cdd55'"
                              stroke-width="1.5px" />
                </svg:g>
            </svg:g>
        </svg:g>

    </ngx-charts-chart>

    <div class="legend-wrapper"
         *ngIf="legendVisible">
        <div class="item">
            <div class="prod-marker"></div>
            {{"Production" | translate}}
        </div>
        <div class="item">
            <div class="consumption-marker"></div>
            {{"Consumption" | translate}}
        </div>
        <div class="item">
            <div class="solar-marker"></div>
            {{"Solar Consumption" | translate}}
        </div>
    </div>

</div>
