import { Solar } from "@websolar/ng-websolar";

export class MapProjector {

    public getSizeInMeters(zoom: number, location: Solar.GeoLocation, imgWidth: number) {
        // let earthLen = 6371000 * 2 * Math.PI;
        const metresPerPixel = 40075016.686 * Math.abs(Math.cos(location.lat * Math.PI / 180)) / Math.pow(2, zoom + 8);
        return metresPerPixel * imgWidth;
    }

    /**
     * Calculates the spherical offset of a given location based on heading and distance.
     * @param location - The starting geo location.
     * @param heading - The heading in degrees clockwise from north.
     * @param distance - The distance in meters.
     * @returns The offset location.
     */
    public getSphericalOffset(location: Solar.GeoLocation, heading: number, distance: number): Solar.GeoLocation {
        const R = 6378137; // Earth's mean radius in meters
        // const distance = 100; // distance in meters
        // const heading = 90; // heading in degrees clockwise from north

        const dLat = distance * Math.cos(heading * Math.PI / 180) / R;
        const dLng = distance * Math.sin(heading * Math.PI / 180) / (R * Math.cos(location.lat * Math.PI / 180));

        const offsetLat = location.lat + dLat * 180 / Math.PI;
        const offsetLng = location.lng + dLng * 180 / Math.PI;

        return { lat: offsetLat, lng: offsetLng } as Solar.GeoLocation;
    }
}