import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyService, Solar, WebSolarInverterService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-inverters-multiselect',
    templateUrl: './inverters-multiselect.component.html',
    styleUrls: ['./inverters-multiselect.component.scss']
})
export class InvertersMultiselectComponent implements OnInit {

    /**
     * Represents a collection of inverters with their selected state.
     */
    public items: { selected: boolean, inverter: Solar.Inverter }[] = [];

    public isLoading = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: { preselectedInverters: string[] },
        private _dialogRef: MatDialogRef<InvertersMultiselectComponent>,
        private _invService: WebSolarInverterService,
        private _notify: NotifyService
    ) { }

    /**
     * Initializes the component.
     * Loads all inverters.
     */
    public async ngOnInit() {
        try {
            this.isLoading = true;

            // load all inverters
            const inverters = await this._invService.find({ limit: 1000 });
            this.items = [];
            for (const inv of inverters) {
                let selected = false;
                if (this._data.preselectedInverters) {
                    selected = this._data.preselectedInverters.includes(inv._id as string)
                }
                this.items.push({
                    selected: selected,
                    inverter: inv
                })
            }
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Saves the selected inverters and closes the dialog.
     */
    public save() {
        const selectedInverters = this.items
            .filter(i => i.selected)
            .map(i => i.inverter);
        this._dialogRef.close(selectedInverters);
    }

    /**
     * Closes the dialog and returns a null value.
     */
    public close() {
        this._dialogRef.close(null);
    }
}
