<div class="component">

    <div class="topbar">
        <a [href]="'templates/power_load_template.xlsx' | assetUrl">{{"Download Template" | translate}}</a>
        <mat-icon mat-dialog-close>close</mat-icon>
    </div>
    <div class="content">
        <ng-container *ngIf="!filename">
            <div class="add-wrapper">
                <div>
                    <mat-icon (click)="fileInput.click()"
                              inline="true">add</mat-icon>
                    <form>
                        <input type="file"
                               id="file-id"
                               #fileInput
                               [accept]="fileTypes"
                               (change)="onFileInput($event)" />
                    </form>
                </div>
            </div>
            <div>
                {{"Please upload. xlsx and. xls files" | translate}}
            </div>
        </ng-container>

        <ng-container *ngIf="filename">
            <div class="file-icon-warapper">
                <app-icon [src]="'icons/excel.svg' | assetUrl"></app-icon>
            </div>
            <div>
                {{filename}}
            </div>
            <button mat-flat-button
                    color="primary"
                    (click)="uploadFile()">
                Upload
            </button>

        </ng-container>
    </div>

</div>
