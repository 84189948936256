import { Solar, WebSolarConfiguration } from "@websolar/ng-websolar";
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export class AikoModelStorage {
    public static _objects: { [key: string]: THREE.Object3D } = {};

    /**
     * Load objects
     */
    public static async init() {

        if (Object.keys(this._objects).length) {
            // already loaded
            return;
        }

        await Promise.all(
            [
                this.loadObject("optimizer-aiko", "optimizer-aiko.glb")
            ]
        )
    }

    private static async loadObject(key: string, filename: string) {
        const loader = new GLTFLoader();
        const url = `${WebSolarConfiguration.storageUrl}/3d_models/${filename}`;
        const data = await loader.loadAsync(url);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const object = data.scene.children.find(c => c.name == "main_object" || c.name == "modelobj") as any

        this._objects[key] = object;
    }

    public static getObject(id: Solar.ObjectModelId) {
        return this._objects[id].clone();
    }
}