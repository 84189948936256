import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { AIKO } from "../types/aiko.types";

@Injectable({
    providedIn: 'root'
})
export class MaterialService {
    constructor(
        private _http: HttpService
    ) { }

    public async find(search: {
        id?: string | string[],
        search?: string,
        skip?: number,
        limit?: number
    }): Promise<AIKO.Material[]> {
        return await this._http.post(`/material/find`, search);
    }

    public async count(search: {
        search?: string,
        model?: string,
        manufacturer?: string | string[],
        maxSize?: number
    }): Promise<number> {
        const res = await this._http.post(`/material/count`, search) as { count: number };
        return res.count;
    }

    public async save(item: AIKO.Material) {
        const res = await this._http.post(`/material/save`, item) as { id: string };
        return res.id;
    }

    public async delete(options: { id?: string }) {
        return await this._http.post(`/material/delete`, options);
    }
}