import { Injectable } from "@angular/core";
import { Solar, WebSolarReportService } from "@websolar/ng-websolar";
import { LossesService } from "./losses.service";
import { AIKO } from "../types/aiko.types";


@Injectable()
export class FinanceService {

    constructor(
        private _reportService: WebSolarReportService,
        private _lossesService: LossesService
    ) { }

    /**
     * Calculates the system cost and financial metrics for a solar project.
     * 
     * @param project - The solar project object.
     * @param objects - An array of solar objects.
     * @returns An object containing the system cost, price per watt, payback period, ROI, savings total, bill savings, and net savings.
     */
    public getSystemCost(project: Solar.Project, objects: Solar.Object[]) {
        const segmentInfo = this._reportService.getSegmentsInfoWithObjects(objects);
        const billSaving: { name: string, value: number }[] = [];
        const netSaving: { name: string, value: number }[] = [];

        let netCost = 0;
        let pricePerWatt = 1.5; // default

        if (project.finance &&
            project.finance.useCustomNetCost) {

            if (project.finance.mode == "pricePerDevice") {
                const bom = this._reportService.getBOM(project, objects);
                for (const record of bom) {
                    netCost += (record.total || 0);
                }
            }
            else {
                netCost = project.finance.customNetCost || 0;

                pricePerWatt = Math.round(netCost / (segmentInfo.power) / 10) / 100;
            }
        }
        else {
            // get default cost
            netCost = segmentInfo.power * 1000 * pricePerWatt;

            // add battery cost
            if (project.electrical.batteryCount &&
                project.electrical.battery) {
                // $0.7 per watt
                netCost += (project.electrical.battery.capacity * 1000 / 0.7) * project.electrical.batteryCount;
            }
        }

        if (project.finance && project.finance.taxRebate) {
            netCost = netCost * (1 - (project.finance.taxRebate / 100));
        }

        let payback = 0;
        // calculate the payback
        // get net savgings for 30 years
        const years = 30;
        const savings = this._reportService.getBillSavingsWithObjects(project, objects, years);

        let savingTotal = 0;
        let netValue = -netCost;
        let opexTotal = 0;

        // take 1% by default for OPEX
        const opexPerYear = netCost * 0.1;

        for (const saving of savings) {

            // cal total saving
            savingTotal += saving.value;
            // calc total OPEX
            opexTotal += opexPerYear;

            billSaving.push({
                name: saving.year.toString(),
                value: savingTotal
            })

            // add saving
            netValue += saving.value;

            // subsract OPEX
            netValue -= opexPerYear;

            if (netValue < 0) {
                // calc the payback period
                payback++;
            }

            netSaving.push({
                name: saving.year.toString(),
                value: netValue
            })
        }


        let ROI = 0;

        // calculate the ROI for 30 years
        // ROI = profit / (CAPEX + OPEX) in percent (%)
        if (netCost > 0) {
            ROI = Math.round(savingTotal / (netCost + opexTotal) * 100);
        }
        else {
            ROI = 0;
        }


        const consumption = this._reportService.getConsumptionOutput(project);

        // get estimated bill
        const oldTotalBill = consumption.months
            .reduce((prev, cur) => prev + cur.nonOptimizedBilling, 0);
        const oldBill = Math.round(oldTotalBill / 12);

        // get saving
        const totalSaving = consumption.months.reduce((prev, cur) => prev + cur.saving, 0)
        const newBill = Math.max(Math.round((oldTotalBill - totalSaving) / 12), 0);

        return {
            netCost: Math.round(netCost),
            pricePerWatt: pricePerWatt,
            payback: payback,
            ROI: ROI,
            savingsTotal: savingTotal,
            billSaving: billSaving,
            netSaving: netSaving,
            oldBill: oldBill,
            newBill: newBill
        };
    }

    /**
     * Retrieves the consumption output for a given project.
     * @param project - The project for which to retrieve the consumption output.
     * @returns The consumption output for the project.
     */
    public getConsumptionOutput(project: Solar.Project): Solar.ConsumptionOutput {
        const customCalc: Solar.CustomOutputCalculation = {
            losses: this._lossesService.userDefinedLosses
        };

        const aikoProject = project as AIKO.ProjectExt;
        if (aikoProject.importedConsumption) {
            customCalc.consumption = (month: number, day: number, hr: number) => {
                // get consumption for hour
                return this.getCustomConsumption(month, day, hr, aikoProject);
            }
        }

        return this._reportService.getConsumptionOutput(project, customCalc);
    }

    /**
     * Retrieves the custom consumption value for a specific month, day, and hour from the given project.
     * If the project does not have imported consumption data, it returns 0.
     * @param month - The month of the consumption record.
     * @param day - The day of the consumption record.
     * @param hr - The hour of the consumption record.
     * @param project - The project containing the imported consumption data.
     * @returns The custom consumption value for the specified month, day, and hour.
     */
    private getCustomConsumption(month: number, day: number, hr: number, project: AIKO.ProjectExt): number {
        if (!project.importedConsumption) {
            return 0;
        }
        const records = project.importedConsumption.filter(r => r.month == month && r.day == day && r.hr == hr);
        return records.reduce((prev, cur) => prev + cur.value, 0);
    }
}