import { Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Solar, NotifyService, SolarInstance, LocalLock, WebSolarConfiguration } from '@websolar/ng-websolar';
import { Subject, Observable } from 'rxjs';
import { LoadCalculationsTableComponent } from '../load-calculations-table/load-calculations-table.component';
import { MountingCalcService } from 'src/app/services/mounting.calc.service';
import { MountingService } from 'src/app/services/mounting.service';
import { AIKO } from 'src/app/types/aiko.types';
import { RailService } from 'src/app/services/rail.service';
import { HookService } from 'src/app/services/hook.service';
import { WindZoneService } from 'src/app/services/windZones.service';
import { AnnexService } from 'src/app/services/annex.service';
import { SnowZoneService } from 'src/app/services/snowZones.service';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
    selector: 'app-mounting-panel',
    templateUrl: './mounting-panel.component.html',
    styleUrls: ['./mounting-panel.component.scss']
})
export class MountingPanelComponent implements OnChanges {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    public calcRes!: Solar.BaseMountingCalculation;

    public isCaclulating = false;

    public hasResults = false;

    private _lock = new LocalLock();

    private _timer: unknown;

    public events = new Subject<{ name: string, params: unknown }>();

    public eventsAsObservable: Observable<{ name: string, params: unknown }>;

    public manufacturers: string[] = []

    public roofTypes: { id: Solar.RoofType, name: string }[] = [
        { id: "Monopitch", name: "Monopitch Roof" },
        { id: "Duopitch", name: "Duopitch Roof" },
        { id: "Hipped", name: "Hipped" }
    ]

    public rails: AIKO.Rail[] = [];

    public hooks: AIKO.Hook[] = [];

    /**
     * Filtered by manufactorer
     */
    public mountingSystems: AIKO.MountingSystemEntity[] = [];

    /**
     * Loaded mounting system
     */
    private _mountingSystems: AIKO.MountingSystemEntity[] = [];

    /**
     * Structure Design Life options
     */
    public designLifes = [25, 50];

    public numberOfRailes = [2];

    public beamSpanNumbers = [2, 3, 4, 5];

    public segments: Solar.ObjectRooftopSegment[] = [];

    public activeSegment!: Solar.ObjectRooftopSegment;

    public objects: Solar.Object[] = [];

    public renderingOptions: Solar.MountingRenderSettings = {
        /**
         * 300mm is the maximum for Cantiliver
         */
        maxCantiliver: 0.3,
        /**
         * 100 mm is rail extension
         * The extra part is used to place the briquette.
         */
        railEndExtension: 0.1,
        clampsUtilization: false,
        hooksUtilization: false,
        zones: false,
        hooks: true,
        measurements: true,
        modules: true,
        rails: true,
        clamps: false,
        rafters: true,
        obstacles: true,
        enableTooltip: false        
    }

    public region: "cn" | "eu" = "eu";

    private _annex?: AIKO.Annex;

    constructor(
        private _notify: NotifyService,
        private _matDialog: MatDialog,
        private _mountingService: MountingService,
        private _calcService: MountingCalcService,
        private _railService: RailService,
        private _hookService: HookService,
        private _dialogService: DialogService,
        private _annexService: AnnexService
    ) {
        this.eventsAsObservable = this.events.asObservable();
    }

    /**
     * Lifecycle hook that is called when any data-bound property of a directive changes.
     */
    public ngOnChanges(): void {
        try {
            if (this.instance && this.project) {
                this.init();
            }
        }
        catch (err) {
            console.error();
        }
    }

    /**
     * Initializes the mounting panel component.
     * This method retrieves necessary data and performs calculations for the component.
     */
    private async init() {
        try {
            if (!this.instance || !this.project) {
                return;
            }

            this.segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
            this.segments = this.segments.filter(s => s.module);
            this.activeSegment = this.segments[0];

            this.rails = await this._railService.find({ limit: 1000 });
            this.hooks = await this._hookService.find({ limit: 1000 });

            const loadedMountSystems = await this._mountingService.find({ limit: 1000 });

            // filter out the invalid systems
            //
            this._mountingSystems = [];
            for (const mountSystem of loadedMountSystems) {
                if (!mountSystem.railId || !mountSystem.hookId) {
                    continue;
                }
                if (!this.rails.find(r => r._id == mountSystem.railId)) {
                    continue;
                }
                if (!this.hooks.find(r => r._id == mountSystem.hookId)) {
                    continue;
                }

                // check if it supported the project size
                const projectSize = this.project.projectSize || "1x";
                if (!mountSystem.projectSize || !mountSystem.projectSize.includes(projectSize)) {
                    continue;
                }

                this._mountingSystems.push(mountSystem);
            }

            this.mountingSystems = this._mountingSystems.filter(m => m.manufacturer == this.project.mountingSystem.type);

            this.manufacturers = loadedMountSystems.map(r => r.manufacturer).filter((v, idx, arr) => arr.indexOf(v) == idx);

            this.objects = this.instance.getObjects({});

            this.region = (this.project.country == "China") ? "cn" : "eu";

            await this.initAnnex();

            await this.verify();

            this.initActiveSegment();

            this.runCalc();
        }
        catch (err) {
            console.error();
        }
    }

    private async verify() {
        const deletedTypes: string[] = [];

        // 
        // Mounting system
        //
        const mountingSystemId = this.project.mountingSystem?.mountingSystemId;
        if (mountingSystemId) {
            const mountingSystems = await this._mountingService.find({ id: mountingSystemId })
            const mountingSystem = mountingSystems[0];
            if (!mountingSystem ||
                !mountingSystem.railId ||
                !mountingSystem.hookId) {
                if (!deletedTypes.includes("Mounting system")) {
                    deletedTypes.push("Mounting system");
                }
                this.project.mountingSystem.mountingSystemId = "";
            }
            else if (mountingSystem && mountingSystem.railId && mountingSystem.hookId) {
                // load rails
                const rails = await this._railService.find({ id: mountingSystem.railId });
                const rail = rails[0];

                const hooks = await this._hookService.find({ id: mountingSystem.hookId });
                const hook = hooks[0];

                if (!rail || !hook) {
                    if (!deletedTypes.includes("Mounting system")) {
                        deletedTypes.push("Mounting system");
                    }
                }
            }
        }
        else if (this.project.mountingSystem) {
            // check if it is the previous project version
            const prevVersion = (this.project.mountingSystem as unknown) as { [key: string]: string; };
            if (prevVersion["railType"]) {
                // clear it
                prevVersion["railType"] = "";

                if (!deletedTypes.includes("Mounting system")) {
                    deletedTypes.push("Mounting system");
                }
            }
        }

        // 
        // Show warns
        //
        for (const delType of deletedTypes) {
            await this._dialogService.confirm({
                title: ``,
                text: `The '${delType}' you selected has been deleted, please choose a new one`,
                hideCancel: true
            })
        }
    }

    private async initAnnex() {
        if (!this.project.country) {
            return;
        }
        if (this.project.country == "China") {
            // annex avaialble only for EU
            return;
        }

        this._annex = await this._annexService.findOne({
            country: this.project.country
        });
        if (!this._annex || !this._annex.enabled) {
            this._dialogService.confirm({
                title: "",
                text: "The country is not in the scope of design.",
                hideCancel: true
            })
            this._annex = undefined;
            return;
        }

    }

    private initActiveSegment() {
        if (!this.activeSegment) {
            return;
        }

        if (!this.activeSegment.mountingSystem.orientation) {
            this.activeSegment.mountingSystem.orientation = this.activeSegment.orientation == "horz" ? "vert" : "horz";
        }

        if (!this.activeSegment.mountingSystem.rafterSpan ||
            this.activeSegment.mountingSystem.rafterSpan == 0.4064) {
            // set default
            this.activeSegment.mountingSystem.rafterSpan = 0.4;
        }

        let verticalSpan = 0.8;

        if (this.activeSegment.mountingSystem.orientation == "horz") {
            if (this.activeSegment.module?.model.includes("MAH72")) {
                verticalSpan = 1.3;
            }
            else if (this.activeSegment.module?.model.includes("MAH54")) {
                verticalSpan = 1.1;
            }
        }

        if (!this.activeSegment.mountingSystem.railSpan) {
            // set default
            this.activeSegment.mountingSystem.railSpan = verticalSpan;
        }

        if (!this.activeSegment.mountingSystem.hookSpan) {
            // set default
            this.activeSegment.mountingSystem.hookSpan = verticalSpan;
        }

        if (!this.activeSegment.mountingSystem.buildingHeight) {
            // get height from active segment
            const maxZ = this.activeSegment.points
                .map(p => p.z)
                .reduce((prev, cur) => Math.max(prev, cur), 0);
            this.activeSegment.mountingSystem.buildingHeight = Math.round(maxZ * 10) / 10;
        }



        if (!this.project.mountingSystem.moduleWeight) {
            if (this.activeSegment.module) {
                this.project.mountingSystem.moduleWeight = this.activeSegment.module.weight || 0;
            }
        }

        if (!this.project.mountingSystem.mountingSystemId) {
            // set the default mounting system "M36/40+roof hook"
            const defaultSystem = this._mountingSystems.find(m => m.model == "M36/40+Roof Hook")
            if (defaultSystem) {
                this.project.mountingSystem.mountingSystemId = defaultSystem._id;
                this.project.mountingSystem.type = defaultSystem.manufacturer;
            }
        }
    }


    /**
     * Handles the change event of the segment.
     * Initializes the active segment and triggers the options change.
     */
    public onSegmentChange() {
        this.initActiveSegment();

        this.onOptionsChange();
    }

    /**
     * Handles the change event of the options.
     * Sends a notification to the canvas layout and runs the calculation debounce.
     */
    public onOptionsChange() {
        this.runCalcDebounce();
    }

    public onTypeOptionsChange() {

        this.mountingSystems = this._mountingSystems.filter(m => m.manufacturer == this.project.mountingSystem.type);

        this.runCalcDebounce();
    }

    /**
     * Runs the calculation with a debounce timer.
     * This method updates the calculation after a delay of 100 milliseconds.
     */
    public runCalcDebounce() {

        if (this._timer) {
            clearTimeout(this._timer as number);
        }

        this._timer = setTimeout(() => {
            // update calculation
            this.runCalc();
        }, 100);
    }


    /**
     * Runs the calculation for the mounting panel.
     * 
     * @returns {Promise<void>} A promise that resolves when the calculation is complete.
     */
    public async runCalc() {
        try {
            await this._lock.lock();

            this.isCaclulating = true;
            this.hasResults = false;


            if (!this.project ||
                !this.project._id ||
                !this.project.mountingSystem ||
                !this.activeSegment ||
                !this.project.mountingSystem.terrainCategory ||
                !this.objects ||
                !this.objects.length) {
                return;
            }

            if (this.project.country != "China") {
                if (!this._annex) {
                    return;
                }

                if (this.project.mountingSystem.terrainCategory && this.project.mountingSystem.terrainCategory.length < 5) {
                    // the terrain id is invalid
                    return;
                }
            }


            const modules = this.objects
                .filter(o => o.type == "module" && o.owner == this.activeSegment.id) as Solar.ObjectModule[];
            if (modules.length == 0) {
                return;
            }

            this.calcRes = await this._mountingService.baseCalc({
                project: this.project,
                mounting: this.project.mountingSystem,
                segment: this.activeSegment,
                modules: modules
            });

            if (this.calcRes.traceInfo) {
                // print the trace info
                console.log(`Mounting Calculation Trace information:`, this.calcRes.traceInfo)
            }

            // update wind and snow load 
            if (this.calcRes.windItems &&
                this.calcRes.windItems.length) {

                this.project.mountingSystem.windLoad = this.calcRes.windLoad;
                this.project.mountingSystem.snowLoad = this.calcRes.snowLoad;

                this.hasResults = true;
            }

            // send notification to canvas layout
            // this.events.next({ name: "segment_changed", params: null })
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isCaclulating = false;

            this._lock.release();
        }
    }


    /**
     * Show table with calculation results
     */
    public showCalcTable() {
        try {
            this._matDialog.open(LoadCalculationsTableComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    project: this.project,
                    calcRes: this.calcRes
                }
            });
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    /**
     * Handles the layout statistic for the mounting panel component.
     * @param statistic - The mounting drawing statistic.
     */
    public onLayoutStatistic(statistic: Solar.MountingDrawingStatistic) {
        if (!statistic ||
            !this.activeSegment) {
            return;
        }

        console.log("statistic:", statistic);

        let isChanged = false;
        if (Math.abs(this.activeSegment.mountingSystem.maxCantileverLength - statistic.maxCantiliver) > 0.001) {
            isChanged = true;
        }

        this.activeSegment.mountingSystem.maxCantileverLength = statistic.maxCantiliver;
        this.activeSegment.mountingSystem.beemSpanNumber = this._calcService.getBeemSpanNumber(statistic);
        this.activeSegment.mountingSystem.roofSlope = statistic.slope || 0;
        this.activeSegment.mountingSystem.railsInfo = statistic.rails;

        if (isChanged) {
            this.runCalcDebounce();
        }
    }


}
