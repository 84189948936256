import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Solar, NotifyService, WebSolarAuthService, WebSolarOptimizerService, WebSolarInverterService } from '@websolar/ng-websolar';
import { InvertersMultiselectComponent } from 'src/app/rooftop/inverters/inverters-multiselect/inverters-multiselect.component';

@Component({
    selector: 'app-optimizer-edit',
    templateUrl: './optimizer-edit.component.html',
    styleUrls: ['./optimizer-edit.component.scss']
})
export class OptimizerEditComponent {

    public optimizer: Solar.Optimizer;

    public isLoading = false;

    /**
     * The names of the inverters.
     */
    public invertersNames = "";    

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { optimizer: Solar.Optimizer },
        private _dialogRef: MatDialogRef<OptimizerEditComponent>,
        private _notify: NotifyService,
        private _optimizerService: WebSolarOptimizerService,
        private _matDialog: MatDialog,
        private _invService: WebSolarInverterService
    ) {
        this.optimizer = data.optimizer || this.getDefault();

        if (!this.optimizer.maxNumberOfModules) {
            this.optimizer.maxNumberOfModules = 1;
        }
        this.loadInvertersNames();
    }

    private getDefault(): Solar.Optimizer {
        return {
            orgId: "general",
            model: "",
            description: "",
            efficiency: 100,
            euroEfficiency: 0,
            isc: 0,
            manufacturer: "AIKO",
            optimalMatching: false,
            pmax: 0,
            price: 0,
            vmpMax: 0,
            vmpMin: 0,
            voc: 0,
            maxNumberOfModules: 1
        }
    }

    public async save() {
        try {
            this.isLoading = true;

            if (this.optimizer.orgId == "general") {
                this.optimizer.orgId = "";
            }

            await this._optimizerService.save(this.optimizer);

            this._notify.success(`Optimizer saved`);

            this._dialogRef.close(true);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isCanSave() {
        if (!this.optimizer.manufacturer ||
            !this.optimizer.model ||
            typeof this.optimizer.efficiency != "number") {
            return false;
        }

        return true;
    }

    public close() {
        this._dialogRef.close(null);
    }

    /**
 * Loads the names of the inverters.
 * If the optimizer has no inverters or the invertersIds array is empty, the method returns early.
 * Retrieves the inverters from the _invService and sets the invertersNames property to a string representation of the inverters' models.
 * @returns {Promise<void>} A promise that resolves when the inverters' names are loaded.
 */
    private async loadInvertersNames() {
        try {
            this.invertersNames = "";
            if (!this.optimizer.invertersIds || !this.optimizer.invertersIds.length) {
                return;
            }
            const inverters = await this._invService.find({ limit: 1000 });
            this.invertersNames = inverters
                .filter(i => this.optimizer.invertersIds?.includes(i._id as string))
                .map(i => `${i.model}`).join(";");
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Opens a dialog to select inverters and updates the `optimizer.invertersIds` property with the selected inverters.
     * 
     * @param evt - The mouse event that triggered the method.
     */
    public async pickInverters(evt: MouseEvent) {
        try {
            evt.stopPropagation();
            evt.preventDefault();

            const dialogRef = this._matDialog.open(InvertersMultiselectComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    preselectedInverters: this.optimizer.invertersIds
                }
            });
            const inverters = await dialogRef.afterClosed().toPromise() as Solar.Inverter[];
            if (!Array.isArray(inverters)) {
                return;
            }
            this.optimizer.invertersIds = inverters.map(i => i._id as string);
            // update the names as well
            this.invertersNames = inverters.map(i => `${i.model}`).join(";");
        }
        catch (err) {
            this._notify.error(err);
        }
    }
}
