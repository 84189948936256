import { Injectable } from "@angular/core";
import { Solar } from "@websolar/ng-websolar";
import { HttpService } from "./http.service";
import { AIKO } from "../types/aiko.types";

@Injectable()
export class MountingService {
    constructor(
        private _http: HttpService
    ) { }


    public async find(search: {
        id?: string,
        search?: string,
        skip?: number,
        limit?: number
    }): Promise<AIKO.MountingSystemEntity[]> {
        return await this._http.post(`/mounting/find`, search);
    }

    public async count(search: {
        search?: string
    }): Promise<number> {
        const res = await this._http.post(`/mounting/count`, search) as { count: number };
        return res.count;
    }

    /**
     * run base calculations
     * @returns 
     */
    public async baseCalc(options: {
        project: Solar.Project,
        mounting: Solar.MountingSystem,
        segment: Solar.ObjectRooftopSegment,
        modules: Solar.ObjectModule[]
    }): Promise<Solar.BaseMountingCalculation> {
        return await this._http.post(`/mounting/calc/base`, options);
    }
}