import * as THREE from "three";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Solar, SolarInstance, NotifyService, WebSolarEventsService, WebSolarGeometryService, WebSolarRoofService, WebSolarTransactionService } from '@websolar/ng-websolar';
import { Observable, Subscription } from 'rxjs';
import { Geometry } from 'src/app/core/geometry';
import { DialogService } from 'src/app/services/dialog.service';
import { ModuleConfigService } from 'src/app/services/module.config.service';
import { AIKO } from 'src/app/types/aiko.types';
import { ArrayService } from "src/app/services/array.service";

@Component({
    selector: 'app-segments-list',
    templateUrl: './segments-list.component.html',
    styleUrls: ['./segments-list.component.scss']
})
export class SegmentsListComponent implements OnChanges, OnDestroy {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Output() deleteSegment = new EventEmitter<Solar.ObjectRooftopSegment>();

    @Output() segmentChange = new EventEmitter<Solar.ObjectRooftopSegment>();

    @Output() navigationRequest = new EventEmitter<AIKO.MenuMode>();

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    @Input() events!: Observable<{ name: string, params: unknown }>;

    public segmentState: { [key: string]: { expanded: boolean } } = {};

    public segments: Solar.ObjectRooftopSegment[] = [];

    public selectedRoof: Solar.ObjectRoof | null = null;

    /**
     * is auto placement available
     */
    public isAutoPlacementAvailable = false;

    public total?: {
        count: number,
        power: number,
        area: number
    };

    private _subs: Subscription[] = [];

    constructor(
        private _dialogService: DialogService,
        private _notify: NotifyService,
        private _translate: TranslateService,
        private _eventService: WebSolarEventsService,
        private _moduleConfigService: ModuleConfigService,
        private _geomService: WebSolarGeometryService,
        private _transactionService: WebSolarTransactionService,
        private _arrayService: ArrayService
    ) {
        this.attachToEvents();
    }


    private attachToEvents() {
        const sub = this._eventService.events.subscribe((opt) => {
            try {
                if (opt.name == "tool_completed" ||
                    opt.name == "project_initalized" ||
                    opt.name == "refresh" ||
                    opt.name == "undo" ||
                    opt.name == "redo") {
                    this.refresh();
                }
                else if (opt.name == "object_picked") {
                    this.onObjectPicked(opt.params as Solar.Object);
                }
                else if (opt.name == "shape_editor_object_changed") {
                    this.onSegmentChange(opt.params as Solar.ObjectRooftopSegment);
                }
                else if (opt.name == "object_deleted") {
                    this.onObjectDeleted(opt.params as Solar.Object);
                }

                this.updateTotal();
            }
            catch (err) {
                console.error(err);
            }
        });
        this._subs.push(sub);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["instance"] && this.instance) {

            this.refresh();
            this.updateTotal();
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subs) {
            sub.unsubscribe();
        }
    }

    private refresh() {
        if (!this.instance || !this.project) {
            return;
        }

        this.updateHint();

        if (!this.selectedRoof) {
            return;
        }

        const segments = this.instance.getObjects({ types: ["segment"], ownerId: this.selectedRoof.id }) as Solar.ObjectRooftopSegment[];
        this.segments = [];
        for (const segment of segments) {
            this.segments.push(segment);
            this.initSegment(segment);
        }

        if (this.segments.length == 1) {
            // expand it
            this.segmentState[this.segments[0].id].expanded = true;
        }

        // auto placement is not available when manual array are found
        this.isAutoPlacementAvailable = !this.segments.find(s => s.segmentType == "array");

        this.updateTotal();
    }

    private updateHint() {
        if (!this.instance) {
            return;
        }
        if (!this.instance.getActivedToolName()) {
            if (!this.selectedRoof) {
                this.instance.getHint().setMessage("Click the roof you want to layout the modules");
            }
            else {
                this.instance.getHint().setMessage("");
            }
        }
    }

    public onNewSegment() {
        this.toolActivated.emit({ type: "segment", params: null });
    }

    public toggle(segment: Solar.ObjectRooftopSegment) {
        // collapse other segments
        for (const key of Object.keys(this.segmentState)) {
            if (key != segment.id) {
                this.segmentState[key].expanded = false;
            }
        }

        this.segmentState[segment.id].expanded = !this.segmentState[segment.id].expanded;
    }

    /**
     * Highlights the selected roof in the 3D scene.
     * If no roof is selected or the 3D scene instance is not available, no action is taken.
     */
    private highlightRoof() {
        if (!this.instance) {
            return;
        }
        this.instance.highlight.clear();

        if (!this.selectedRoof) {
            return;
        }

        const obj3d = this.instance.get3dObjects({ id: this.selectedRoof.id })[0];
        if (obj3d) {
            this.instance.highlight.add(obj3d);
        }
    }

    /**
     * Handles the event when an object is picked.
     * If the picked object is not of type "segment", it returns early.
     * Collapses other segments and expands the picked segment.
     * Finally, it highlights the segment.
     * 
     * @param object - The picked object.
     */
    private onObjectPicked(object: Solar.Object) {
        if (object.type == "roof") {
            this.selectedRoof = object as Solar.ObjectRoof;

            this.highlightRoof();

            this.refresh();
            return;
        }
        else if (object.type == "module") {
            // activate editing for the array
            const array = this.instance.getObjects({ id: object.owner, types: ["segment"] })[0] as Solar.ObjectRooftopSegment;
            if (!array) {
                console.error(`array not found`);
                return;
            }
            this.instance.shapeEditor.attach({
                object: array,
                units: this.project.measurement,
                mode: "constant_angle"
            });

            // adjust to the top view
            this.instance.lockTopView(true);
            this.instance.lockTopView(false);

            // highlight the roof
            this.selectedRoof = this.instance.getObjects({ id: array.owner, types: ["roof"] })[0] as Solar.ObjectRoof;
            this.highlightRoof();
            this.refresh();

            // expand the picked array
            if (this.segmentState[array.id]) {
                this.segmentState[array.id].expanded = true;
            }
        }
    }

    /**
     * Initializes a rooftop segment.
     * 
     * @param segment - The rooftop segment to initialize.
     */
    private initSegment(segment: Solar.ObjectRooftopSegment) {
        this.segmentState[segment.id] = {
            expanded: false
        };

        if (!segment.align) {
            segment.align = "grid";
        }

        if (!segment.output) {
            // set default
            segment.output = {
                power: 0,
                count: 0,
                area: 0
            };
        }

        if (segment.module) {
            segment.isAutoPlacement = true;
        }

        if (this.instance) {
            const modules = this.instance.getObjects({ types: ["module"], ownerId: segment.id });
            const power = segment.module ? Math.round((modules.length * segment.module.power) / 100) / 10 : 0;
            segment.output.count = modules.length;
            segment.output.power = power;
            segment.output.area = Math.round(Geometry.getArea(segment.points));
        }
    }

    /**
     * Handles the change event of a rooftop segment.
     * @param segment - The selected rooftop segment.
     */
    public onSegmentChange(segment: Solar.ObjectRooftopSegment) {

        if (!this._arrayService.verifyOverlapingAndPlacment(this.instance, segment)) {
            // undo the transaction
            this._transactionService.undo(this.instance);
            // disable editing
            this.instance.shapeEditor.clear();
            return;
        }

        const nrModules = this.instance.getObjects({ types: ["module"] }).length;

        // rebuild the segments
        this.instance.rebuild(this.project, [segment.id], true);

        // mark the project simulation as not done
        this.project.simulationStatus = "";

        const modulesNew = this.instance.getObjects({ types: ["module"] }) as Solar.ObjectModule[];
        if (nrModules != modulesNew.length) {
            // clear the electrical configuration
            this.project.electrical.stringsConfig = {
                items: [],
                isAuto: false
            };
            // remove the inverters
            this.instance.removeObjects({ types: ["inverter"] });
        }

        // remove optimizers and strings
        this.instance.removeObjects({ types: ["optimizer", "string"] });
        for (const m of modulesNew) {
            m.optimizerId = "";
        }

        this.segmentChange.emit(segment)
    }

    public onToolActivated(opt: Solar.ToolOptions) {
        this.toolActivated.emit(opt)
    }

    /**
     * Deletes a segment from the rooftop.
     * @param segment - The segment to be deleted.
     */
    public async onDeleteSegment(segment: Solar.ObjectRooftopSegment) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `${this._translate.instant("Delete segment")} "${segment.name}"`,
                text: `Are you sure you want to delete this segment ?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }

            // remove the segment and dependenices from the scene
            this.instance.removeObjects({ id: segment.id });
            this.instance.removeObjects({ ownerId: segment.id });

            // mark the project simulation as not done
            this.project.simulationStatus = "";

            // send notification
            this._eventService.send({ name: "object_deleted", params: segment })

            // clear editor
            this.instance.shapeEditor.clear();

            this.refresh();
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    /**
     * Updates the total area, count, and power based on the segments data.
     */
    private updateTotal() {
        if (!this.segments) {
            return;
        }
        this.total = {
            area: 0,
            count: 0,
            power: 0
        }

        for (const segment of this.segments) {
            if (!segment.output) {
                continue;
            }
            this.total.area += segment.output.area;
            this.total.power += segment.output.power;
            this.total.count += segment.output.count;
        }

        this.total.area = Math.round(this.total.area * 10) / 10;
        this.total.power = Math.round(this.total.power * 10) / 10;
        this.total.count = Math.round(this.total.count);
    }

    /**
     * Handles the module change event.
     * Sets the new module to all segments and rebuilds the instance.
     */
    public onModuleChange() {
        // set the new module to all segments
        const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        for (const segment of segments) {
            if (segment.isAutoPlacement) {
                segment.module = this.project.baseConfiguration.module;
                this._moduleConfigService.setDefaultSpacing(segment);
            }
        }


        // rebuild all
        this.instance.rebuild(this.project);
    }


    /**
     * Performs manual placement of rooftop segments.
     * 
     * This method allows the user to manually place rooftop segments by picking two points on the screen.
     * The first point is picked by the user, and then the second point is picked to define the segment.
     * The segment is then transformed and added to the scene.
     * 
     * @returns {Promise<void>} A promise that resolves when the manual placement is complete.
     * @throws {Error} If an error occurs during the manual placement process.
     */
    public async manualPlacement() {
        if (!this.instance || !this.selectedRoof) {
            return;
        }
        try {
            // cancel previous
            await this.instance.sendEscape();

            this.instance.lockTopView(true);
            this.instance.layers.irradiance = false;
            this.instance.layers.modules = false;
            this.instance.layers.update();
            this.instance.highlight.clear();

            const existSegments = this.instance.getObjects({ ownerId: this.selectedRoof.id }) as Solar.ObjectRooftopSegment[];
            if (existSegments.find(s => s.segmentType == "auto_array")) {
                // remove all arrays when mode changed from auto to manual
                this.removeAllArrays(this.selectedRoof.id);
            }

            this.instance.getHint().setMessage("Pick the first point");
            const firstPnt = await this.instance.pointPicker.queryPoint({
                snapToSegments: false,
                snapToVertex: false,
                disableSnaping: true
            });
            if (!firstPnt) {
                return;
            }

            this.instance.getHint().setMessage("Pick the second point");

            const newArray = this._arrayService.getNewArray(this.instance, this.project, this.selectedRoof.id, "array");

            const secondPnt = await this.instance.pointPicker.queryPoint({
                snapToSegments: false,
                snapToVertex: false,
                disableSnaping: true,
                callback: (newPoint) => {

                    if (!this.selectedRoof) {
                        return;
                    }

                    newArray.points = this._arrayService.getArrayPoints(this.instance, firstPnt, newPoint, this.selectedRoof);

                    // remove previous entity
                    this.instance.removeObjects({ id: newArray.id })

                    // create new
                    const obj3d = this.instance.createObject(newArray, this.project.measurement);
                    if (obj3d) {
                        this.instance.scene.add(obj3d);
                    }
                }
            });

            if (!secondPnt) {
                // remove entity
                this.instance.removeObjects({ id: newArray.id })
                return;
            }

            // check overlapping
            if (!this._arrayService.verifyOverlapingAndPlacment(this.instance, newArray)) {
                // remove entity
                this.instance.removeObjects({ id: newArray.id })
                return;
            }

            // set azimuth
            this._geomService.setAutoAzimuth(newArray);

            // rebuild
            this.instance.rebuild(this.project);

            this.refresh();
        }
        catch (err) {
            console.error(err);
        }
        finally {
            this.instance.getHint().setMessage("");
            this.instance.lockTopView(false);
            this.instance.layers.irradiance = true;
            this.instance.layers.modules = true;
            this.instance.layers.update();
        }
    }


    public async autoPlacement() {
        try {
            if (!this.selectedRoof) {
                return;
            }
            // remove all arrays
            this.removeAllArrays(this.selectedRoof.id);

            // run autoplacement
            this._arrayService.runAutoplacement(this.selectedRoof, this.project, this.instance);

            this.refresh();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Removes all arrays associated with a specific roof ID.
     * @param roofId - The ID of the roof.
     */
    private removeAllArrays(roofId: string) {
        const arrs = this.instance.getObjects({ ownerId: roofId });
        for (const arr of arrs) {
            this.instance.removeObjects({ ownerId: arr.id });
            this.instance.removeObjects({ id: arr.id });
        }
    }

    public onObjectDeleted(obj: Solar.Object) {
        if (obj.type == "module") {
            const segment = this.instance.getObjects({ types: ["segment"], id: obj.owner })[0] as Solar.ObjectRooftopSegment;
            if (segment) {
                // check if segment still have any modules
                const hasModules = this.instance.getObjects({ types: ["module"], ownerId: obj.owner }).length > 0;
                if (!hasModules) {
                    // remove the segment
                    this.instance.removeObjects({ id: segment.id });
                    // cancel the shape editing
                    this.instance.shapeEditor.clear();
                    this.refresh();
                }
            }
        }

        this.updateTotal();
    }

}
