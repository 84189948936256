import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Color } from '@swimlane/ngx-charts';
import { Solar } from '@websolar/ng-websolar';

@Component({
    selector: 'app-report-bill-pipe',
    templateUrl: './report-bill-pipe.component.html',
    styleUrls: ['./report-bill-pipe.component.scss']
})
export class ReportBillPipeComponent implements OnChanges {

    @Input() consumption!: Solar.ConsumptionOutput;

    @Input() project!: Solar.Project;

    public scheme = {
        domain: ["#fa5002", "#fb8801"]
    } as Color;

    public results: { name: string, value: number }[] = [];

    /**
     * self consume in MWh
     */
    public saving = 0;

    public savingPercent = 0;

    /**
     * import in MWh
     */
    public import = 0;

    public importPercent = 0;

    /**
     * total in MWh
     */
    public total = 0;

    constructor(
        private _translate: TranslateService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.consumption) {
            this.rebuild();
        }
    }

    private rebuild() {
        try {
            this.total = this.consumption.months
                .reduce((prev, cur) => prev + cur.nonOptimizedBilling, 0)
            // convert to month
            this.total = Math.round(this.total / 12);

            this.saving = this.consumption.months
                .reduce((prev, cur) => prev + cur.saving, 0)
            // convert to month
            this.saving = Math.min(Math.round(this.saving / 12), this.total);
            this.savingPercent = Math.round(this.saving / this.total * 100);

            this.import = Math.round((this.total - this.saving) * 100) / 100;
            this.importPercent = Math.round(this.import / this.total * 100);

            this.results = [
                { name: this._translate.instant("Saving"), value: this.saving },
                { name: this._translate.instant("Payment"), value: this.import }
            ]
        }
        catch (err) {
            console.error(err);
        }
    }

}
