<div class="component">
    <div class="main-content">
        <div class="stat-info">
            <div class="item-wrapper self-consumption">
                <div class="lbl">{{"Solar Consumption" | translate}}</div>
                <div class="val">
                    {{selfConsume}} {{"MWh" | translate}} ({{selfConsumePercent}}%)
                </div>
                <div class="consumption-line"
                     [ngStyle]="{ 'width': selfConsumePercent + '%' }">
                </div>
            </div>
            <div class="item-wrapper import">
                <div class="lbl">{{"Grid Export" | translate}}</div>
                <div class="val">
                    {{export}} {{"MWh" | translate}} ({{exportPercent}}%)
                </div>
                <div class="import-line"
                     [ngStyle]="{ 'width': exportPercent + '%' }">
                </div>
            </div>
        </div>

        <div class="chart-wrapper">
            <ngx-charts-pie-chart [results]="results"
                                  [scheme]="scheme"
                                  [doughnut]="true">
            </ngx-charts-pie-chart>

            <div class="total">
                {{total}} {{"MWh" | translate}}
            </div>
        </div>
    </div>

</div>
