import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Color } from '@swimlane/ngx-charts';
import { Solar, SolarInstance, WebSolarReportService, NotifyService, WebSolarCurrencyService } from '@websolar/ng-websolar';
import { FinanceService } from 'src/app/services/finance.service';
import { BOMRecordEditComponent } from '../bom-record-edit/bom-record-edit.component';
import { BomService } from 'src/app/services/bom.service';
import { AIKO } from 'src/app/types/aiko.types';
import { NumberTool } from 'src/app/core/number.tool';


@Component({
    selector: 'app-report-finance',
    templateUrl: './report-finance.component.html',
    styleUrls: ['./report-finance.component.scss']
})
export class ReportFinanceComponent implements OnChanges {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Input() consumption!: Solar.ConsumptionOutput;

    @Input() showCost = true;

    public currency: Solar.Currency;

    public schemeBillSaving = {
        domain: ['#fa5002']
    } as Color;


    public bomSource: AIKO.BOMRecord[] = [];

    public displayedColumns = ["name", "quantity"];

    public netCost = 0;

    /**
     * Payback period in years
     */
    public payback = 0;

    /**
     * Return on Investment (ROI) represents the profitability of an investment.
     */
    public ROI = 0;

    public oldBill = 0;

    public newBill = 0;

    /**
     * Price per Watt. The default is 1.5
     */
    public pricePerWatt = 1.5;

    public modes = [
        { id: "total", name: "Total price" },
        { id: "pricePerWatt", name: "Price per watt" },
        { id: "pricePerDevice", name: "Price Per Device" }
    ]

    constructor(
        private _reportService: WebSolarReportService,
        private _notify: NotifyService,
        private _currencyService: WebSolarCurrencyService,
        private _financeService: FinanceService,
        private _bomService: BomService,
        private _matDialog: MatDialog
    ) {
        this.currency = this._currencyService.getDefault();
    }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     * @param changes - An object containing the changed properties and their current and previous values.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (this.project && this.instance) {
            if (!this.project.finance) {
                this.project.finance = {
                    customNetCost: 0,
                    taxRebate: 0,
                    useCustomNetCost: false
                }
            }
            if (!this.project.finance.mode) {
                this.project.finance.mode = "total";
            }

            if (this.project.finance.mode == "pricePerWatt" &&
                this.project.finance.customNetCost) {
                const segmentInfo = this._reportService.getSegmentsInfo(this.instance);
                if (segmentInfo.power) {
                    this.pricePerWatt = NumberTool.round(this.project.finance.customNetCost / (segmentInfo.power * 1000), 2);
                }
            }

            this.currency = this.project.currency;

            this.update();
        }
    }

    /**
     * Updates the custom net cost based on the price per watt and the power of the segment.
     * If the finance object is not available in the project, the function returns early.
     */
    public async onPerWattUpdate() {
        if (!this.project.finance) {
            return;
        }

        const segmentInfo = this._reportService.getSegmentsInfo(this.instance);
        this.project.finance.customNetCost = segmentInfo.power * 1000 * this.pricePerWatt;

        await this.update();
    }

    /**
     * Updates the finance report based on the project and instance data.
     */
    public async update() {
        try {
            this.bomSource = [];

            if (this.project.finance?.mode == "pricePerDevice") {
                this.bomSource = await this._bomService.getBOM(this.project, this.instance.getObjects({}));
            }
            else {
                this.bomSource = [];
            }

            const info = this._financeService.getSystemCost(this.project, this.instance.getObjects({}));
            this.netCost = info.netCost;
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Handles the change event of the finance mode.
     * If the project has finance data, it resets the system cost to zero and updates the project.
     */
    public onFinanceModeChange() {
        if (!this.project.finance) {
            return;
        }
        // reset the system cost to zero
        this.project.finance.customNetCost = 0;
        this.pricePerWatt = 0;
        this.update();
    }


    /**
     * Edits a record in the finance report.
     */
    public async editRecord(record?: Solar.BOMRecord) {
        try {
            const dialogRef = this._matDialog.open(BOMRecordEditComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "70vw",
                data: {
                    record: record,
                    project: this.project
                }
            });
            const res = await dialogRef.afterClosed().toPromise() as Solar.BOMRecord;
            if (!res) {
                return;
            }
            this.update();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public deleteDevice(record: AIKO.BOMRecord) {
        if (!this.project ||
            !this.project.finance ||
            !this.project.finance.userDefinedRecords) {
            return;
        }
        const idx = this.project.finance.userDefinedRecords.indexOf(record);
        this.project.finance.userDefinedRecords.splice(idx, 1);

        this.update();
    }
}
