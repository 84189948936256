import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Solar, SolarInstance, NotifyService, WebSolarShadingService, WebSolarProjectService, WebSolarLossesService } from '@websolar/ng-websolar';
import { ThreadTool } from 'src/app/core/thread.tool';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-reports-panel',
    templateUrl: './reports-panel.component.html',
    styleUrls: ['./reports-panel.component.scss']
})
export class ReportsPanelComponent implements OnChanges, OnDestroy {

    /**
     * The project input 
     */
    @Input() project!: Solar.Project;

    /**
     * Represents an array of Solar objects.
     */
    @Input() objects!: Solar.Object[];

    @Input() instance?: SolarInstance;

    /**
     * Is this shared report
     */
    @Input() shared = false;

    /**
     * show consumption
     */
    @Input() showConsumption = false;

    /**
     * Emits a navigation request event with the specified menu mode.
     *
     * @event navigationRequest
     * @type {EventEmitter<AIKO.MenuMode>}
     */
    @Output() navigationRequest = new EventEmitter<AIKO.MenuMode>();

    /**
     * Indicates whether the component is ready or not.
     */
    public isReady = false;

    /**
     * Indicates whether the component has modules.
     */
    public isHasModules = true;

    /**
     * The share ID for the reports panel.
     */
    public shareId = "";

    /**
     * The error message displayed in the reports panel.
     */
    public errorMessage = "";

    private _isInit = false;

    private _cancellation: Solar.Cancellation = { cancelled: false }

    constructor(
        private _lossesService: WebSolarLossesService,
        private _notify: NotifyService,
        private _shadingService: WebSolarShadingService,
        private _projectService: WebSolarProjectService
    ) { }

    /**
     * Lifecycle hook that is called when the component is about to be destroyed.
     * It cancels the simulation process by setting the `_cancellation.cancelled` property to `true`.
     */
    public ngOnDestroy(): void {
        // cancel the simulation process
        this._cancellation.cancelled = true;
    }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     * It is called before ngOnInit() and whenever one or more data-bound input properties change.
     */
    public ngOnChanges(): void {

        if (this.project) {
            this.init();
        }
    }

    /**
     * Initializes the reports panel component.
     * 
     * @returns {Promise<void>} A promise that resolves when the initialization is complete.
     */
    private async init() {
        try {
            if (this.instance) {
                // get objects directly from instance
                this.objects = this.instance.getObjects({});
            }
            if (!this.objects) {
                return;
            }

            if (this._isInit) {
                // already initalized
                return;
            }
            this._isInit = true;


            this.shareId = this.project.shareId;

            const modules = this.objects.filter(o => o.type == "module");
            this.isHasModules = modules.length > 0;

            if (!this.isHasModules) {
                return;
            }

            console.log("sim status:", this.project.simulationStatus)

            this.isReady = this.project.simulationStatus == "done";

            if (!this.isReady) {
                if (this.shared) {
                    // we should show the shared page without simulation
                    this.isReady = true;
                }
                else {
                    await this.run();
                }
            }

            // load DC losses
            try {
                this.project.dcLosses = await this._lossesService.getDesignLosses(this.objects, this.project);
            }
            catch (err) {
                console.error(`failed get DC losses`, err)
            }

            this.createIrradiance();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Runs the shading service for the current project and instance.
     * Updates the simulation status and saves the project if it has an ID.
     * Handles errors and cancels the simulation if necessary.
     */
    public async run() {
        try {
            if (!this.instance) {
                return;
            }
            this.isReady = false;

            this.project.simulationStatus = "in_progress";

            this._cancellation = { cancelled: false }

            // don't show irradiance during simulation
            this.instance.layers.irradiance = false;
            this.instance.layers.update();

            const segments = this.instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
            const hasTiltSegments = segments.find(s => s.racking == "fixed_tilt" && s.module && s.layoutGrouping?.enabled);

            await this._shadingService.run({
                project: this.project,
                instance: this.instance,
                cancellation: this._cancellation,
                legendMin: 0,
                createIrradiance: false,
                saveResults: true,
                useModules: Boolean(hasTiltSegments)
            });


            // mark the simulation as done
            this.project.simulationStatus = "done";


            if (this.project._id) {
                // save the project
                const objects = this.instance.getObjects({});
                this._projectService.save({
                    project: this.project,
                    objects: objects
                })
            }

            this.isReady = true;
        }
        catch (err) {
            // don't show error when simulation cancelled
            if (err != "operation cancelled") {
                this._notify.error(err);
                this.errorMessage = "Simulation failed"
            }
            this.project.simulationStatus = "";
            console.error(err);
        }
    }

    /**
     * Creates the irradiance for the current instance.
     * If the instance is not available, the function returns early.
     * The irradiance is restored from the modules and assigned to the legend variable.
     * If the legend is not available, the function returns early.
     * The irradiance layer is then shown on the instance.
     */
    private createIrradiance() {
        if (!this.instance) {
            return;
        }

        // restore the irradiance from modules
        this._shadingService.createIrradianceFromDesign({
            project: this.project,
            instance: this.instance,
            legendMin: 0,
            legendSegmentation: true,
            showPercentage: true,
            cancellation: { cancelled: false }
        })

        // show irradiance layer
        //
        this.instance.layers.irradiance = true;
        this.instance.layers.update();
    }


    /**
     * Navigates to the Design page.
     */
    public goToDesign() {
        this.navigationRequest.emit("Design");
    }

    /**
     * Event handler for when a tab is changed.
     * 
     * @param evt - The MatTabChangeEvent object containing information about the tab change event.
     */
    public onTabChanged(evt: MatTabChangeEvent) {
        try {
            // none
        }
        catch (err) {
            console.error(err);
        }
    }
}
