import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyService, Solar, WebSolarBatteryService } from '@websolar/ng-websolar';


@Component({
    selector: 'app-battery-edit',
    templateUrl: './battery-edit.component.html',
    styleUrls: ['./battery-edit.component.scss']
})
export class BatteryEditComponent {

    public battery: Solar.Battery;

    public isLoading = false;

    public isGeneral = false;

    public SOCmin = 0;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { battery: Solar.Battery },
        private _dialogRef: MatDialogRef<BatteryEditComponent>,
        private _notify: NotifyService,
        private _batteryService: WebSolarBatteryService
    ) {
        this.battery = data.battery || this.getDefault();
        this.isGeneral = this.battery.orgId == "general";
        this.SOCmin = 100 - this.battery.dod;
    }

    private getDefault(): Solar.Battery {
        return {
            orgId: "general",
            model: "",
            manufacturer: "",
            capacity: 2.76,
            voltage: 12,
            efficiency: 96,
            powerMaxContinuous: 1.2,
            dod: 50
        }
    }

    public onChangeGeneral() {
        if (this.isGeneral) {
            this.battery.orgId = "general";
        }
        else {
            // TODO: unsupported
            this.battery.orgId = "";
        }
    }

    public async save() {
        try {
            this.isLoading = true;


            await this._batteryService.save(this.battery);

            this._notify.success(`Battery created`);

            this._dialogRef.close(true);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isCanSave() {
        if (this.isGeneral) {
            return false;
        }
        if (!this.battery.manufacturer ||
            !this.battery.model ||
            typeof this.battery.capacity != "number" ||
            typeof this.battery.voltage != "number" ||
            typeof this.battery.dod != "number" ||
            typeof this.battery.powerMaxContinuous != "number" ||
            typeof this.battery.efficiency != "number") {
            return false;
        }

        return true;
    }

    public close() {
        this._dialogRef.close(null);
    }

    public onSOCChanged() {
        this.battery.dod = 100 - this.SOCmin;
    }
}
