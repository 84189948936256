<div *ngIf="permitScope"
     class="component">

    <div class="package-card">
        <div class="title">
            {{"Safety Signs" | translate}}
        </div>

        <div class="row">
            <!-- Sign #1 -->
            <div class="item">
                <div class="sign-img">
                    <img [src]="'signs/sign-1.png' | assetUrl">
                </div>
                <div class="sign-alert">
                    {{"WARNING:PHOTOVOLTIC POWER SOURCE" | translate}}
                </div>
                <div class="sign-warn">
                    <div class="header">
                        <img [src]="'icons/warn-icon.svg' | assetUrl"/>
                        <span>{{"WARNING" | translate}}</span>
                    </div>
                    <div class="text">
                        <div><b>{{"ELECTRIC SHOCK HAZARD" | translate}}</b></div>
                        <p>
                            {{"THE DC CONDUCTORS OF THIS PHOTOVOLTIC SYSTEM ARE UNGROUNDED AND MAY BE ENERGIZED" | translate}}
                        </p>
                    </div>
                </div>
            </div>

            <!-- Sign #2 -->
            <div class="item">
                <div class="sign-img">
                    <img [src]="'signs/sign-2.png' | assetUrl">
                </div>
                <div class="sign-alert">
                    {{"WARNING:PHOTOVOLTIC POWER SOURCE" | translate}}
                </div>
            </div>

            <!-- Sign #3 -->
            <div class="item">
                <div class="sign-img">
                    <img [src]="'signs/sign-3.png' | assetUrl">
                </div>
                <div class="sign-warn">
                    <div class="header">
                        <img [src]="'icons/warn-icon.svg' | assetUrl" />
                        <span>{{"WARNING" | translate}}</span>
                    </div>
                    <div class="text">
                        <div><b>{{"ELECTRIC SHOCK HAZARD" | translate}}</b></div>
                        <p>
                            {{"THE DC CONDUCTORS OF THIS PHOTOVOLTIC SYSTEM ARE UNGROUNDED AND MAY BE ENERGIZED" | translate}}
                        </p>
                    </div>
                </div>
            </div>


            <!-- Sign #4 -->
            <div class="item">
                <div class="sign-img">
                    <img [src]="'signs/sign-4.png' | assetUrl">
                </div>
                <div class="sign-warn">
                    <div class="header header-warn-alternative">
                        <div>
                            <img [src]="'icons/warn-icon.svg' | assetUrl"/>
                        </div>
                        <span>{{"CAUTION MULTIPLY SOURCES OF POWER WITH DISCONNECTS LOCATED AS SHOWN" | translate}}</span>
                    </div>
                    <div class="site-plan-img-wrapper">
                        <img class="site-plan-img"
                             [src]="permitScope.content.sitePlan">
                        <img class="north"
                             src="{{cloudStorageUrl}}/images/north-v.png">
                    </div>
                </div>

                <div class="sign-warn">
                    <div class="header">
                        <img [src]="'icons/warn-icon.svg' | assetUrl"/>
                        <span>{{"WARNING" | translate}}</span>
                    </div>
                    <div class="text">
                        <div><b>{{"DUAL POWER SOURCES" | translate}}</b></div>
                        <p>
                            {{"SECOND SOURCE IS PHOTOVOLTIC SYSTEM PHOTOVOLTIC SYSTEM ARE UNGROUNDED AND MAY BE ENERGIZED" | translate}}
                        </p>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
