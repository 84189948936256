<div *ngIf="permitScope"
     class="component">

    <div class="package-card summary-card">
        <div class="summary-view">
            <div class="sub-title">
                {{"PROJECT SUMMARY" | translate}}
            </div>
            <div class="map-view">
                <img *ngIf="mapUrlLocale"
                     [src]="mapUrlLocale">
            </div>
            <div class="note-title">
                {{"Notes" | translate}}
            </div>
            <div class="notes">
                {{"THE DESIGN RULES ALIGN WITH THE EURO CODE EN 1990" | translate}}<br>
                {{"ENSURE THAT RESULTS AND DATA ARE VALIDATED AND REVIEWED BY AN APPROPRIATELY QUALIFIED INDIVDUAL" | translate}}<br>
                {{"THE CALCULATION OF WIND LOADS RELIES ON EUROCODE EN 1991-1-3" | translate}}<br>
                {{"THE CALCULATION OF SNOW LOADS RELIES ON EUROCODE EN 1991-1-4" | translate}}<br>
            </div>
        </div>
        <div class="summary-fields">
            <div class="field">
                <div class="lbl">{{"Owner" | translate}}</div>
                <div class="val">
                    {{permitScope.project.permit?.owner}}
                </div>
            </div>
            <div class="field">
                <div class="lbl">{{"Project Address" | translate}}</div>
                <div class="val">
                    {{permitScope.project.permit?.address}}
                </div>
            </div>
            <div class="field">
                <div class="lbl">{{"Utility" | translate}}</div>
                <div class="val">
                    {{permitScope.project.permit?.utilityCompany}}
                </div>
            </div>
            <div class="field">
                <div class="lbl">{{"Contractor Name" | translate}}</div>
                <div class="val">
                    {{permitScope.project.permit?.contractorName}}
                </div>
            </div>
            <div class="field">
                <div class="lbl">{{"Contractor Phone" | translate}}</div>
                <div class="val">
                    {{permitScope.project.permit?.contractorPhone}}
                </div>
            </div>
            <div class="field">
                <div class="lbl">{{"System DC Power" | translate}}</div>
                <div class="val">
                    {{dcPower}}
                </div>
            </div>
            <div class="field">
                <div class="lbl">{{"AC Output" | translate}}</div>
                <div class="val">
                    {{acOutput}} {{"kW" | translate}}
                </div>
            </div>
        </div>
    </div>
</div>
