import { Injectable } from "@angular/core";
import { Solar } from "@websolar/ng-websolar";

@Injectable()
export class ModuleConfigService {

    public setDefaultSpacing(segment: Solar.ObjectRooftopSegment) {
        if (segment.orientation == "horz") {
            segment.moduleSpacing = 0.02;
            segment.rowSpacing = 0.02;
        }
        else {
            segment.moduleSpacing = 0.02;
            segment.rowSpacing = 0.02;
        }

        let verticalSpan = 0.8;

        if (segment.mountingSystem.orientation == "horz") {
            if (segment.module?.model.includes("MAH72")) {
                verticalSpan = 1.3;
            }
            else if (segment.module?.model.includes("MAH54")) {
                verticalSpan = 1.1;
            }
        }

        // set default
        segment.mountingSystem.railSpan = verticalSpan;
        segment.mountingSystem.hookSpan = verticalSpan;
    }
}