export class CloneTool {
    public static clone<T>(source: T): T {
        let output = JSON.parse(JSON.stringify(source));

        const sourceAny = (source as unknown) as { [key: string]: Date };

        for (let key of Object.keys(sourceAny)) {
            if (sourceAny[key] &&
                typeof sourceAny[key] == "object" &&
                typeof sourceAny[key].getTime == "function" &&
                typeof sourceAny[key].getDate == "function") {
                output[key] = new Date(sourceAny[key]);
            }
        }

        return output;
    }

    public static copy(source: unknown, destination: unknown) {
        const src = source as Record<string, unknown>;
        const dest = destination as Record<string, unknown>;
        for (let key of Object.keys(src)) {
            dest[key] = src[key];
        }
    }
}
