import { Injectable } from "@angular/core";
import { Solar } from "@websolar/ng-websolar";

@Injectable()
export class MountingCalcService {

    /**
     * Calculates the beam span number based on the given mounting drawing statistic.
     * The beam span number is determined as follows:
     * - If all rails have only 1 beam span, the beam span number is 1.
     * - If at least one rail has 2 beam spans, the beam span number is 2.
     * - If the rails' biggest beam span is 3 and there are no rails with 2 beam spans, the beam span number is 3.
     * - If the rails' biggest beam span is 4 and there are no rails with 2 beam spans, the beam span number is 4.
     * - If the rails' biggest beam span is 5 or more and there are no rails with 2 beam spans, the beam span number is 5.
     * - If there are no rails, the default beam span number is 1.
     * 
     * @param statistic The mounting drawing statistic.
     * @returns The calculated beam span number.
     */
    public getBeemSpanNumber(statistic: Solar.MountingDrawingStatistic): number {

        if (statistic.rails.length == 0) {
            // default for no data
            return 1;
        }

        // check if all rails has 1 beam
        let has1SpanOnly = true;
        for (let rail of statistic.rails) {
            if (rail.beemSpanNumbers != 1) {
                has1SpanOnly = false;
                break;
            }
        }
        if (has1SpanOnly) {
            return 1;
        }

        let has2Spans = statistic.rails.find(r => r.beemSpanNumbers == 2);
        if (has2Spans) {
            return 2;
        }

        let maxCount = statistic.rails.reduce((prev, cur) => Math.max(prev, cur.beemSpanNumbers), 0);
        if (maxCount >= 5) {
            return 5;
        }
        if (maxCount == 0) {
            return 1;
        }

        return maxCount;
    }
}