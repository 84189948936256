<div class="component">

    <div class="buttons">

        <div class="btn"
             (click)="createNew('cone')"
             [matTooltip]="'pick the tree/electric pole add to the map' | translate">
            <app-icon [src]="'icons/cone-tree.svg' | assetUrl"></app-icon>
            <div>
                {{"Cone Tree" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createNew('round')"
             [matTooltip]="'pick the tree/electric pole add to the map' | translate">
            <app-icon [src]="'icons/round-tree.svg' | assetUrl"></app-icon>
            <div>
                {{"Round Tree" | translate}}
            </div>
        </div>

        <div class="btn"
             (click)="createPoleWire()"
             [matTooltip]="'pick the tree/electric pole add to the map' | translate">
            <app-icon [src]="'icons/wire-pole.svg' | assetUrl"></app-icon>
            <div>
                {{"Wire Pole" | translate}}
            </div>
        </div>
    </div>


    <div class="list-item"
         *ngFor="let tree of trees">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(tree)"
                      class="title-text">
                    {{tree.name}}
                </span>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDelete(tree)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div class="info"
                 (click)="toggle(tree)">
                <div class="info-data">
                    <app-icon [src]="'icons/object-height.svg' | assetUrl"></app-icon>
                    <span>{{tree.height}}m</span>
                    <app-icon [src]="'icons/object-radius.svg' | assetUrl"></app-icon>
                    <span>{{tree.radius}}m</span>
                </div>

                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[tree.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[tree.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[tree.id]?.expanded"
             class="item-content">

            <mat-form-field>
                <mat-label>{{'Name' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="tree.name">
            </mat-form-field>

            <ng-container *ngIf="tree.treeType == 'round'">
                <websolar-number-input [label]="'Radius' | translate"
                                       [size]="'large'"
                                       [(value)]="tree.radius"
                                       (valueChange)="onItemChange(tree)"></websolar-number-input>

                <websolar-number-input [label]="'Trunk Height' | translate"
                                       [size]="'large'"
                                       [(value)]="tree.height"
                                       (valueChange)="onItemChange(tree)"></websolar-number-input>
            </ng-container>

            <ng-container *ngIf="tree.treeType == 'cone'">
                <websolar-number-input [label]="'Radius' | translate"
                                       [size]="'large'"
                                       [(value)]="tree.radius"
                                       (valueChange)="onItemChange(tree)"></websolar-number-input>

                <websolar-number-input [label]="'Branch Height' | translate"
                                       [size]="'large'"
                                       [(value)]="tree.topHeight"
                                       (valueChange)="onItemChange(tree)"></websolar-number-input>

                <websolar-number-input [label]="'Trunk Height' | translate"
                                       [size]="'large'"
                                       [(value)]="tree.height"
                                       (valueChange)="onItemChange(tree)"></websolar-number-input>
            </ng-container>

        </div>
    </div>

    <div class="list-item"
         *ngFor="let pole of poles">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(pole)"
                      class="title-text">
                    {{pole.name}}
                </span>

                <button class="menu-btn"
                        mat-icon-button
                        (click)="onDelete(pole)"
                        color="primary">
                    <mat-icon>delete</mat-icon>
                </button>

            </div>
            <div class="info"
                 (click)="toggle(pole)">
                <div class="info-data">
                    <app-icon [src]="'icons/object-height.svg' | assetUrl"></app-icon>
                    <span>{{pole.height}}m</span>
                </div>

                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[pole.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[pole.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[pole.id]?.expanded"
             class="item-content">

            <mat-form-field>
                <mat-label>{{'Name' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="pole.name">
            </mat-form-field>

            <websolar-number-input [label]="'Height' | translate"
                                   [(value)]="pole.height"
                                   (valueChange)="onItemChange(pole)"
                                   size="large"
                                   [placeholder]="'Enter the height' | translate"></websolar-number-input>

            <websolar-number-input [label]="'Radius' | translate"
                                   [(value)]="pole.radius"
                                   (valueChange)="onItemChange(pole)"
                                   size="large"></websolar-number-input>

            <websolar-number-input [label]="'Wire Radius' | translate"
                                   [(value)]="pole.wireRadius"
                                   (valueChange)="onItemChange(pole)"
                                   size="large"></websolar-number-input>

            <websolar-number-input [label]="'Wire Length' | translate"
                                   [(value)]="pole.wireLength"
                                   (valueChange)="onItemChange(pole)"
                                   size="large"></websolar-number-input>

            <websolar-number-input *ngIf="pole.rotation"
                                   [label]="'Rotation angle' | translate"
                                   [(value)]="pole.rotation.z"
                                   (valueChange)="onItemChange(pole)"
                                   type="angle_rad"
                                   [matTooltip]="'Wire deflection angle, east-west direction is 0°' | translate"></websolar-number-input>

        </div>
    </div>

</div>
