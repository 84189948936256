<div class="component">
    <div class="dialog-container">

        <div class="confirm-title"
             *ngIf="title">
            <mat-icon *ngIf="type === 'alert'"
                      fontSet="material-icons-outlined"
                      class="danger-text">delete</mat-icon>
            <mat-icon *ngIf="type === 'default'"
                      fontSet="material-icons-outlined">info</mat-icon>
            {{title | translate}}
        </div>

        <div class="text-wrapper danger-text">
            {{text | translate}}
        </div>

    </div>

    <div class="btns-wrapper">
        <button *ngIf="!hideCanel"
                mat-stroked-button
                (click)="cancel()">{{"Cancel" | translate}}</button>
        <button mat-flat-button
                color="primary"
                [class.danger]="type === 'alert'"
                (click)="accept()">{{okBtn | translate}}</button>
    </div>

</div>
