import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ModuleEditWindowComponent } from '../module-edit-window/module-edit-window.component';
import { TranslateService } from '@ngx-translate/core';
import { Solar, WebSolarModuleService, NotifyService, WebSolarAuthService } from '@websolar/ng-websolar';
import { CloneTool } from 'src/app/core/clone.tool';
import { UserStorageService } from 'src/app/services/user.storage.service';

@Component({
    selector: 'app-module-picker-window',
    templateUrl: './module-picker-window.component.html',
    styleUrls: ['./module-picker-window.component.scss']
})
export class ModulePickerWindowComponent implements AfterViewInit {

    @ViewChild("paginator") paginator!: MatPaginator;

    public searchText = "";

    public displayedColumns = ['manufacturer', 'model', 'description', 'power', 'tpmax', 'voc', 'copy', 'fav'];

    public pageOptions = [10, 20, 30];

    public resultsLength = 0;

    public isLoading = true;

    /**
     * modules displayed on the page
     */
    public pageModules: Solar.Module[] = [];

    private _modules: Solar.Module[] = [];

    private _favModules: Solar.Module[] = [];

    private _timer: unknown;

    private _loadedModules: Solar.Module[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            manufacturer: string[]
        },
        private _dialogRef: MatDialogRef<ModulePickerWindowComponent>,
        private _moduleService: WebSolarModuleService,
        private _notify: NotifyService,
        private _auth: WebSolarAuthService,
        private _matDialog: MatDialog,
        private _translate: TranslateService,
        private _userStorage: UserStorageService
    ) {
        if (window.innerWidth < 900) {
            this.displayedColumns = ['manufacturer', 'model', 'power']
        }
    }

    ngAfterViewInit(): void {
        this.reload();
    }

    public async reload(force?: boolean) {
        try {
            this.isLoading = true;
            this._modules = [];
            if (force) {
                this._loadedModules = [];
                this._favModules = [];
                this._modules = [];
            }

            await this.loadPage();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSearch() {
        if (this._timer) {
            clearTimeout(this._timer as number)
        }

        this._timer = setTimeout(() => {
            this.paginator.pageIndex = 0;
            this.reload();
        }, 300);
    }


    public async loadPage() {
        try {
            this.isLoading = true;
            this.pageModules = [];


            if (!this._loadedModules.length) {
                this._loadedModules = await this._moduleService.find({
                    limit: 1000
                });
            }
            if (this.searchText) {
                this._modules = this._loadedModules.filter(m => m.model.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            else {
                this._modules = this._loadedModules.slice();
            }

            this.resultsLength = this._modules.length;

            if (!this._favModules.length) {
                // load fav modules
                const favModulesIds = await this._userStorage.getValue("fav_modules") as string[];
                if (favModulesIds && favModulesIds.length) {
                    // load fav modules
                    this._favModules = this._loadedModules.filter(m => favModulesIds.includes(m._id as string));
                    // sort by id
                    this._favModules.sort((m1, m2) => {
                        return favModulesIds.indexOf(m2._id as string) - favModulesIds.indexOf(m1._id as string);
                    })
                }
            }

            this.updatePageModules();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private updatePageModules() {
        const skip = this.paginator.pageIndex * this.paginator.pageSize;
        this.pageModules = this._modules.slice(skip, skip + this.paginator.pageSize);

        // pin the fav modules on the top
        const favIds = this._favModules.map(m => m._id as string);
        this.pageModules = this.pageModules.filter(m => !favIds.includes(m._id as string));
        this.pageModules = this._favModules.slice(0).concat(this.pageModules);
    }

    public onSelect(module: Solar.Module) {
        this._dialogRef.close(module);

    }

    public async add() {
        try {
            if (!this._auth.isSigned()) {
                return;
            }

            const dialogRef = this._matDialog.open(ModuleEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {}
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }

            this.reload(true);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async copy(module: Solar.Module, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            if (!this._auth.isSigned()) {
                return;
            }

            const newModule = CloneTool.clone(module);
            delete newModule._id;
            delete newModule.orgId;
            newModule.model += ` (${this._translate.instant("Copy")})`;

            const dialogRef = this._matDialog.open(ModuleEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    module: newModule
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async edit(module: Solar.Module, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(ModuleEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    module: module
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload(true);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async markAsFav(module: Solar.Module, evt: MouseEvent) {
        try {
            evt.stopPropagation();
            // add to fav list
            this._favModules.splice(0, 0, module);
            // update page modules
            this.updatePageModules();

            const favModulesIds = await this._userStorage.getValue("fav_modules") as string[] || [];
            favModulesIds.push(module._id as string);
            await this._userStorage.setValue({ id: "fav_modules", val: favModulesIds });
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Unsets a module from favorites.
     * 
     * @param module - The module to unset from favorites.
     * @param evt - The mouse event that triggered the unset action.
     */
    public async unsetFav(module: Solar.Module, evt: MouseEvent) {
        try {
            evt.stopPropagation();
            // unset from fav
            this._favModules = this._favModules.filter(m => m != module);
            this.updatePageModules();

            let favModulesIds = await this._userStorage.getValue("fav_modules") as string[] || [];
            favModulesIds = favModulesIds.filter(id => id != module._id);
            await this._userStorage.setValue({ id: "fav_modules", val: favModulesIds });
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public isFav(module: Solar.Module): boolean {
        return this._favModules.includes(module);
    }
}
