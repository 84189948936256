<div *ngIf="project">
    <div class="title">
        {{"Irradiation" | translate}}
    </div>
    <div class="box">
        <app-report-losses-bar title="optimal irradiation"
                               value="{{losses.optimalIrradiance}} {{'kWh/m2' | translate}}"
                               [percent]="100"
                               [primary]="true"></app-report-losses-bar>
        <app-report-losses-bar title="azimuth, tilt"
                               value="-{{losses.orientation | number:'1.2-2'}}%"
                               [percent]="losses.orientation"
                               [offset]="0"></app-report-losses-bar>
        <app-report-losses-bar title="soling"
                               value="-{{losses.soling | number:'1.2-2'}}%"
                               [percent]="losses.soling"
                               [offset]="losses.offsets.offset1"></app-report-losses-bar>
        <app-report-losses-bar title="shading"
                               value="-{{losses.shading | number:'1.2-2'}}%"
                               [percent]="losses.shading"
                               [offset]="losses.offsets.offset2"></app-report-losses-bar>
        <app-report-losses-bar title="output"
                               value="{{losses.output1.value | roundNumber}} {{'kWh' | translate}}"
                               [percent]="losses.output1.percent"
                               [primary]="true"></app-report-losses-bar>

        <div class="title">
            {{"DC" | translate}}
        </div>
        <app-report-losses-bar title="snow"
                               value="-{{losses.snow | number:'1.2-2'}}%"
                               [percent]="losses.snow"
                               [offset]="losses.offsets.offset3"></app-report-losses-bar>        
        <app-report-losses-bar title="temperature"
                               value="-{{losses.temperature | number:'1.2-2'}}%"
                               [percent]="losses.temperature"
                               [offset]="losses.offsets.offset4"></app-report-losses-bar>
        <app-report-losses-bar title="electrical losses"
                               value="-{{losses.electrical | number:'1.2-2'}}%"
                               [percent]="losses.electrical"
                               [offset]="losses.offsets.offset5"></app-report-losses-bar>
        <app-report-losses-bar title="optimizer efficiency"
                               value="-{{losses.optimizer | number:'1.2-2'}}%"
                               [percent]="losses.optimizer"
                               [offset]="losses.offsets.offset6"></app-report-losses-bar>
        <app-report-losses-bar title="DC wiring"
                               value="-{{losses.DCWiring | number:'1.2-2'}}%"
                               [percent]="losses.DCWiring"
                               [offset]="losses.offsets.offset7"></app-report-losses-bar>
        <app-report-losses-bar title="output"
                               value="{{losses.output2.value | roundNumber}} {{'kWh' | translate}}"
                               [percent]="losses.output2.percent"
                               [primary]="true"></app-report-losses-bar>



        <div class="title">
            {{"AC" | translate}}
        </div>
        <app-report-losses-bar title="inverter efficiency"
                               value="-{{losses.inverter | number:'1.2-2' }}%"
                               [percent]="losses.inverter"
                               [offset]="losses.offsets.offset8"></app-report-losses-bar>
        <app-report-losses-bar title="AC wiring"
                               value="-{{losses.ACWiring | number:'1.2-2'}}%"
                               [percent]="losses.ACWiring"
                               [offset]="losses.offsets.offset9"></app-report-losses-bar>
        <app-report-losses-bar title="Availability/Maintenance"
                               value="-{{losses.maintenance | number:'1.2-2'}}%"
                               [percent]="losses.maintenance"
                               [offset]="losses.offsets.offset10"></app-report-losses-bar>
        <app-report-losses-bar title="output"
                               value="{{losses.output3.value | roundNumber}} {{'kWh' | translate}}"
                               [percent]="losses.output3.percent"
                               [primary]="true"></app-report-losses-bar>

    </div>
</div>
