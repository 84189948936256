<div class="component">
    <div class="list-item"
         *ngFor="let panel of items">
        <div class="line highlight">
            <div class="title">
                <span (click)="toggle(panel)"
                      class="title-text">
                    {{panel.name || ("Main Service Panel" | translate)}}
                </span>
            </div>
            <div class="info"
                 (click)="toggle(panel)">

                <div *ngIf="itemState[panel.id]"
                     class="info-data">
                    <mat-icon inline="true">border_outer</mat-icon>
                    <span>{{itemState[panel.id].item.raiting }}A</span>
                </div>


                <div class="expander">
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="itemState[panel.id]?.expanded">
                        expand_less
                    </mat-icon>
                    <mat-icon inline="true"
                              color="primary"
                              *ngIf="!itemState[panel.id]?.expanded">
                        expand_more
                    </mat-icon>
                </div>
            </div>
        </div>

        <div *ngIf="itemState[panel.id]?.expanded"
             class="item-content">

            <div *ngIf="getErrorMessage(panel) | translate"
                 class="error-wrapper">
                <mat-icon>warning</mat-icon>
                <div class="text">
                    {{getErrorMessage(panel) | translate}}
                </div>
            </div>


            <mat-form-field>
                <mat-label>{{'Description' | translate}}</mat-label>
                <input matInput
                       [placeholder]="'Enter the name' | translate"
                       [(ngModel)]="panel.name">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'PV Connection' | translate}}</mat-label>
                <mat-select [(ngModel)]="panel.connectionType"
                            (ngModelChange)="connectionChanged(panel)">
                    <mat-option *ngFor="let r of connectionTypes"
                                [value]="r.id">
                        {{r.name | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <mat-form-field>
                <mat-label>{{'Bus Bar Size' | translate}} (A)</mat-label>
                <input matInput
                       [placeholder]="'Enter the rating' | translate"
                       type="number"
                       min="0"
                       [(ngModel)]="panel.raiting"
                       (ngModelChange)="onItemChange(panel)">
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'Main Breaker Rating' | translate}} (A)</mat-label>
                <input matInput
                       [placeholder]="'Enter the rating' | translate"
                       type="number"
                       min="0"
                       [(ngModel)]="panel.mainBreaker"
                       (ngModelChange)="onItemChange(panel)">
            </mat-form-field>

            <mat-form-field *ngIf="panel.connectionType == 'load-side-tap'">
                <mat-label>{{'Solar Breaker' | translate}} (A)</mat-label>
                <input matInput
                       [placeholder]="'Enter the rating' | translate"
                       type="number"
                       min="0"
                       [(ngModel)]="panel.pvBreaker"
                       (ngModelChange)="onItemChange(panel)">
            </mat-form-field>
        </div>
    </div>

    <div class="hint"
         *ngIf="items.length == 0">
        {{"You don't have any inverter yet,back to 'Electrical' add the inverter." | translate}}
    </div>



</div>
