<div class="component">
    <div class="logo hide-sm">
        <button *ngIf="!hideBack"
                class="back-btn"
                mat-icon-button
                (click)="onBack()">
            <mat-icon fontSet="material-icons-outlined">arrow_back_ios</mat-icon>
        </button>
        <div class="app-logo-wrapper"
             [class.center]="hasLogo">
            <app-logo></app-logo>
        </div>
    </div>
    <div class="project-name hide-sm">
        {{project?.name}}
    </div>

    <div class="close-btn show-sm">
        <button mat-icon-button
                (click)="onBack()">
            <mat-icon fontSet="material-icons-outlined">close</mat-icon>
        </button>
    </div>


    <div class="main-menu-wrapper">

        <ng-container *ngFor="let item of menuItems; let idx = index; let last = last;">
            <div class="item-wrapper">
                <div class="main-item-wrapper"
                     (click)="activateItem(item)">
                    <div class="step-number"
                         [class.done]="isStepDone(item)">
                        {{idx + 1}}
                    </div>

                    <div class="menu-item"
                         [class.active]="item.active">
                        <ng-container *ngIf="item.icon">
                            <mat-icon inline="true"
                                      fontSet="material-icons-outlined">{{item.icon}}</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="item.svgIcon">
                            <app-icon class="svg-icon"
                                      [src]="item.svgIcon"></app-icon>
                        </ng-container>
                        <ng-container *ngIf="item.pngIcon">
                            <img class="png-icon"
                                 [src]="item.pngIcon" />
                        </ng-container>

                        <span>{{item.title | translate}}</span>
                    </div>
                </div>

                <div class="sub-menu-container hide-sm"
                     *ngIf="item.children && item.expanded">
                    <div class="sub-menu-item"
                         *ngFor="let subItem of item.children"
                         (click)="activateItem(subItem)"
                         [class.active]="subItem.active">

                        <ng-container *ngIf="subItem.icon">
                            <mat-icon inline="true"
                                      fontSet="material-icons-outlined">{{subItem.icon}}</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="subItem.svgIcon">
                            <app-icon class="svg-icon"
                                      [src]="subItem.svgIcon"></app-icon>
                        </ng-container>
                        <ng-container *ngIf="subItem.pngIcon">
                            <img class="png-icon"
                                 [src]="subItem.pngIcon" />
                        </ng-container>
                        <span>{{subItem.title | translate}}</span>
                    </div>
                </div>

                <div *ngIf="!last"
                     class="line"
                     [class.done]="isStepDone(item)"></div>

                <!-- Show line for mobile screen -->
                <div *ngIf="last"
                     class="line show-sm"
                     [class.done]="isStepDone(item)"></div>
            </div>

        </ng-container>

    </div>

    <div class="footer hide-sm">
        <div class="bottom-item"
             (click)="activateMode('Sharing')">
            <mat-icon inline="true"
                      fontSet="material-icons-outlined">share</mat-icon>
            <span>{{ 'Online Proposal' | translate }}</span>
        </div>
        <div class="bottom-item"
             (click)="activateMode('Settings')">
            <mat-icon inline="true"
                      fontSet="material-icons-outlined">settings</mat-icon>
            <span>{{'Settings' | translate}}</span>
        </div>
    </div>

</div>
