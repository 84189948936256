import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService, Solar } from '@websolar/ng-websolar';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-site-plan',
    templateUrl: './permit-site-plan.component.html',
    styleUrls: ['./permit-site-plan.component.scss']
})
export class PermitSitePlanComponent implements OnChanges {

    @Input() permitScope!: AIKO.PermitScope;

    public siteScale = `1in = 10ft`;

    public strings: Solar.ObjectString[] = [];

    constructor(
        private _notify: NotifyService,
        private _translate: TranslateService
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        this.init();
    }


    private init() {
        try {
            if (!this.permitScope) {
                return;
            }

            const siteHeightMeters = this.permitScope.content.siteHeight;
            // img size is 9in (css class .site-plan-img)
            const heightFeet = siteHeightMeters * 3.28084;
            const scale = Math.round(heightFeet / 9);
            this.siteScale = `1${this._translate.instant("inch")} = ${scale}${this._translate.instant("ft")}`

            this.strings = this.permitScope.objects.filter(o => o.type == "string") as Solar.ObjectString[];
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
