<div *ngIf="project"
     class="component">

    <button mat-stroked-button
            color="primary"
            [matMenuTriggerFor]="menu">
        <mat-icon>map</mat-icon>
    </button>
    <mat-menu #menu="matMenu">

        <ng-container *ngIf="type == 'google'">
            <button mat-menu-item
                    class="btn-map-menu-item"
                    (click)="project.mapType='google'"
                    [class.active]="project.mapType == 'google'">{{"Google" | translate}}</button>
            <button mat-menu-item
                    class="btn-map-menu-item"
                    (click)="project.mapType='3d_google'"
                    [class.active]="project.mapType == '3d_google'">{{"3D Google" | translate}}</button>
        </ng-container>

        <ng-container *ngIf="type == 'amap'">
            <button mat-menu-item
                    class="btn-map-menu-item"
                    (click)="setAMap()"
                    [class.active]="project.mapType == 'intergrated'">{{"AMap" | translate}}</button>
        </ng-container>

        <button mat-menu-item
                class="btn-map-menu-item"
                (click)="uploadCustomMap()"
                [class.active]="project.mapType == 'custom'">{{"Custom Image" | translate}}</button>
        <button mat-menu-item
                class="btn-map-menu-item"
                (click)="uploadTexturedMesh()"
                [class.active]="project.mapType == 'textured_mesh'">{{"Textured Mesh" | translate}}</button>
    </mat-menu>

</div>
