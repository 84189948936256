import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService, Solar, WebSolarBatteryService } from '@websolar/ng-websolar';
import { BatteryEditComponent } from '../battery-edit/battery-edit.component';
import { CloneTool } from 'src/app/core/clone.tool';

@Component({
    selector: 'app-battery-page',
    templateUrl: './battery-window.component.html',
    styleUrls: ['./battery-window.component.scss']
})
export class BatteryWindowComponent implements AfterViewInit {

    @ViewChild("paginator") paginator!: MatPaginator;

    public searchText = "";

    public displayedColumns = ['manufacturer', 'model', 'capacity', 'voltage', 'dod', "copy"];

    public pageOptions = [10, 20, 30];

    public resultsLength = 0;

    public isLoading = true;

    public batteries: Solar.Battery[] = [];

    private _manufatories: string[];

    private _timer: unknown;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            manufacturer: string[]
        },
        private _dialogRef: MatDialogRef<BatteryWindowComponent>,
        private _batteryService: WebSolarBatteryService,
        private _notify: NotifyService,
        private _matDialog: MatDialog,
        private _paginator: MatPaginatorIntl,
        private _translate: TranslateService
    ) {
        this._manufatories = data.manufacturer;

        // update paginator lang
        this._paginator.itemsPerPageLabel = this._translate.instant("Items per page");
    }

    ngAfterViewInit(): void {
        this.reload();
    }

    public async reload() {
        try {
            this.isLoading = true;
            this.batteries = [];

            this.resultsLength = await this._batteryService.count({
                search: this.searchText,
                manufacturer: this._manufatories
            })

            await this.loadPage();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSearch() {
        if (this._timer) {
            clearTimeout(this._timer as number)
        }

        this._timer = setTimeout(() => {
            this.paginator.pageIndex = 0;
            this.reload();
        }, 300);
    }


    public async loadPage() {
        try {
            this.isLoading = true;
            this.batteries = [];

            const skip = this.paginator.pageIndex * this.paginator.pageSize;

            this.batteries = await this._batteryService.find({
                search: this.searchText,
                manufacturer: this._manufatories,
                skip: skip,
                limit: this.paginator.pageSize
            });
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }


    public async add() {
        try {
            const dialogRef = this._matDialog.open(BatteryEditComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {}
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    public async copy(battery: Solar.Battery, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const newBattery = CloneTool.clone(battery);
            delete newBattery._id;
            newBattery.model += ` (${this._translate.instant("Copy")})`
            newBattery.orgId = "";

            const dialogRef = this._matDialog.open(BatteryEditComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    battery: newBattery
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async edit(battery: Solar.Battery, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(BatteryEditComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    battery: battery
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public onSelect(battery: Solar.Battery) {
        this._dialogRef.close(battery);
    }
}
