import { Injectable } from "@angular/core";

@Injectable()
export class QueryService {
    public getSnapshot() {
        const urlParams = new URLSearchParams(window.location.search);
        let params: { [key: string]: string | string[] } = {};
        urlParams.forEach((value, key) => {
            if (params.hasOwnProperty(key)) {
                params[key] = (Array.isArray(params[key]) ? [...params[key], value] : [params[key], value]) as string[];
            } else {
                params[key] = value;
            }
        })
        return params;
    }
} 