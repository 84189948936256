import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService } from '@websolar/ng-websolar';
import { DialogService } from 'src/app/services/dialog.service';

@Component({
  selector: 'app-meters-list',
  templateUrl: './meters-list.component.html',
  styleUrls: ['./meters-list.component.scss']
})
export class MetersListComponent implements OnChanges {

    @Input() instance!: SolarInstance;

    @Output() meterChange = new EventEmitter<Solar.ObjectMeter>();

    @Output() toolActivated = new EventEmitter<{ type: Solar.ToolType, params: unknown }>();

    public meters: Solar.ObjectMeter[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.ObjectMeter } } = {};

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _eventService: WebSolarEventsService
    ) { 
        // subscribe to events
        this._eventService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo") {
                this.updateList();
            }
        else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
        })
    }

    public ngOnChanges(): void {
        try {
            this.updateList();
        }
        catch (err) {
            console.error();
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (object.type != "meter") {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    private updateList() {
        try {
            if (!this.instance) {
                return;
            }
            const prevItems = this.meters;

            this.meters = this.instance.getObjects({ types: ["meter"] }) as Solar.ObjectMeter[];

            for (const item of this.meters) {
                this.initObject(item);
            }

            if (prevItems && prevItems.length && prevItems.length < this.meters.length) {
                // probably new item added
                const last = this.meters[this.meters.length - 1];
                this.itemState[last.id].expanded = true;
            }

            if (this.meters.length == 1) {
                // expand it
                this.itemState[this.meters[0].id].expanded = true;
            }
        }
        catch (err) {
            console.error();
        }
    }

    public onNew() {
        this.toolActivated.emit({ type: "meter", params: null });
    }

    public toggle(segment: Solar.ObjectMeter) {
        this.itemState[segment.id].expanded = !this.itemState[segment.id].expanded;
    }

    private initObject(meter: Solar.ObjectMeter) {
        this.itemState[meter.id] = {
            expanded: false,
            item: meter
        };
    }

    public onItemChange(item: Solar.ObjectMeter) {
        // notify about changes
        this.meterChange.emit(item)
    }

    public onToolActivated(opt: Solar.ToolOptions) {
        this.toolActivated.emit(opt)
    }

    public async onDelete(item: Solar.ObjectMeter) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete meter`,
                text: `Are you sure you want to delete this meter?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove children
            this.instance.removeObjects({
                ownerId: item.id
            });

            // delete keepout as well
            this.instance.removeObjects({
                id: item.id
            });

            // remove from the list
            this.meters = this.meters.filter(k => k != item)
        }
        catch (err) {
            this._notify.error(err);
        }
    }





}
