import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Solar, WebSolarReportService, WebSolarCurrencyService, WebSolarProjectStateService } from '@websolar/ng-websolar';


@Component({
    selector: 'app-settings-pannel',
    templateUrl: './settings-pannel.component.html',
    styleUrls: ['./settings-pannel.component.scss']
})
export class SettingsPannelComponent implements OnChanges {

    @Input() project!: Solar.Project;


    public measurements = [
        { id: "imperial", name: "Imperial" },
        { id: "metric", name: "Metric" }
    ]

    public currencies: Solar.Currency[];

    public currencyCode = "";

    constructor(
        private _reportService: WebSolarReportService,
        private _currencyService: WebSolarCurrencyService,
        private _state: WebSolarProjectStateService
    ) {
        this.currencies = this._currencyService.getList();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.project) {
            if (!this.project.systemLoses) {
                this.project.systemLoses = this._reportService.getDefaultLoses()
            }
            if (!this.project.measurement) {
                this.project.measurement = "imperial";
            }

            this.currencyCode = this.project.currency?.code || "USD";
        }
    }

    public onMeasurementChange() {
        this._state.units = this.project.measurement;
    }

    public currencyChanged() {
        const newCurrency = this.currencies.find(c => c.code == this.currencyCode);
        if (newCurrency) {
            this.project.currency = newCurrency;
        }
    }

}
