import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appDigitsLimit]'
})
export class DigitsLimiterDirective {
    private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);

    constructor(private el: ElementRef) {
    }

    @HostListener("keydown", ["$event"])
    onKeyDown(event: KeyboardEvent) {
        this.run(this.el.nativeElement.value);
    }

    @HostListener("paste", ["$event"])
    onPaste(event: ClipboardEvent) {
        this.run(this.el.nativeElement.value);
    }

    private run(oldValue: string) {
        setTimeout(() => {
            let currentValue: string = this.el.nativeElement.value;
            if (currentValue !== '') {
                const isMatched = String(currentValue).match(this.regex)
                if (!isMatched) {
                    this.el.nativeElement.value = oldValue;
                }
            }
        });
    }
}