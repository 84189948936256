import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cn-terrain-category-help',
    templateUrl: './cn-terrain-category-help.component.html',
    styleUrls: ['./cn-terrain-category-help.component.scss']
})
export class CnTerrainCategoryHelpComponent  {

    constructor() { }

}
