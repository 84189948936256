import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotifyService } from '@websolar/ng-websolar';
import { TerrainCategoryService } from 'src/app/services/terrainCategory.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-eu-terrain-category-help',
    templateUrl: './eu-terrain-category-help.component.html',
    styleUrls: ['./eu-terrain-category-help.component.scss']
})
export class EUTerrainCategoryHelpComponent {

    public terrains: AIKO.TerrainCategory[] = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            categories: AIKO.TerrainCategory[]
        },
        private _terrainService: TerrainCategoryService,
        private _notify: NotifyService
    ) {
        this.terrains = data.categories;
    }


}
