<div class="component"
     *ngIf="project">
    <div class="stat-info">
        <div class="item-wrapper self-consumption">
            <div class="lbl">{{"Saving" | translate}}</div>
            <div class="val">
                {{project.currency.sign}}{{saving}} ({{savingPercent}}%)
            </div>
            <div class="saving-line"
                 [ngStyle]="{ 'width': savingPercent + '%' }">
            </div>
        </div>
        <div class="item-wrapper import">
            <div class="lbl">{{"Payment" | translate}}</div>
            <div class="val">
                {{project.currency.sign}}{{import}} ({{importPercent}}%)
            </div>
        </div>
        <div class="import-line"
             [ngStyle]="{ 'width': importPercent + '%' }">
        </div>
    </div>

    <div class="chart-wrapper">
        <ngx-charts-pie-chart [results]="results"
                              [scheme]="scheme"
                              [doughnut]="true">
        </ngx-charts-pie-chart>

        <div class="total">
            {{project.currency.sign}}{{total}}
        </div>
    </div>

</div>
