<div class="component"
     *ngIf="node && instance">
    <div class="node-wrapper">
        <div class="node-content node-type-{{node.object.type}}">

            <ng-container *ngIf="node.object.type == 'inverter'">
                <div class="inverter-wrapper">
                    <div class="inverter-content">
                        <app-icon [src]="'icons/inverter.svg' | assetUrl"></app-icon>
                        <span>{{formatIndex(inverterIndex + 1)}} {{getInverter(node).inverter.manufacturer}}
                            {{getInverter(node).inverter.model}}</span>
                    </div>
                    <div class="inverter-details">
                        <span *ngIf="getInverter(node).color"
                              [ngStyle]="{ 'background-color': getInverter(node).color }"
                              class="color-item"></span>
                        <mat-icon inline="true">bolt</mat-icon>
                        <span>{{getInverter(node).inverter.minVoltage}}-{{getInverter(node).inverter.maxVoltage}}V</span>
                        <span>{{'Description' | translate}}:</span>
                        <span>{{node.name}}</span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="node.object.type != 'inverter'">
              
                <div>
                    <div>
                        {{node.name | translate}}
                    </div>

                    <div class="info"
                         *ngIf="infoItems.length">
                        <ng-container *ngFor="let item of infoItems">
                            <span *ngIf="item.color"
                                  [ngStyle]="{ 'background-color': item.color }"
                                  class="color-item"></span>
                            <mat-icon *ngIf="item.icon"
                                      inline="true">{{item.icon}}</mat-icon>
                            <span>{{item.text}}</span>
                        </ng-container>
                    </div>

                </div>
            </ng-container>


        </div>


        <div class="btns">
            <button mat-stroked-button
                    color="primary"
                    [matMenuTriggerFor]="menu"
                    *ngIf="actions.length > 0">
                <mat-icon>add</mat-icon>
            </button>
            <button *ngIf="canDelete"
                    mat-stroked-button
                    color="primary"
                    (click)="deleteNode(node)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
        <mat-menu #menu="matMenu">
            <button mat-menu-item
                    *ngFor="let action of actions"
                    (click)="addComponent(action)">
                {{action.name | translate}}
            </button>
        </mat-menu>
    </div>

    <div class="children"
         *ngIf="node.children && node.children.length && node.object.type != 'inverter'">

        <div class="children-nodes">
            <div *ngFor="let child of node.children; let last = last;"
                 class="node-wrapper">
                <div class="vert-line"
                     [class.last-line]="last">
                </div>
                <div class="horz-line"></div>
                <app-tree-config-node [node]="child"
                                      [parent]="node"
                                      [instance]="instance"
                                      [config]="config"
                                      [project]="project"
                                      [eventsEmitter]="eventsEmitter"
                                      (nodeChanged)="refresh()"></app-tree-config-node>
            </div>
        </div>
    </div>
</div>
