<div class="component-wrapper">
    <div class="header">
        Support Inverters
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="table-wrapper"
         *ngIf="!isLoading">

        <table>
            <thead>
                <tr>
                    <th>Inverter Model</th>
                    <th>Support</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items">
                    <td>{{item.inverter.model}}</td>
                    <td>
                        <mat-checkbox [(ngModel)]="item.selected">
                        </mat-checkbox>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="save-btn-wrapper">
            <button (click)="save()"
                    mat-flat-button
                    color="primary">
                Save
            </button>
        </div>


    </div>
    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
