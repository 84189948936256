<h1>{{title | titlecase | translate}}</h1>

<div class="legend">
    <div *ngFor="let legendItem of legend"
         class="item">
        <div class="color"
             [ngStyle]="{ 'background-color': legendItem.color}"></div>
        <div class="text">{{legendItem.val}}</div>
    </div>
</div>

<div class="map-wrapper"
     *ngIf="!noData">

    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>

    <div #mapElement
         class="map"></div>

</div>

<div *ngIf="noData"
     class="no-data">
    <h2>{{"Data unavailable for your project location." | translate}}</h2>
</div>


<div class="btn-wrapper">
    <button mat-stroked-button
            mat-dialog-close
            color="primary">
        {{"Close" | translate}}
    </button>
</div>
