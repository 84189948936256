import html2canvas from 'html2canvas';
import { Injectable } from "@angular/core";
import { ThreadTool } from "../core/thread.tool";
import { Solar } from "@websolar/ng-websolar";
import { MapProjector } from "../core/map.projector";
import { ProjectHelper } from "../core/project.helper";
import { AMapCitySearchResult } from '../types/amap.types';
import { HttpService } from './http.service';

/**
 * API reference: https://a.amap.com/jsapi/static/doc/index.html
 */
@Injectable()
export class AMapMapService {

    constructor(
        private _http: HttpService
    ) { }

    /**
     * Waits for AMap initialization.
     * @returns {Promise<void>} A promise that resolves when AMap is initialized.
     */
    public async waitAMapInitialization() {
        const timeout = Date.now() + 20000;
        while (Date.now() < timeout) {
            if (typeof window["AMap"] === "undefined") {
                await ThreadTool.sleep(1000);
                continue;
            }

            return;
        }
    }

    /**
     * Retrieves the address based on the given latitude and longitude coordinates.
     * @param lat - The latitude coordinate.
     * @param lng - The longitude coordinate.
     * @returns A promise that resolves to the address as a string.
     */
    public getAddress(lat: number, lng: number): Promise<string> {

        return new Promise<string>((resolve, reject) => {
            try {
                this.waitAMapInitialization().then(() => {
                    // TODO: The AMap typings doesn't contains defenition for plugins
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const geocoder = new (window as any).AMap.Geocoder({
                        radius: 1000,
                        extensions: "all"
                    });


                    geocoder.getAddress(new AMap.LngLat(lng, lat), (result: unknown, data: {
                        regeocode: {
                            formattedAddress: string
                        }
                    }) => {
                        console.log("geocode result:", result, data);
                        resolve(data?.regeocode?.formattedAddress || "");
                    });
                })
            }
            catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Uploads a static image of the map for the given project.
     * @param project The project for which the map image is being uploaded.
     */
    public async uploadStaticImage(project: Solar.Project) {

        const { canvas, boxSize } = await this.getMapScreenshotAsCanvas(project);

        // upload image
        const res = await this._http.post(`/map/custom/upload`, {
            img: canvas.toDataURL()
        }) as { id: string };

        project.customMap = {
            id: res.id,
            height: boxSize,
            width: boxSize
        }
    }

    /**
     * Retrieves a screenshot of the map as a canvas.
     *
     * @param project The project for which to capture the map screenshot.
     * @returns An object containing the canvas and the image size.
     */
    public async getMapScreenshotAsCanvas(project: Solar.Project) {
        await this.waitAMapInitialization();

        const zoomLevel = ProjectHelper.getZoomLevel(project.projectSize);
        const imgSize = 640;
        const mapProjector = new MapProjector();

        // get mesh size
        const boxSize = mapProjector.getSizeInMeters(zoomLevel, project.location, imgSize);
        console.log(`Map box size: `, boxSize);

        const container = document.createElement("div");
        container.style.width = "640px";
        container.style.height = "640px";
        document.body.append(container);

        await this.initBackgroundMap(container, zoomLevel, project.location);

        // get a screenshot
        const canvas = await html2canvas(container, {
            allowTaint: true,
            useCORS: true,
            windowWidth: imgSize,
            windowHeight: imgSize
        });

        console.log("AMap screenshot completed");

        // remove container from the body
        container.remove();

        return { canvas, boxSize };
    }

    private initBackgroundMap(container: HTMLElement, zoomLevel: number, location: Solar.GeoLocation) {
        return new Promise((resolve) => {
            const map = new AMap.Map(container, {
                layers: [new AMap.TileLayer.Satellite()],
                zoom: zoomLevel,
                center: new AMap.LngLat(location.lng, location.lat)
            });
            map.on("complete", () => {
                resolve(true);
            })
        })
    }


    /**
     * Retrieves the current geolocation using the Google Geolocation API.
     * @returns {Promise<any>} A promise that resolves to the current geolocation.
     * @throws {Error} If there is an error retrieving the geolocation.
     */
    public async getCurrentGeolocationAMap(): Promise<Solar.GeoLocation | null> {
        return new Promise((resolve, reject) => {
            this.waitAMapInitialization().then(() => {
                // TODO: The AMap typings doesn't contains defenition for plugins
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const citysearch = new (window as any).AMap.CitySearch();
                citysearch.getLocalCity((status: string, result: AMapCitySearchResult) => {
                    if (status === 'complete' && result.info === 'OK') {
                        if (result && result.city && result.bounds) {
                            resolve({
                                name: result.city,
                                lat: result.bounds.getCenter().getLat(),
                                lng: result.bounds.getCenter().getLng()
                            });
                            return;
                        }
                    }

                    resolve(null);
                });
            })
        })
    }
}
