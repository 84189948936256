import { Pipe, PipeTransform } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';
import { AIKO } from '../types/aiko.types';

@Pipe({
    name: 'castProject'
})
export class CastProject implements PipeTransform {
    transform(value: Solar.Project): AIKO.ProjectExt {
        return value as AIKO.ProjectExt;
    }
}