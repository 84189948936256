import * as THREE from "three";
import moment from "moment";
import { Injectable } from "@angular/core";
import { Solar, SolarInstance, WebSolarGeometryService, WebSolarShadingService, WebSolarWiringService } from "@websolar/ng-websolar";
import { DialogService } from "./dialog.service";
import { Geometry } from "../core/geometry";
import { assetUrl } from "../pipes/asset.url.pipe";


@Injectable()
export class OptimizerService {

    constructor(
        private _shadingService: WebSolarShadingService,
        private _wiringService: WebSolarWiringService,
        private _dialogService: DialogService,
        private _geometry: WebSolarGeometryService
    ) { }

    /**
     * Runs the auto placement algorithm to configure optimizers for the given solar instance and project.
     * 
     * @param instance - The solar instance.
     * @param project - The solar project.
     * @returns A promise that resolves when the auto placement algorithm is completed.
     */
    public async runAutoPlacement(
        instance: SolarInstance,
        project: Solar.Project) {

        // remove the previous optimizers
        instance.removeObjects({ types: ["optimizer"] });

        const segments = instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        const hasTiltSegments = segments.find(s => s.racking == "fixed_tilt" && s.module && s.layoutGrouping?.enabled);

        const output = await this._shadingService.run({
            instance: instance,
            project: project,
            cancellation: { cancelled: false },
            saveResults: false,
            createIrradiance: false,
            includeSensorHourly: true, // generate the hours output
            useModules: Boolean(hasTiltSegments)
        })
        console.log("output:", output);
        if (!output) {
            return;
        }

        // collect the segments ids
        const segmentsIds: string[] = [];
        for (const item of output.items) {
            const segmentId = item.segmentId || "";
            if (!segmentsIds.includes(segmentId)) {
                segmentsIds.push(segmentId);
            }
        }


        // The logic about place optimizer automatically:
        // When the modules meet the following conditions at the same time, 
        // the optimizer can be configured
        // 1. The average shadow area of the module is more than 5 % from 9am to 15pm;
        // 2、The average shadow area of the module is less than 30 % from12am to 13pm;

        for (const segmentId of segmentsIds) {

            const modulesLosses1 = this.getAverageLosses(segmentId, output.items, 9, 15);
            const modulesLosses2 = this.getAverageLosses(segmentId, output.items, 12, 13);

            const modulesIds: string[] = [];

            for (const item of output.items) {
                if ((item.segmentId || "") != segmentId) {
                    continue;
                }
                const losses1 = modulesLosses1.get(item.id);
                const losses2 = modulesLosses2.get(item.id);

                // console.log(`check module: losses #1 (${losses1}%) losses #2 (${losses2}%)`);

                if (!losses1 || losses1 < 5) {
                    continue;
                }
                if (!losses2 || losses2 > 30) {
                    continue;
                }

                // Meet the both criteria

                modulesIds.push(item.id);

                console.log(`detected the optimizer: losses #1 (${losses1}%) losses #2 (${losses2}%)`);
            }


            if (modulesIds.length > 0) {
                // Create the optimizers
                const modules = instance.getObjects({ id: modulesIds, types: ["module"] }) as Solar.ObjectModule[];
                this.createOptimizers(instance, modules, project);
            }
        }
    }


    /**
     * Calculates the average losses for a given segment and time range.
     * 
     * @param segmentId - The ID of the segment.
     * @param items - An array of production output items.
     * @param startHr - The starting hour of the time range.
     * @param endHr - The ending hour of the time range.
     * @returns A map of item IDs to their corresponding average losses.
     */
    private getAverageLosses(
        segmentId: string,
        items: Solar.ProductionOutput[],
        startHr: number,
        endHr: number) {

        const averageLosses = new Map<string, number>();
        let maximum = 0;

        for (const item of items) {
            if ((item.segmentId || "") != segmentId) {
                continue;
            }
            if (!item.hourlyIrradiance || !item.hourlyIrradiance.length) {
                continue;
            }
            let total = 0;
            let count = 0;

            // 1 series is 1 hour. Total is around 8759
            let startDate = moment().utcOffset(0).year(2000).startOf("year");
            for (const [hourIdx, val] of item.hourlyIrradiance.entries()) {
                const hour = moment(startDate).add(hourIdx, "hours").hour();
                if (hour < startHr || hour > endHr) {
                    continue;
                }
                total += val;
                count++;
            }

            const avgVal = count ? (total / count) : 0;
            averageLosses.set(item.id, avgVal);

            // update the maximum
            maximum = Math.max(maximum, avgVal);
        }

        if (maximum > 0) {
            // set losses
            for (let [key, val] of averageLosses.entries()) {
                const losses = Math.round((maximum - val) / maximum * 1000) / 10;
                averageLosses.set(key, losses);
            }
        }

        return averageLosses;
    }

    /**
     * Places optimizers manually
     * 
     * @param instance - The solar instance.
     * @param project - The solar project.
     * @returns An array of newly created optimizers.
     */
    public async placeManually(instance: SolarInstance, project: Solar.Project) {
        const listener = (evt: MouseEvent) => {
            // 2 is the button value for the right click
            if (evt.button === 2) {
                instance.sendEscape();
            }
        }

        let previewObjects: Solar.ObjectImage[] = [];

        try {
            window.addEventListener('mousedown', listener);

            // cancel the previous tool
            await instance.sendEscape();


            while (true) {
                instance.getHint().setMessage(`Select module(s) or press ESC to exit`);

                let selectedModules: Solar.ObjectModule[] = [];
                instance.highlight.clear("hover_group");

                const result = await instance.selector.select({
                    types: ["module"],
                    callback: (obj: THREE.Object3D) => {
                        // clear highlight
                        instance.highlight.clear("hover_group");
                        // clear previous preview
                        instance.removeObjects({ id: previewObjects.map(o => o.id) });
                        selectedModules = [];

                        const moduleObj = obj.userData as Solar.ObjectModule;
                        const connection = this.getModuleConnection(moduleObj, project);
                        if (!connection ||
                            !connection.string ||
                            !connection.inverter ||
                            !connection.inverter.optimizer) {
                            return;
                        }
                        const idx = connection.string.moduleIds.indexOf(moduleObj.id);
                        const maxNr = connection.inverter.optimizer.maxNumberOfModules || 1;
                        const modulesIds = connection.string.moduleIds.slice(idx, idx + maxNr);
                        selectedModules = instance.getObjects({ id: modulesIds, types: ["module"] }) as Solar.ObjectModule[];


                        // create preview
                        previewObjects = this.createPreviewObjects(instance, selectedModules, project);

                        // highlight modules
                        const modules3dObjects = instance.get3dObjects({ id: modulesIds, types: ["module"] });
                        for (const obj3d of modules3dObjects) {
                            instance.highlight.add(obj3d, "hover_group");
                        }
                    }
                });

                // clear previous preview
                instance.removeObjects({ id: previewObjects.map(o => o.id) });

                // clear selection
                instance.highlight.clear("hover_group");

                if (!result || !selectedModules.length) {
                    // check if we need to show a warn message
                    if (result && result.object) {
                        await this.getIsValid([result.object.userData as Solar.ObjectModule], instance, project);
                    }
                    break;
                }

                let isValid = await this.getIsValid(selectedModules, instance, project);
                if (!isValid) {
                    continue;
                }

                // create optimizers
                await this.createOptimizers(instance, selectedModules, project);
            }
        }
        finally {
            // cleanup
            instance.getHint().setMessage("");
            instance.highlight.clear();
            // remove previe objects
            instance.removeObjects({ id: previewObjects.map(o => o.id) });
            // Clean up the event listener
            window.removeEventListener('mousedown', listener);
        }
    }


    private async getIsValid(selectedModules: Solar.ObjectModule[], instance: SolarInstance, project: Solar.Project) {
        let isValid = true;

        for (const moduleObject of selectedModules) {
            if (moduleObject.optimizerId) {
                const obj = instance.getObjects({ id: moduleObject.optimizerId })[0];
                if (obj) {
                    // optimizer already attached
                    await this._dialogService.confirm({
                        title: "Error",
                        text: "This module already has optimizer",
                        hideCancel: true
                    });
                    isValid = false;
                    break;
                }
            }

            // verify if module connected to string
            const connection = this.getModuleConnection(moduleObject, project);
            if (!connection.inverter) {
                await this._dialogService.confirm({
                    title: "Error",
                    text: "This module has no connect with any inverter",
                    hideCancel: true
                });
                isValid = false;
                break;
            }
            if (!connection.inverter.optimizer) {
                await this._dialogService.confirm({
                    title: "Error",
                    text: "The inverter wich module connect has no optimizer",
                    hideCancel: true
                });
                isValid = false;
                break;
            }
        }
        return isValid;
    }

    /**
     * Creates preview objects based on the given parameters.
     * 
     * @param instance - The SolarInstance object.
     * @param selectedModules - An array of selected Solar.ObjectModule objects.
     * @param project - The Solar.Project object.
     * @returns An array of Solar.ObjectImage representing the preview objects.
     */
    private createPreviewObjects(
        instance: SolarInstance,
        selectedModules: Solar.ObjectModule[],
        project: Solar.Project) {

        const previewUrl = assetUrl("icons/arrow.png");

        const previewObjects: Solar.ObjectImage[] = [];

        const optimizersGroups = this.getOptimizersGroups(selectedModules, project);
        for (const group of optimizersGroups) {
            const lastModule = group.modules[group.modules.length - 1];
            const pos = lastModule.position;
            const rotation = lastModule.rotation;
            const previewObj: Solar.ObjectImage = {
                id: instance.getUniqueId(),
                type: "image",
                height: 0.5,
                width: 0.5,
                owner: "",
                position: { x: pos.x, y: pos.y, z: pos.z + 0.5 },
                rotation: { x: 0, y: 0, z: rotation.z },
                url: previewUrl
            };
            previewObjects.push(previewObj);

            // create on scene
            const obj3d = instance.createObject(previewObj);
            if (obj3d) {
                instance.scene.add(obj3d);
            }
        }
        return previewObjects;
    }

    /**
     * Creates optimizers based on the selected modules, instance, and project.
     * 
     * @param instance - The SolarInstance object.
     * @param selectedModules - An array of selected modules.
     * @param project - The Solar Project object.
     * @returns An array of newly created optimizers.
     */
    private async createOptimizers(
        instance: SolarInstance,
        selectedModules: Solar.ObjectModule[],
        project: Solar.Project) {

        const optimizersGroups = this.getOptimizersGroups(selectedModules, project);

        const newOptimizers: Solar.ObjectOptimizer[] = [];
        let error = "";
        // Iterate over each group
        for (const optGroup of optimizersGroups) {
            try {
                // verify matching                    
                this.verifyMatching(optGroup.optimizer, project, optGroup.modules.length);
            }
            catch (err) {
                error = err as string;
                break;
            }

            // create the optimizer
            const newOptimizer = this.createOptimizerObject(
                optGroup.modules.map(m => m.id || ""),
                optGroup.strId,
                optGroup.optimizer,
                instance
            )
            if (newOptimizer) {
                newOptimizers.push(newOptimizer);
            }
        }

        if (error) {
            // show an error
            await this._dialogService.confirm({
                title: "Error",
                text: error,
                hideCancel: true
            });
            // remove all optimizers
            instance.removeObjects({ id: newOptimizers.map(o => o.id) });
            return [];
        }


        return newOptimizers;
    }

    /**
     * Retrieves the optimizers groups based on the selected modules and project.
     * 
     * @param selectedModules - The array of selected modules.
     * @param project - The project object.
     * @returns An array of optimizers groups, each containing the string ID, optimizer, string object, and modules.
     */
    private getOptimizersGroups(selectedModules: Solar.ObjectModule[], project: Solar.Project) {

        const modulesGroup: { strId: string; optimizer: Solar.Optimizer; string: Solar.ObjectString, modules: Solar.ObjectModule[]; }[] = [];
        for (const moduleObject of selectedModules) {
            const connection = this.getModuleConnection(moduleObject, project);
            if (!connection.string || !connection.inverter?.optimizer) {
                continue;
            }
            const strId = connection.string?.id || "";
            const group = modulesGroup.find(g => g.strId == strId);
            if (group) {
                group.modules.push(moduleObject);
            }
            else {
                modulesGroup.push({
                    strId: strId,
                    optimizer: connection.inverter.optimizer,
                    string: connection.string,
                    modules: [moduleObject]
                });
            }
        }


        const optimizersGroups: { strId: string; optimizer: Solar.Optimizer; string: Solar.ObjectString, modules: Solar.ObjectModule[]; }[] = [];
        // Iterate over each group
        for (const group of modulesGroup) {
            // sort modules by string direction
            group.modules.sort((m1, m2) => {
                const idx1 = group.string.moduleIds.indexOf(m1.id);
                const idx2 = group.string.moduleIds.indexOf(m2.id);
                return idx1 - idx2;
            });

            // group modules by location and max number
            const maxModulesNumber = group.optimizer.maxNumberOfModules || 1;
            let currentGroup: Solar.ObjectModule[] = [];
            for (const module of group.modules) {

                let hasGap = false;
                let isAdded = false;

                if (currentGroup.length) {
                    const lastOpt = currentGroup[currentGroup.length - 1];
                    const lastIdx = group.string.moduleIds.indexOf(lastOpt.id);
                    const curIdx = group.string.moduleIds.indexOf(module.id);
                    if ((lastIdx + 1) != curIdx) {
                        // detected the gap
                        hasGap = true;
                    }
                    else {
                        // check the distance as well
                        const moduleDist = this._geometry.getDistance2d([lastOpt.position, module.position]);
                        const maxSize = Math.max(module.width, module.height);
                        if (moduleDist > (maxSize * 1.2)) {
                            hasGap = true;
                        }
                    }
                }

                if (!hasGap) {
                    currentGroup.push(module);
                    isAdded = true;
                }

                if (currentGroup.length >= maxModulesNumber || hasGap) {
                    optimizersGroups.push({
                        modules: currentGroup,
                        strId: group.strId,
                        optimizer: group.optimizer,
                        string: group.string
                    })
                    currentGroup = [];

                    if (!isAdded) {
                        // add handled module to the new group
                        currentGroup.push(module);
                    }
                }
            }

            if (currentGroup.length > 0) {
                optimizersGroups.push({
                    modules: currentGroup,
                    strId: group.strId,
                    optimizer: group.optimizer,
                    string: group.string
                });
                currentGroup = [];
            }
        }

        return optimizersGroups;
    }

    private getModuleConnection(moduleObject: Solar.ObjectModule, project: Solar.Project) {
        const flatTree = this._wiringService.getFlatTree(project.electrical.stringsConfig.items);
        const stringItems = flatTree.filter(i => i.object.type == "string") as Solar.StringConfigItem[];

        let inverter: Solar.ObjectInverter | null = null;
        let targetString: Solar.ObjectString | null = null;
        for (const strItem of stringItems) {
            const str = strItem.object as Solar.ObjectString;
            if (str.moduleIds.includes(moduleObject.id)) {
                // find inverter
                const invItem = this._wiringService.findParent(project.electrical.stringsConfig, strItem, "inverter");
                if (invItem && invItem.object) {
                    inverter = invItem.object as Solar.ObjectInverter;
                    targetString = str;
                    break;
                }
            }
        }
        return {
            inverter: inverter,
            string: targetString
        };
    }


    /**
     * Creates a single optimizer object in the scene.
     * 
     * @param modulesIds - An array of module IDs.
     * @param stringId - The string ID of the optimizer.
     * @param optimizer - The optimizer object.
     * @param instance - The instance of the SolarInstance class.
     */
    private createOptimizerObject(
        modulesIds: string[],
        stringId: string,
        optimizer: Solar.Optimizer,
        instance: SolarInstance) {

        const objects3d = instance.get3dObjects({ id: modulesIds });
        if (!objects3d || !objects3d.length) {
            return;
        }
        // sort objects by input ids to keep order
        objects3d.sort((o1, o2) => {
            const idx1 = modulesIds.indexOf((o1.userData as Solar.Object).id);
            const idx2 = modulesIds.indexOf((o2.userData as Solar.Object).id);
            return idx1 - idx2;
        })

        const stringObj = instance.getObjects({ id: stringId })[0] as Solar.ObjectString;
        if (!stringObj) {
            throw `The string not found`
        }

        const segment = instance.getObjects({ id: stringObj.owner, types: ["segment"] })[0] as Solar.ObjectRooftopSegment;
        if (!segment) {
            throw `The roof segment not found`
        }

        const transform = this.getTransform(segment);

        let pointsUcs: Solar.Point[] = [];

        const newOptimizerId = instance.getUniqueId();


        // get module parameters
        //
        const firstModule = objects3d[0].userData as Solar.ObjectModule;
        const width = firstModule.width;
        const height = firstModule.height;
        const optimizerHalfSize = 0.3;
        const rotation = firstModule.rotation;
        const vertOffset = (height / 2) - optimizerHalfSize;
        const horzOffset = (width / 2) - optimizerHalfSize;

        // get center points
        for (const obj3d of objects3d) {
            const moduleObject = obj3d.userData as Solar.ObjectModule;

            // assign optimizer id to the module
            moduleObject.optimizerId = newOptimizerId;

            const vec = new THREE.Vector3(moduleObject.position.x, moduleObject.position.y, moduleObject.position.z);
            vec.applyMatrix4(transform);

            // collect points
            pointsUcs.push({ x: vec.x, y: vec.y, z: vec.z });
        }

        // get strings points in UCS
        const stringPointsUcs: Solar.Point[] = [];
        for (const p of stringObj.points) {
            const vec = new THREE.Vector3(p.x, p.y, p.z);
            vec.applyMatrix4(transform);
            stringPointsUcs.push({ x: vec.x, y: vec.y, z: vec.z });
        }

        let bestSolution: Solar.Point[] = [];

        if (pointsUcs.length > 0) {
            let isSameY = true;
            let isSameX = true;
            const x = pointsUcs[0].x;
            const y = pointsUcs[0].y;
            if (pointsUcs.length == 1) {
                if (segment.orientation == "horz") {
                    isSameX = true;
                    isSameY = false;
                }
                else {
                    isSameX = false;
                    isSameY = true;
                }
            }
            else {
                for (const pnt of pointsUcs) {
                    const diffY = Math.abs(pnt.y - y);
                    if (diffY > 0.1) {
                        isSameY = false;
                    }
                    const diffX = Math.abs(pnt.x - x);
                    if (diffX > 0.1) {
                        isSameX = false;
                    }
                }
            }

            if (isSameY) {
                // check the horizontal intersection on the top position
                const yUp = pointsUcs[0].y + vertOffset;
                const testLineUp = [{ x: -10000, y: yUp, z: 0 }, { x: 10000, y: yUp, z: 0 }];

                if (!this.isIntersect(testLineUp, stringPointsUcs)) {
                    bestSolution = pointsUcs.map(p => ({ x: p.x, y: p.y + vertOffset, z: p.z }));
                }
                else {
                    bestSolution = pointsUcs.map(p => ({ x: p.x, y: p.y - vertOffset, z: p.z }));
                }
            }
            else if (isSameX) {
                // check the horizontal intersection on the top position
                const xRight = pointsUcs[0].x + horzOffset;
                const testLineRight = [{ x: xRight, y: -1000, z: 0 }, { x: xRight, y: 1000, z: 0 }];

                if (!this.isIntersect(testLineRight, stringPointsUcs)) {
                    bestSolution = pointsUcs.map(p => ({ x: p.x + horzOffset, y: p.y, z: p.z }));
                }
                else {
                    bestSolution = pointsUcs.map(p => ({ x: p.x - horzOffset, y: p.y, z: p.z }));
                }
            }
            else if (pointsUcs.length >= 3) {

                bestSolution = [];

                for (let idx = 1; idx < pointsUcs.length; idx++) {
                    const p1 = pointsUcs[idx - 1];
                    const p2 = pointsUcs[idx];

                    let isSameY = Math.abs(p1.y - p2.y) < 0.1;
                    let isSameX = Math.abs(p1.x - p2.x) < 0.1;

                    const lastPoint = bestSolution[bestSolution.length - 1];

                    if (isSameY) {
                        // check the horizontal intersection on the top position
                        const yUp = p1.y + vertOffset;
                        const xVec = p2.x - p1.x;
                        const testLineUp = [{ x: p1.x, y: yUp, z: 0 }, { x: p2.x + xVec, y: yUp, z: 0 }];

                        if (!this.isIntersect(testLineUp, stringPointsUcs)) {
                            if (lastPoint) {
                                lastPoint.y = p1.y + vertOffset;
                                bestSolution.push({ x: p2.x, y: p2.y + vertOffset, z: 0 });
                            }
                            else {
                                bestSolution.push({ x: p1.x, y: p1.y + vertOffset, z: 0 });
                                bestSolution.push({ x: p2.x, y: p2.y + vertOffset, z: 0 });
                            }
                        }
                        else {
                            if (lastPoint) {
                                lastPoint.y = p1.y - vertOffset;
                                bestSolution.push({ x: p2.x, y: p2.y - vertOffset, z: 0 });
                            }
                            else {
                                bestSolution.push({ x: p1.x, y: p1.y - vertOffset, z: 0 });
                                bestSolution.push({ x: p2.x, y: p2.y - vertOffset, z: 0 });
                            }
                        }
                    }
                    else if (isSameX) {
                        // check the horizontal intersection on the top position
                        const xRight = p1.x + horzOffset;
                        const testLineRight = [{ x: xRight, y: -1000, z: 0 }, { x: xRight, y: 1000, z: 0 }];

                        if (!this.isIntersect(testLineRight, stringPointsUcs)) {
                            if (lastPoint) {
                                lastPoint.x = p1.x + horzOffset;
                                bestSolution.push({ x: p2.x + horzOffset, y: p2.y, z: 0 });
                            }
                            else {
                                bestSolution.push({ x: p1.x + horzOffset, y: p1.y, z: 0 });
                                bestSolution.push({ x: p2.x + horzOffset, y: p2.y, z: 0 });
                            }
                        }
                        else {
                            if (lastPoint) {
                                lastPoint.x = p1.x - horzOffset;
                                bestSolution.push({ x: p2.x - horzOffset, y: p2.y, z: 0 });
                            }
                            else {
                                bestSolution.push({ x: p1.x - horzOffset, y: p1.y, z: 0 });
                                bestSolution.push({ x: p2.x - horzOffset, y: p2.y, z: 0 });
                            }
                        }
                    }
                    else {
                        bestSolution.push({ x: p1.x, y: p1.y, z: 0 });
                        console.error("not supported case");
                    }
                }
            }
            else {
                // turn case (L-shape)
                bestSolution = pointsUcs;
            }
        }

        // transform back to WCS
        //
        const wcsPoints: Solar.Point[] = [];
        const backTransform = transform.clone().invert();
        for (const p of bestSolution) {
            const vec = new THREE.Vector3(p.x, p.y, p.z);
            vec.applyMatrix4(backTransform);
            wcsPoints.push({ x: vec.x, y: vec.y, z: vec.z });
        }

        // update the z positions
        for (const pnt of wcsPoints) {
            const res = instance.surface.getElevationWithFilter(pnt.x, pnt.y, { types: ["segment", "roof"] });
            if (!res) {
                continue;
            }
            pnt.z = res.elevation;
        }

        // position of inverter should be at center of line
        //
        let position: Solar.Point | undefined = this.getCenterOf(wcsPoints);
        if (!position) {
            console.error("position not detected for optimizer");
            return;
        }
        const res = instance.surface.getElevationWithFilter(position.x, position.y, { types: ["segment", "roof"] });
        position.z = res.elevation;

        // Create 
        const newOptimizer = {
            id: newOptimizerId,
            type: "optimizer",
            owner: stringId,
            name: `${optimizer.manufacturer} ${optimizer.model}`,
            position: position,
            connectionPoints: wcsPoints,
            optimizer: optimizer,
            rotation: rotation
        } as Solar.ObjectOptimizer;

        const sceneObject = instance.createObject(newOptimizer);
        if (!sceneObject) {
            return;
        }

        instance.scene.add(sceneObject);

        return newOptimizer;
    }

    private getCenterOf(wcsPoints: Solar.Point[]) {
        let position: Solar.Point | undefined;
        if (wcsPoints.length > 1) {
            // get distance of lines
            let totalDist = this._geometry.getDistance2d(wcsPoints);
            let center = totalDist / 2;
            let curDist = 0;
            for (let idx = 1; idx < wcsPoints.length; idx++) {
                const p1 = wcsPoints[idx - 1];
                const p2 = wcsPoints[idx];
                const dist = this._geometry.getDistance2d([p1, p2]);
                if ((curDist + dist) >= center) {
                    const v1 = new THREE.Vector3(p1.x, p1.y, 0);
                    const v2 = new THREE.Vector3(p2.x, p2.y, 0);
                    const vec = v2.clone().sub(v1).normalize();
                    const offset = center - curDist;
                    vec.multiplyScalar(offset);
                    position = { x: p1.x + vec.x, y: p1.y + vec.y, z: 0 };
                    break;
                }
                else {
                    curDist += dist;
                }
            }
        }
        else {
            // use the first point
            position = { x: wcsPoints[0].x, y: wcsPoints[0].y, z: wcsPoints[0].z };
        }
        return position;
    }

    private getTransform(segment: Solar.ObjectRooftopSegment) {
        const azimuth = segment.azimuth || 0;
        const transform = new THREE.Matrix4();
        const angle = 180 - azimuth;
        const z = -angle / 180.0 * Math.PI;

        transform.makeRotationFromEuler(new THREE.Euler(0, 0, z));
        return transform;
    }

    /**
     * Checks if two lines intersect.
     * @param line1 - The first line represented as an array of points.
     * @param line2 - The second line represented as an array of points.
     * @returns A boolean indicating whether the two lines intersect.
     */
    private isIntersect(line1: Solar.Point[], line2: Solar.Point[]): boolean {
        for (let idx1 = 1; idx1 < line1.length; idx1++) {
            const p1 = line1[idx1 - 1];
            const p2 = line1[idx1];

            for (let idx2 = 1; idx2 < line2.length; idx2++) {
                const p3 = line2[idx2 - 1];
                const p4 = line2[idx2];

                if (this._geometry.lineIntersectsLine({ start: p1, end: p2 }, { start: p3, end: p4 })) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Verifies if the given optimizer is compatible with the project and module configuration.
     * Throws an error if any of the compatibility checks fail.
     * 
     * @param optimizer - The optimizer to verify.
     * @param project - The project containing the module configuration.
     * @param countOfModules - The number of modules used in the project.
     * @throws {string} - Throws an error message if the compatibility checks fail.
     */
    public verifyMatching(optimizer: Solar.Optimizer, project: Solar.Project, countOfModules: number) {

        if (!project.baseConfiguration || !project.baseConfiguration.module) {
            throw `Solar panel(s) are not available for the given project`
        }
        const module = project.baseConfiguration.module;

        if ((module.power * countOfModules) > optimizer.pmax) {
            throw `Module Pmax is greater than optimizer rated module power!`;
        }
        if ((module.voc * countOfModules) > optimizer.voc) {
            throw `Module Voc is greater than the optimizer maximum input voltage!`;
        }
        if ((module.vpmax * countOfModules) < optimizer.vmpMin ||
            (module.vpmax * countOfModules) > optimizer.vmpMax) {
            throw `Module Vmp is out of the optimizer MPPT operating voltage range!`;
        }
        if ((module.isc * countOfModules) >= optimizer.isc) {
            throw `Module Isc exceeds optimizer maximum short circuit current!`;
        }
        // If optional matching is not supported, the group string optimizer should be fully matched.
        if (!optimizer.optimalMatching) {
            throw "The optimizer does not support optional scenes!";
        }
    }

    public updateOptimizer(instance: SolarInstance, project: Solar.Project, optimizerId: string) {
        if (!optimizerId) {
            return;
        }
        // remove previous
        instance.removeObjects({ id: optimizerId });

        // get active modules
        const modules = instance.getObjects({ types: ["module"] }) as Solar.ObjectModule[];
        const optimizerModules = modules.filter(m => m.optimizerId == optimizerId);
        if (!optimizerModules.length) {
            return;
        }

        const connection = this.getModuleConnection(optimizerModules[0], project)
        if (!connection.inverter ||
            !connection.inverter.optimizer ||
            !connection.string) {
            return;
        }

        this.createOptimizerObject(
            optimizerModules.map(m => m.id || ""),
            connection.string.id,
            connection.inverter.optimizer,
            instance
        )
    }
}