<div class="component">
    <div class="total">
        {{"Total" | translate}} {{length}}
    </div>
    <div class="page-size">
        <mat-select [(ngModel)]="pageSize"
                    (ngModelChange)="onPageSizeChange()">
            <mat-option *ngFor="let size of pageSizeOptions"
                        [value]="size">
                {{size}}/{{"page" | translate}}
            </mat-option>
        </mat-select>
    </div>
    <div class="page-nav">
        <app-icon [src]="'icons/left-arrow.svg' | assetUrl"
                  (click)="prev()"></app-icon>
        <input class="input-base"
               type="number"
               [readOnly]="true"
               [(ngModel)]="pageNr">
        <app-icon [src]="'icons/right-arrow.svg' | assetUrl"
                  (click)="next()"></app-icon>
    </div>
    <div class="page-go">
        <div class="lbl">{{"go to" | translate}}</div>
        <input class="input-base"
               type="number"
               [(ngModel)]="goToPage"
               (keyup.enter)="goTo()">

        <div class="lbl">{{"page" | translate}}</div>
    </div>
</div>
