<div class="component">

    <div class="title">
        {{ "Shading" | translate}}
    </div>

    <div class="btns-wrapper">
        <div class="shadow-outline">
            <button mat-flat-button
                    color="primary"
                    [disabled]="isCalculating || !project.baseConfiguration.module"
                    (click)="toggleShadowOutline()">
                <div class="btn-content">
                    <app-icon [src]="'icons/shadows-outline.svg' | assetUrl"></app-icon>
                    <span *ngIf="!isOutlineVisible">
                        {{"Shadow outline" | translate}}
                    </span>
                    <span *ngIf="isOutlineVisible">
                        {{"Hide outline" | translate}}
                    </span>
                </div>
            </button>
        </div>

        <div>
            <button [disabled]="isCalculating || !project.baseConfiguration.module"
                    mat-flat-button
                    color="primary"
                    (click)="toggleAnimation()">
                <div class="btn-content">
                    <app-icon [src]="'icons/run-animation.svg' | assetUrl"></app-icon>
                    <span>{{ 'Shadow animation' | translate }}</span>
                </div>
            </button>
        </div>
    </div>

    <div *ngIf="isCalculating"
         class="progress-wrapper">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div class="time-settings"
         (click)="isTimeSettingsVisible = !isTimeSettingsVisible">
        <div>
            {{'Time setting' | translate}}: {{date | date:'dd/MM/YYYY'}}
            {{startTime.hr}}:{{startTime.min}}-{{endTime.hr}}:{{endTime.min}}
        </div>
        <div class="expander">
            <mat-icon inline="true"
                      color="primary"
                      *ngIf="isTimeSettingsVisible">
                expand_less
            </mat-icon>
            <mat-icon inline="true"
                      color="primary"
                      *ngIf="!isTimeSettingsVisible">
                expand_more
            </mat-icon>
        </div>
    </div>

    <div *ngIf="isTimeSettingsVisible">
        <div class="date-picker-wrapper">
            <mat-form-field>
                <mat-label>{{"Choose a date" | translate}}</mat-label>
                <input matInput
                       [matDatepicker]="picker"
                       [disabled]="isCalculating"
                       [(ngModel)]="date"
                       (ngModelChange)="dateChanged()">
                <mat-datepicker-toggle matSuffix
                                       [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="time-picker-wrapper">
            <app-time-picker [(time)]="startTime"
                             (timeChange)="startTimeChanged()"
                             [disabled]="isCalculating"></app-time-picker>
            <div class="divider">-</div>
            <app-time-picker [(time)]="endTime"
                             (timeChange)="endTimeChanged()"
                             [disabled]="isCalculating"></app-time-picker>
        </div>
    </div>

    <div *ngIf="hasIrradiance">
        <mat-checkbox [(ngModel)]="useLossesFormat"
                      (ngModelChange)="updateIrradiance()"
                      [disabled]="isAnimating || isCalculating">
            {{"Radiation loss caused by shadow" | translate}}
        </mat-checkbox>
    </div>
</div>
