import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-input-search',
    templateUrl: './input-search.component.html',
    styleUrls: ['./input-search.component.scss']
})
export class InputSearchComponent {

    @Input() text!: string;

    @Output() textChange = new EventEmitter<string>();

    @Input() placeholder = "Search";

    public onChange() {
        this.textChange.emit(this.text);
    }
}
