<div class="component">
    <div class="content">

        <h2 class="title">
            <span>{{"Select Battery" | translate}}</span>
        </h2>

        <mat-form-field>
            <mat-icon matPrefix
                      color="primary">search</mat-icon>
            <input matInput
                   [(ngModel)]="searchText"
                   (ngModelChange)="onSearch()"
                   [placeholder]="'Type to search battery' | translate">
        </mat-form-field>

        <div class="table-wrapper">

            <table mat-table
                   [dataSource]="batteries">
                <!-- manufacturer -->
                <ng-container matColumnDef="manufacturer">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Manufacturer" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let element"> {{element.manufacturer}} </td>
                </ng-container>

                <ng-container matColumnDef="model">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Model" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let element"> {{element.model}} </td>
                </ng-container>


                <ng-container matColumnDef="voltage">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Voltage" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let element"> {{element.voltage}}</td>
                </ng-container>

                <ng-container matColumnDef="dod">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"SOC (%)" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let element"> {{100 - element.dod}}</td>
                </ng-container>

                <ng-container matColumnDef="capacity">
                    <th mat-header-cell
                        *matHeaderCellDef>{{"Total Energy (kWh)" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let element"> {{element.capacity}}</td>
                </ng-container>

                <ng-container matColumnDef="copy">
                    <th mat-header-cell
                        *matHeaderCellDef> </th>
                    <td mat-cell
                        *matCellDef="let element">
                        <button mat-button
                                color="primary"
                                (click)="copy(element, $event)">
                            <mat-icon>content_copy</mat-icon>
                            {{"Copy" | translate}}
                        </button>
                        <button *ngIf="element.orgId != 'general'"
                                mat-button
                                color="primary"
                                (click)="edit(element, $event)">
                            <mat-icon>edit</mat-icon>
                            {{"Edit" | translate}}
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row
                    *matRowDef="let row; columns: displayedColumns;"
                    (click)="onSelect(row)"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row"
                    *matNoDataRow>
                    <td class="mat-cell"
                        *ngIf="!isLoading"
                        colspan="10">{{"No data matching" | translate}}</td>
                </tr>
            </table>

            <div class="loader-wrapper"
                 *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>
        </div>

        <mat-paginator #paginator
                       [length]="resultsLength"
                       [pageSize]="30"
                       [pageSizeOptions]="pageOptions"
                       (page)="loadPage()"></mat-paginator>
    </div>
</div>
