import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotifyService, Solar } from '@websolar/ng-websolar';
import { InverterPickerWindowComponent } from '../inverter-picker-window/inverter-picker-window.component';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-inverters-adding',
    templateUrl: './inverters-adding.component.html',
    styleUrls: ['./inverters-adding.component.scss']
})
export class InvertersAddingComponent {

    public selectedInverters: Solar.Inverter[] = [];

    private _voltLevel: AIKO.VoltageLevel

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            allocatedInverters?: Solar.Inverter[],
            voltLevel: AIKO.VoltageLevel
        },
        private _matDialog: MatDialog,
        private _notify: NotifyService,
        private _dialogRef: MatDialogRef<InvertersAddingComponent>
    ) {
        this._voltLevel = data.voltLevel;
        this.selectedInverters = data.allocatedInverters || [];
        //sort them by power
        this.selectedInverters.sort((i1, i2) => i2.maxPower - i1.maxPower);
    }


    public async add() {
        try {
            const dialogRef = this._matDialog.open(InverterPickerWindowComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    voltLevel: this._voltLevel
                }
            });
            const inv = await dialogRef.afterClosed().toPromise() as Solar.Inverter;
            if (!inv) {
                return;
            }
            if (this.selectedInverters.find(i => i._id == inv._id)) {
                this._notify.warn(`Inverter already added to the list`);
                return;
            }
            this.selectedInverters.push(inv);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public deleteInv(inv: Solar.Inverter) {
        const idx = this.selectedInverters.indexOf(inv);
        this.selectedInverters.splice(idx, 1);
    }

    public close() {
        this._dialogRef.close(null);
    }

    public confirm() {
        this._dialogRef.close(this.selectedInverters);
    }
}
