<div class="component">

    <div class="north"
         *ngIf="north">
        <img src="{{storageUrl}}/images/north-v.png"
             class="north">
    </div>
    <div class="map-legend">
        <div class="name">
            {{title}}
        </div>
        <div class="scale">
            {{scale}}
        </div>
    </div>

</div>
