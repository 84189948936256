<div class="component"
     [class.wide-second-panel]="largePanels.includes(mode)"
     [class.sm-full-height]="mode == 'Reports'"
     [class.full-second-panel]="fullWidthPanels.includes(mode)">
    <div class="side-pannel">
        <app-rooftop-sidebar-tree [mode]="mode"
                                  [project]="project"
                                  [instance]="instance"
                                  (itemActivated)="onMenuItemActivate($event)"
                                  [hideBack]="hideBack"
                                  (back)="onBack()"></app-rooftop-sidebar-tree>
    </div>
    <div #secondPanel
         class="second-panel"
         [ngSwitch]="mode">

        <ng-container *ngSwitchCase="'Design'">
            <app-roof-list [project]="project"
                           [instance]="instance"
                           [toolbarControl]="toolbarControl"></app-roof-list>
        </ng-container>

        <ng-container *ngSwitchCase="'Obstacles'">
            <app-keepout-list [project]="project"
                              [instance]="instance"
                              [toolbarControl]="toolbarControl"></app-keepout-list>
        </ng-container>

        <ng-container *ngSwitchCase="'Trees'">
            <app-tree-list [project]="project"
                           [instance]="instance"
                           [toolbarControl]="toolbarControl"></app-tree-list>
        </ng-container>

        <ng-container *ngSwitchCase="'Array'">
            <app-segments-list [project]="project"
                               [instance]="instance"
                               (toolActivated)="activateTool($event)"></app-segments-list>
        </ng-container>

        <ng-container *ngSwitchCase="'Inverters'">
            <app-inverters-list [project]="project"
                                [instance]="instance"
                                (toolActivated)="activateTool($event)"></app-inverters-list>
        </ng-container>

        <ng-container *ngSwitchCase="'Mounting'">
            <app-mounting-panel [project]="project"
                                [instance]="instance"></app-mounting-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'Consumption'">
            <app-consumption-panel [instance]="instance"
                                   [project]="project"></app-consumption-panel>
        </ng-container>


        <ng-container *ngSwitchCase="'Reports'">
            <app-reports-panel [project]="project"
                               [instance]="instance"></app-reports-panel>
        </ng-container>


        <ng-container *ngSwitchCase="'MainServicePanel'">
            <app-main-service-panel-list [instance]="instance"
                                         [project]="project"
                                         (toolActivated)="activateTool($event)"></app-main-service-panel-list>
        </ng-container>

        <ng-container *ngSwitchCase="'ElectricalConfig'">
            <app-main-service-panel-config [instance]="instance"
                                           [project]="project"></app-main-service-panel-config>
        </ng-container>

        <ng-container *ngSwitchCase="'Meters'">
            <app-meters-list [instance]="instance"
                             (toolActivated)="activateTool($event)"></app-meters-list>
        </ng-container>

        <ng-container *ngSwitchCase="'Battery'">
            <app-battery-panel [project]="project"
                               [instance]="instance"></app-battery-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'Finance'">
            <div class="finance-wrapper">
                <app-report-finance [project]="project"
                                    [instance]="instance"></app-report-finance>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="'Permit'">
            <app-permit-panel [project]="project"
                              [instance]="instance"></app-permit-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'Sharing'">
            <app-share-panel [project]="project"></app-share-panel>
        </ng-container>

        <ng-container *ngSwitchCase="'Settings'">
            <app-settings-pannel [project]="project"></app-settings-pannel>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <websolar-rooftop-sidebar-content [instance]="instance"
                                              [project]="project"
                                              [mode]="mode | castAny"></websolar-rooftop-sidebar-content>
        </ng-container>


    </div>
</div>
