import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';
import { MapProjector } from 'src/app/core/map.projector';
import { ProjectHelper } from 'src/app/core/project.helper';
import { AMapMapService } from 'src/app/services/amap.map.service';
import { PolygonCoords } from "amap-jsapi-v2-types/types/common/Geometry";

@Component({
    selector: 'app-amap',
    templateUrl: './amap.component.html',
    styleUrls: ['./amap.component.scss']
})
export class AmapComponent implements AfterViewInit, OnChanges {

    @ViewChild("mapElement") _mapElement!: ElementRef<HTMLElement>;

    /**
     * The geographical location data. This is an input property and it's required.
     */
    @Input() location!: Solar.GeoLocation;

    /**
     * The size of the project. This is an input property and it's required.
     */
    @Input() projectSize!: Solar.ProjectSize;

    /**
     * Emits an event when the location changes.
     * @event locationChange
     * @type {EventEmitter<Solar.GeoLocation>}
     */
    @Output() locationChange = new EventEmitter<Solar.GeoLocation>();

    private _map: AMap.Map | undefined;

    private _marker: AMap.Marker | undefined;

    private _rectangle: AMap.Polygon | undefined;

    constructor(
        private _mapService: AMapMapService
    ) { }

    public ngAfterViewInit() {
        this.createMapSafe();
    }

    public ngOnChanges(): void {
        if (this.location) {
            this.updateLocationMarker();
        }
    }

    /**
     * Updates the location marker on the map.
     * If a marker already exists, it is deleted and a new marker is created at the updated location.
     * The map is centered on the updated location and zoom level is set based on the project size.
     * The marker's dragend event is handled to update the location coordinates and redraw the boundary.
     * 
     * @private
     * @memberof AmapComponent
     */
    private updateLocationMarker(zoomToMarker: boolean = true) {

        if (this._marker) {
            // delete previous marker
            this._marker.setMap(null);
        }

        if (!this.location) {
            return;
        }

        if (!this._map) {
            return;
        }

        const pos = new AMap.LngLat(this.location.lng, this.location.lat);
        if (zoomToMarker) {
            this._map.setCenter(pos);
            this._map.setZoom(ProjectHelper.getZoomLevel(this.projectSize));
        }

        this._marker = this.createMarker(pos);

        this.drawBoundary();
    }


    private onDblClick(evt: AMap.MapsEvent) {
        if (!this._map || !evt.lnglat) {
            return;
        }
        const center = evt.lnglat;

        if (!this._marker) {
            this._marker = this.createMarker(center);
        }

        this._marker.setPosition(center);

        this.location.lat = center?.getLat() || 0;
        this.location.lng = center?.getLng() || 0;

        this.drawBoundary();

        this.locationChange.emit(this.location);
    }

    private createMarker(center: AMap.LngLat) {
        return new AMap.Marker({
            position: center,
            map: this._map,
            title: "Location",
            draggable: true
        });
    }

    /**
     * Creates the map safely by waiting for Amap initialization.
     * @returns {Promise<void>} A promise that resolves when the map is created.
     */
    private async createMapSafe() {
        await this._mapService.waitAMapInitialization();
        this.createMap();
    }



    private createMap() {
        // When the Map instance is initialized, if the center is not set,
        // the API will automatically match the map center point to the center
        // of the user's city based on the user's IP.
        // https://lbs.amap.com/demo/javascript-api-v2/example/location/map-is-initially-loaded-into-the-current-city
        this._map = new AMap.Map(this._mapElement.nativeElement, {
            layers: [new AMap.TileLayer.Satellite()],
            doubleClickZoom: false,
            zoom: 17
        });
        AMap.plugin(
            [
                "AMap.ToolBar",
                "AMap.Scale"
            ],
            () => {
                if (this._map) {
                    // Add a toolbar control, which integrates a combination of zoom, pan, position and other function buttons
                    this._map.addControl(new AMap.ToolBar());

                    // Add a scale bar control to display the scale of the map at the current level and latitude
                    this._map.addControl(new AMap.Scale());
                }
            }
        );
        this._map.on("dblclick", this.onDblClick.bind(this))
        this._map.on("complete", this.onMapLoaded.bind(this))
    }

    /**
     * Callback function called when the map is loaded.
     */
    private onMapLoaded() {
        if (!this._map) {
            return;
        }
        if (this.location &&
            this.location.lat == 0 &&
            this.location.lng == 0) {
            // when location is not available then pick the map center
            const mapCenter = this._map.getCenter();
            this.location.lat = mapCenter.getLat();
            this.location.lng = mapCenter.getLng();
            this.updateLocationMarker(false);
        }
    }

    /**
     * Draws a boundary on the map based on the current location.
     * The boundary is represented as a rectangle with dashed lines.
     * @returns {void}
     */
    private drawBoundary() {
        try {
            if (!this.location ||
                typeof this.location.lat != "number" ||
                typeof this.location.lng != "number") {
                return;
            }
            if (!this._map) {
                return;
            }
            // remove previous
            if (this._rectangle) {
                this._rectangle.setMap(null);
            }

            const zoomLevel = ProjectHelper.getZoomLevel(this.projectSize);
            const imgSize = 640;

            const mapProjector = new MapProjector();
            const boxSize = mapProjector.getSizeInMeters(zoomLevel, this.location, imgSize);
            const halfBox = boxSize;
            const len = Math.sqrt((halfBox * halfBox) + (halfBox * halfBox));

            const center = new AMap.LngLat(this.location.lng, this.location.lat);

            const sw = mapProjector.getSphericalOffset(this.location, 45, -len / 2);
            const ne = mapProjector.getSphericalOffset(this.location, 45, len / 2);


            const path = [
                [sw.lng, sw.lat],
                [ne.lng, sw.lat],
                [ne.lng, ne.lat],
                [sw.lng, ne.lat]
            ] as PolygonCoords;

            this._rectangle = new AMap.Polygon({
                path: path,
                fillColor: '#FFFFFF',
                strokeOpacity: 1,
                fillOpacity: 0.1,
                strokeColor: '#FFFFFF',
                strokeWeight: 1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5],
            });
            this._map.add(this._rectangle);
        }
        catch (err) {
            console.error("Failed draw boundary", err);
        }
    }



}
