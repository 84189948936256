import { Solar, WebSolarTextBuilder } from "@websolar/ng-websolar";
import * as THREE from "three";

export class StringFactory implements Solar.CustomObjectFactory {

    public getType(): Solar.ObjectType {
        return "string";
    }

    public create(obj: Solar.Object, units?: Solar.Units): THREE.Object3D | null {
        if (!obj || obj.type != "string") {
            return null;
        }
        return this.createString(obj as Solar.ObjectString)
    }

    private createString(wireString: Solar.ObjectString) {

        const group = new THREE.Group();
        // create line
        //
        const color = wireString.color || "#A300F9";
        const line = this.createLine(wireString.points, color);
        group.add(line);

        // create polarities
        const start = wireString.points[0];
        group.add(this.createPolarity(start, "#4ba5f0", wireString.polarityAngle, "1"));
        const end = wireString.points[wireString.points.length - 1];
        group.add(this.createPolarity(end, "#ff0000", wireString.polarityAngle, `${wireString.moduleIds.length}`));

        if (wireString.number && wireString.points.length >= 2) {
            // add text at middle of string

            const midIdx = Math.max(Math.floor(wireString.points.length / 2) - 1, 0);
            const midStart = wireString.points[midIdx];
            const midEnd = wireString.points[midIdx + 1];

            const middle = { x: (midStart.x + midEnd.x) / 2, y: (midStart.y + midEnd.y) / 2, z: Math.max(midStart.z, midEnd.z) } as Solar.Point;

            // find middel points

            const text = WebSolarTextBuilder.createText(`#${wireString.number}`, "#ffffff", 0.5);
            text.position.set(middle.x, middle.y, middle.z);
            text.rotation.set(0, 0, wireString.polarityAngle);
            group.add(text);
        }

        // update the group data
        //
        group.userData = wireString;

        return group;
    }

    private createPolarity(start: Solar.Point, color: string, rotation: number, text: string) {
        const size = 0.3;

        // create a group
        const group = new THREE.Group();
        group.position.set(start.x, start.y, start.z + 0.01);

        const sphere = new THREE.CircleGeometry(size);
        const material = new THREE.MeshBasicMaterial({ color: color, side: THREE.DoubleSide, transparent: false, opacity: 1 });
        const mesh = new THREE.Mesh(sphere, material);
        group.add(mesh);


        // add text
        const textObj = WebSolarTextBuilder.createText(`${text}`, "#ffffff", 0.5);
        textObj.position.set(0, 0, 0.01);
        textObj.rotation.set(0, 0, rotation);
        group.add(textObj);

        return group;
    }

    private createLine(source: Solar.Point[], lineColor: string) {
        const points: THREE.Vector3[] = [];
        for (const p of source) {
            points.push(new THREE.Vector3(p.x, p.y, p.z));
        }

        const geometry = new THREE.BufferGeometry().setFromPoints(points);


        const material = new THREE.LineBasicMaterial({ color: lineColor, side: THREE.DoubleSide });
        const line = new THREE.Line(geometry, material);

        line.geometry = geometry;
        line.geometry.computeBoundingBox();
        line.geometry.computeBoundingSphere();
        return line;
    }


}