import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { AIKO } from "../types/aiko.types";

@Injectable({
    providedIn: 'root'
})
export class WindZoneService {
    constructor(
        private _http: HttpService
    ) { }

    public async find(search: {
        id?: string | string[],
        location?: { lat: number, lng: number },
        search?: string,
        skip?: number,
        limit?: number
    }): Promise<AIKO.WindZone[]> {
        return await this._http.post(`/windZone/find`, search);
    }

    public async count(search: {
        search?: string
    }): Promise<number> {
        const res = await this._http.post(`/windZone/count`, search) as { count: number };
        return res.count;
    }

    public async save(item: AIKO.WindZone) {
        const res = await this._http.post(`/windZone/save`, item) as { id: string };
        return res.id;
    }

    public async delete(options: { id?: string }) {
        return await this._http.post(`/windZone/delete`, options);
    }
}