import { Component, Input } from '@angular/core';
import { WebSolarConfiguration } from '@websolar/ng-websolar';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-safety-signs',
    templateUrl: './permit-safety-signs.component.html',
    styleUrls: ['./permit-safety-signs.component.scss']
})
export class PermitSafetySignsComponent {

    @Input() permitScope!: AIKO.PermitScope;

    public cloudStorageUrl = WebSolarConfiguration.storageUrl;

}
