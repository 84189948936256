import { Injectable } from "@angular/core";
import { Solar, WebSolarConfiguration } from "@websolar/ng-websolar";
import { environment } from "src/environments/environment";
import { AIKO } from "../types/aiko.types";
import { MountingService } from "./mounting.service";
import { RailService } from "./rail.service";
import { HookService } from "./hook.service";
import { MaterialService } from "./material.service";

@Injectable()
export class BomService {

    constructor(
        private _mountingService: MountingService,
        private _railService: RailService,
        private _hookService: HookService,
        private _materialService: MaterialService
    ) { }

    /**
     * Retrieves the Bill of Materials (BOM) based on the provided mounting system and objects.
     */
    public async getBOM(project: Solar.Project, objects: Solar.Object[]): Promise<AIKO.BOMRecord[]> {
        const mounting = project.mountingSystem;
        const output: AIKO.BOMRecord[] = [];

        const segments = objects.filter(s => s.type == "segment") as Solar.ObjectRooftopSegment[];

        const segment = segments.find(s => s.module);
        if (!segment || !segment.module) {
            return [];
        }

        const modules = objects.filter(s => s.type == "module");


        output.push({
            name: "Solar Panel",
            image: `${WebSolarConfiguration.storageUrl}/mounting/AIKO/kit/solar-panel.png`,
            quantity: modules.length,
            model: segment.module.model,
            price: 0,
            total: 0,
            quantityUnit: "",
            totalUnit: ""
        })

        const inverters = objects.filter(s => s.type == "inverter") as Solar.ObjectInverter[];
        const uniqueModels: string[] = [];
        for (const invObj of inverters) {
            if (!uniqueModels.includes(invObj.inverter.model)) {
                uniqueModels.push(invObj.inverter.model)

                output.push({
                    name: "Inverter",
                    image: `${WebSolarConfiguration.storageUrl}/mounting/AIKO/kit/inverter.png`,
                    quantity: inverters.filter(i => i.inverter.model == invObj.inverter.model).length,
                    model: invObj.inverter.model,
                    price: 0,
                    total: 0,
                    quantityUnit: "",
                    totalUnit: ""
                })
            }
        }

        const mainServicePanels = objects.filter(s => s.type == "main_service_panel").length;
        if (mainServicePanels) {
            output.push({
                name: "Main Service Panel",
                image: "",
                quantity: mainServicePanels,
                model: "",
                price: 0,
                total: 0,
                quantityUnit: "",
                totalUnit: ""
            })
        }

        if (mounting.mountingSystemId) {

            const mountingSystems = await this._mountingService.find({ id: mounting.mountingSystemId });
            const mountingSystem = mountingSystems[0];

            if (mountingSystem) {
                const rails = await this._railService.find({ id: mountingSystem.railId });
                const rail = rails[0];

                if (rail) {
                    output.push({
                        name: "Rail",
                        image: rail.image || "",
                        quantity: this.getRailQuantity(rail, segments),
                        model: rail.name,
                        price: 0,
                        total: 0,
                        quantityUnit: "",
                        totalUnit: ""
                    })
                }



                const hooks = await this._hookService.find({ id: mountingSystem.hookId });
                const hook = hooks[0];
                if (hook) {
                    output.push({
                        name: "Hook",
                        image: hook.image || "",
                        quantity: this.getHookQuantity(segments),
                        model: hook.name,
                        price: 0,
                        total: 0,
                        quantityUnit: "",
                        totalUnit: ""
                    })
                }
            }
        }

        if (project.finance && project.finance.userDefinedRecords) {
            // add custom items
            for (const item of project.finance.userDefinedRecords) {
                const exist = output.find(i => i.name.toLowerCase() == item.name.toLowerCase());
                if (exist) {
                    exist.price = item.price;
                }
                else {
                    output.push(item as AIKO.BOMRecord);
                }
            }

            // calculate total
            for (const record of output) {
                record.price = record.price || 0;
                record.total = (record.price || 0) * (record.quantity || 0);
            }
        }

        return output;
    }

    /**
     * Retrieves the Bill of Materials (BOM) for a mounting system.
     *
     * @param mounting The mounting system.
     * @param segments The rooftop segments.
     * @returns An array of MountingBOMRecord objects representing the BOM.
     */
    public async getMountingSystemBOM(mounting: Solar.MountingSystem, segments: Solar.ObjectRooftopSegment[]): Promise<AIKO.MountingBOMRecord[]> {
        const output: AIKO.MountingBOMRecord[] = [];

        if (!mounting.mountingSystemId) {
            // the mounting is not defined, return the empty BOM
            return output;
        }

        if (environment.enableTraceLogs) {
            // print the debug information
            this.printInformation(mounting, segments);
        }

        const mountingSystems = await this._mountingService.find({ id: mounting.mountingSystemId });
        const mountingSystem = mountingSystems[0];
        if (!mountingSystem ||
            !mountingSystem.railId ||
            !mountingSystem.hookId) {
            // the mounting is not defined, return the empty BOM
            return output;
        }

        const rails = await this._railService.find({ id: mountingSystem.railId });
        const rail = rails[0];

        const hooks = await this._hookService.find({ id: mountingSystem.hookId });
        const hook = hooks[0];

        if (!hook || !rail) {
            return output;
        }


        // add rail and hook to top
        if (rail) {
            output.push({
                device: rail.name || "",
                code: rail.code,
                image: rail.image || "",
                weight: rail.weight || 0,
                quantity: this.getRailQuantity(rail, segments),
                totalWeight: 0
            })
        }

        if (hook) {
            output.push({
                device: hook.name || "",
                code: hook.code,
                image: hook.image || "",
                weight: hook.weight || 0,
                quantity: this.getHookQuantity(segments),
                totalWeight: 0
            })
        }


        const materials = await this._materialService.find({ id: mountingSystem.materialsIds })
        for (const material of materials) {

            let quantity = 0;
            switch (material.category) {
                case "rail connector":
                    quantity = this.getRailConnectorQuantity(segments);
                    break;
                case "screw":
                    quantity = this.getHookQuantity(segments) * 2;
                    break;
                case "mid clamp":
                    quantity = this.getMidClampsQuantity(segments);
                    break;
                case "end clamp":
                    quantity = this.getEndClampsQuantity(segments);
                    break;
                case "grounding lug":
                    quantity = this.getGroundingLugQuantity(segments);
                    break;
                case "grounding clip":
                    // 1 for every two mid clamps
                    quantity = Math.floor(this.getMidClampsQuantity(segments) / 2);
                    break;
                case "cable clip":
                    quantity = this.getCableClip(segments);
                    break;
                case "rail endcap":
                    quantity = this.getRailCover(segments);
                    break;
            }

            output.push({
                code: material.code || "",
                device: material.name || "",
                image: material.image || "",
                quantity: quantity,
                totalWeight: 0,
                weight: material.weight || 0
            })
        }

        for (const item of output) {
            item.totalWeight = Math.round(item.weight * item.quantity * 100) / 100;
        }

        return output;
    }


    /**
     * Calculates the total number of rail covers needed based on the given rooftop segments.
     * Each rail requires 2 rail covers.
     *
     * @param segments - An array of rooftop segments.
     * @returns The total number of rail covers needed.
     */
    private getRailCover(segments: Solar.ObjectRooftopSegment[]) {
        // 2 for each rail
        let total = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }

            for (const rail of segment.mountingSystem.railsInfo) {
                total += 2;
            }
        }

        return total;
    }

    /**
     * Calculates the total number of cable clips needed based on the segments of the rooftop.
     * @param segments - An array of rooftop segments.
     * @returns The total number of cable clips needed.
     */
    private getCableClip(segments: Solar.ObjectRooftopSegment[]) {
        // 1 for each module
        let total = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }

            for (const rail of segment.mountingSystem.railsInfo) {
                if (rail.type == "first") {
                    total += rail.modules;
                }
            }
        }

        return total;
    }

    /**
     * Calculates the total quantity of grounding lugs needed based on the given rooftop segments.
     * Each segment may have a mounting system with multiple rails.
     *
     * @param segments - An array of rooftop segments.
     * @returns The total quantity of grounding lugs needed.
     */
    private getGroundingLugQuantity(segments: Solar.ObjectRooftopSegment[]) {
        // 2 for each array

        let total = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }

            for (const rail of segment.mountingSystem.railsInfo) {
                // 2 for each array or 1 for each rail
                total += 1;
            }
        }

        return total;
    }

    /**
     * Calculates the quantity of end clamps needed for the given rooftop segments.
     *
     * @param segments - The array of rooftop segments.
     * @returns The total quantity of end clamps.
     */
    private getEndClampsQuantity(segments: Solar.ObjectRooftopSegment[]) {
        let total = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }

            for (const rail of segment.mountingSystem.railsInfo) {
                // 4 for each array or 2 for each rail
                total += 2;
            }
        }

        return total;
    }

    /**
     * Calculates the quantity of mid clamps needed for the given rooftop segments.
     *
     * @param segments - The array of rooftop segments.
     * @returns The total quantity of mid clamps needed.
     */
    private getMidClampsQuantity(segments: Solar.ObjectRooftopSegment[]) {
        let modules = 0;

        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }

            for (const rail of segment.mountingSystem.railsInfo) {
                modules += Math.max((rail.modules || 0) - 1, 0);
            }
        }

        return modules;
    }

    /**
     * Calculates the total quantity of hooks needed for the given rooftop segments.
     * @param segments - An array of rooftop segments.
     * @returns The total quantity of hooks.
     */
    private getHookQuantity(segments: Solar.ObjectRooftopSegment[]) {
        let total = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }

            for (const rail of segment.mountingSystem.railsInfo) {
                total += rail.attachments || 0;
            }
        }

        return total;
    }

    /**
     * Calculates the quantity of rail connectors needed based on the mounting system and rooftop segments.
     *
     * @param segments The rooftop segments.
     * @returns The total quantity of rail connectors needed.
     */
    private getRailConnectorQuantity(segments: Solar.ObjectRooftopSegment[]) {
        let total = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }
            const connector = this.getRailConnectorsForSegment(segment);
            total += connector;
        }

        return total;
    }

    /**
     * Calculates the number of rail connectors needed for a given rooftop segment.
     *
     * @param segment - The rooftop segment for which to calculate the rail connectors.
     * @returns The number of rail connectors needed.
     */
    private getRailConnectorsForSegment(segment: Solar.ObjectRooftopSegment): number {
        if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
            return 0;
        }

        let connector = 0;

        for (const rail of segment.mountingSystem.railsInfo) {
            let count = Math.ceil(rail.length / 2.4) - 1;
            if (count > 0) {
                connector += count;
            }
        }

        return connector;
    }

    /**
     * Calculates the quantity of rails needed based on the total length of connected rails.
     *
     * @param segments - An array of rooftop segments.
     * @returns The quantity of rails needed.
     */
    private getRailQuantity(rail: AIKO.Rail, segments: Solar.ObjectRooftopSegment[]): number {
        let totalMeters = 0;
        for (const segment of segments) {
            if (!segment.mountingSystem || !segment.mountingSystem.railsInfo) {
                continue;
            }
            for (const rail of segment.mountingSystem.railsInfo) {
                if (rail.length) {
                    totalMeters += rail.length;
                }
            }
        }

        if (totalMeters == 0) {
            return 0;
        }

        // get mm
        const totalMM = Math.round(totalMeters * 1000)

        // rail quantity=the theory  length of the connected rails/rail length
        const railSliceLen = (rail.length || 1) * 1000;
        return Math.ceil(totalMM / railSliceLen);
    }

    private printInformation(mounting: Solar.MountingSystem, segments: Solar.ObjectRooftopSegment[]) {
        try {
            let totalLen = 0;

            for (const segment of segments) {
                if (!segment.module || !segment.mountingSystem) {
                    continue;
                }
                console.log(`==== Segment "${segment.name}" =====`)

                console.log(`Solar panel size: ${Math.round(segment.module.width * 1000)}mm x ${Math.round(segment.module.height * 1000)}mm`)
                console.log(`Gap between panels: ${Math.round(segment.moduleSpacing * 1000)}mm`);
                console.log(`Rail connectors: ${this.getRailConnectorsForSegment(segment)}`);

                if (!segment.mountingSystem.railsInfo) {
                    console.warn(`mounting information is not available for the specific segment`)
                    continue;
                }


                for (const rail of segment.mountingSystem.railsInfo) {
                    const idx = segment.mountingSystem.railsInfo.indexOf(rail);
                    const lenInMM = Math.round(rail.length * 1000);
                    console.log(`Rail #${idx + 1} Rail length: ${lenInMM}mm  Number of panels: ${rail.modules} Number of hooks: ${rail.attachments}`)
                    totalLen += lenInMM;
                }
            }

            console.log(`=============`)
            console.log(`The total length is ${totalLen}mm Quantity is ${Math.ceil(totalLen / 2400)}`)
        }
        catch (err) {
            console.error(`[BomService] Failed print the debug information`)
        }
    }
}
