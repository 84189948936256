import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Solar, NotifyService, WebSolarZoneMapComponent, WebSolarTerrainCategoryHelpComponent } from '@websolar/ng-websolar';
import { Observable } from 'rxjs';
import { AnnexService } from 'src/app/services/annex.service';
import { SnowZoneService } from 'src/app/services/snowZones.service';
import { TerrainCategoryService } from 'src/app/services/terrainCategory.service';
import { WindZoneService } from 'src/app/services/windZones.service';
import { AIKO } from 'src/app/types/aiko.types';
import { ZoneMapComponent } from '../zone-map/zone-map.component';
import { EUTerrainCategoryHelpComponent } from '../eu-terrain-category-help/eu-terrain-category-help.component';

@Component({
    selector: 'app-mounting-europe',
    templateUrl: './mounting-europe.component.html',
    styleUrls: ['./mounting-europe.component.scss']
})
export class MountingEuropeComponent implements OnChanges {

    /**
     * The project input for the MountingEuropeComponent.
     */
    @Input() project!: Solar.Project;

    /**
     * The segment input for the MountingEuropeComponent.
     */
    @Input() segment!: Solar.ObjectRooftopSegment;

    /**
     * The events input property.
     * It is an Observable that emits objects with a name and params property.
     */
    @Input() events!: Observable<{ name: string, params: unknown }>;

    /**
     * The calculation result
     */
    @Input() calcRes: Solar.BaseMountingCalculation | null = null;

    /**
     * Event emitter for the onChange event.
     */
    @Output() onChange = new EventEmitter<void>();

    /**
     * Indicates whether the calculation is in progress.
     */
    @Input() isCaclulating = false;

    /**
     * Represents the terrain categories for mounting in Europe.
     * Each category has an ID and a name.
     */
    public terrainCategories: AIKO.TerrainCategory[] = [];

    /**
     * Indicates whether the snow zones are visible.
     */
    public snowZonesVisible = true;

    /**
     * Indicates whether the wind zones are visible.
     */
    public windZonesVisible = true;

    public windZones: string[] = [];

    public snowZones: string[] = [];

    constructor(
        private _matDialog: MatDialog,
        private _notify: NotifyService,
        private _terrainService: TerrainCategoryService,
        private _annexService: AnnexService
    ) { }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["project"]) {
            if (this.project) {
                this.init();
            }
        }
    }

    /**
     * Initializes the component.
     * This method populates the windZones, snowZones, and terrainCategories arrays based on the project data.
     */
    private async init() {
        try {
            this.windZones = [];
            this.snowZones = [];

            this.windZonesVisible = false;
            this.snowZonesVisible = false;


            if (this.project.country) {
                const annex = await this._annexService.findOne({ country: this.project.country });
                if (annex && annex.enabled) {
                    this.terrainCategories = await this._terrainService.find({
                        id: annex.terrainsIds
                    });

                    if (this.project.mountingSystem.snowZone) {
                        this.snowZones.push(this.project.mountingSystem.snowZone);
                    }

                    if (this.project.mountingSystem.windZone) {
                        this.windZones.push(this.project.mountingSystem.windZone);
                    }

                    this.windZonesVisible = true;
                    this.snowZonesVisible = true;
                }
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Event handler for when the options change.
     * Emits the change event.
     */
    public onOptionsChange() {
        this.onChange.emit();
    }

    /**
     * Shows the wind map dialog.
     * 
     * @param evt - The mouse event that triggered the function.
     * @returns A promise that resolves when the dialog is closed.
     */
    public async showWindMap(evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(ZoneMapComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    project: this.project,
                    type: "wind"
                }
            });
            await dialogRef.afterClosed().toPromise()
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Shows the snow map dialog.
     * 
     * @param evt - The mouse event that triggered the function.
     * @returns A promise that resolves when the dialog is closed.
     */
    public async showSnowMap(evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(ZoneMapComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    project: this.project,
                    type: "snow"
                }
            });
            await dialogRef.afterClosed().toPromise()
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Shows the terrain help dialog.
     * 
     * @param evt - The mouse event that triggered the function.
     * @returns A promise that resolves when the dialog is closed.
     */
    public async showTerrainHelp(evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(EUTerrainCategoryHelpComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    categories: this.terrainCategories
                }
            });
            await dialogRef.afterClosed().toPromise()
        }
        catch (err) {
            this._notify.error(err);
        }
    }



}
