import { Component, Input, OnInit } from '@angular/core';
import { WebSolarConfiguration } from '@websolar/ng-websolar';

@Component({
    selector: 'app-permit-scale',
    templateUrl: './permit-scale.component.html',
    styleUrls: ['./permit-scale.component.scss']
})
export class PermitScaleComponent {

    @Input() title = "";

    @Input() scale = "";

    @Input() north = false;

    public storageUrl = WebSolarConfiguration.storageUrl;

}
