import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundNumber'
})
export class RoundPipe implements PipeTransform {

    transform(value: unknown): unknown {
        if (typeof value != "number") {
            return ;
        }

        return Math.round(value);
    }

}
