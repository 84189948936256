import { Component, Input, OnInit } from '@angular/core';
import { assetUrl } from "../../pipes/asset.url.pipe";

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent  {

    @Input() logo = assetUrl('/icons/aiko.png');

    constructor() { }


}
