import * as THREE from "three";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Solar, SolarInstance, WebSolarGeometryService } from "@websolar/ng-websolar";
import { DialogService } from "./dialog.service";


@Injectable()
export class ArrayService {
    constructor(
        private _translate: TranslateService,
        private _geomService: WebSolarGeometryService,
        private _dialogService: DialogService
    ) { }

    /**
     * Runs the autoplacement algorithm for arrays on the given roof.
     * @param roof - The roof object.
     * @param project - The project object.
     * @param instance - The SolarInstance object.
     */
    public runAutoplacement(roof: Solar.ObjectRoof, project: Solar.Project, instance: SolarInstance) {
        // remove all arrays
        this.removeAllArrays(roof.id, instance);

        const roofTransform = this._geomService.getObjectTransform(roof);

        // add invisible arrays to roof
        const roofParts = roof.roofParts || [];
        for (const roofPart of roofParts) {
            if (roofPart.type == "wall") {
                continue;
            }
            const newArr = this.getNewArray(instance, project, roof.id, "auto_array");
            const pnts = roofPart.points.map(p => new THREE.Vector3(p.x, p.y, p.z).applyMatrix4(roofTransform));
            newArr.points = pnts.map(p => ({ x: p.x, y: p.y, z: p.z }));

            // set azimuth
            this._geomService.setAutoAzimuth(newArr);

            // create new
            const obj3d = instance.createObject(newArr, project.measurement);
            if (obj3d) {
                instance.scene.add(obj3d);
            }
        }

        // rebuild
        instance.rebuild(project);
    }

    /**
        * Removes all arrays associated with a specific roof ID.
        * @param roofId - The ID of the roof.
        */
    private removeAllArrays(roofId: string, instance: SolarInstance) {
        const arrs = instance.getObjects({ ownerId: roofId });
        for (const arr of arrs) {
            instance.removeObjects({ ownerId: arr.id });
            instance.removeObjects({ id: arr.id });
        }
    }

    /**
     * Retrieves an array of points based on the given parameters.
     * @param instance - The SolarInstance object.
     * @param p1 - The first Solar.Point object.
     * @param p2 - The second Solar.Point object.
     * @param roof - The Solar.ObjectRoof object.
     * @returns An array of Solar.Point objects.
     */
    public getArrayPoints(instance: SolarInstance, p1: Solar.Point, p2: Solar.Point, roof: Solar.ObjectRoof): Solar.Point[] {

        const roofTransform = this._geomService.getObjectTransform(roof);
        const roofTransformInv = roofTransform.clone().invert();

        const v1 = new THREE.Vector3(p1.x, p1.y, p1.z).applyMatrix4(roofTransformInv);
        const v2 = new THREE.Vector3(p2.x, p2.y, p2.z).applyMatrix4(roofTransformInv);

        const points: Solar.Point[] = [
            { x: v1.x, y: v1.y, z: 0 },
            { x: v2.x, y: v1.y, z: 0 },
            { x: v2.x, y: v2.y, z: 0 },
            { x: v1.x, y: v2.y, z: 0 },
            { x: v1.x, y: v1.y, z: 0 }
        ];

        for (const pnt of points) {
            const v = new THREE.Vector3(pnt.x, pnt.y, 0).applyMatrix4(roofTransform);
            const res = instance.surface.getElevationWithFilter(v.x, v.y, { types: ["roof"] });

            pnt.x = v.x;
            pnt.y = v.y;
            pnt.z = res?.elevation || 0;
        }

        return points
    }


    /**
     * Returns a new array object with the specified parameters.
     * @param instance - The SolarInstance object.
     * @param project - The Solar.Project object.
     * @param ownerId - The ID of the owner.
     * @param segmentType - The type of the segment ("array" or "auto_array").
     * @returns A new Solar.ObjectRooftopSegment object.
     */
    public getNewArray(instance: SolarInstance, project: Solar.Project, ownerId: string, segmentType: "array" | "auto_array") {
        return {
            id: instance.getUniqueId(),
            name: this._translate.instant("Array"),
            type: "segment",
            segmentType: segmentType,
            isAutoPlacement: true,
            module: project.baseConfiguration.module,
            owner: ownerId,
            points: [] as Solar.Point[],
            align: "grid",
            racking: "flush_mount",
            tilt: 0,
            azimuth: 180,
            orientation: "vert",
            ewSpacing: 0.1,
            moduleSpacing: 0.02,
            rowSpacing: 0.02,
            moduleBottomOffset: 0.1,
            setback: 0,
            mountingSystem: {
                rafterOffset: 0,
                rafterSpan: 0.4064, // 16"
                railSpan: 0,
                maxMountSpacing: 1
            },
            output: {
                area: 0,
                count: 0,
                power: 0
            }
        } as Solar.ObjectRooftopSegment;
    }


    /**
     * Verifies if a new array overlaps with existing arrays and checks if it is placed within the roof.
     * @param instance - The SolarInstance object.
     * @param newArray - The new ObjectRooftopSegment array to be verified.
     * @returns Returns true if the new array is valid and does not overlap with existing arrays or placed outside the roof, otherwise returns false.
     */
    public verifyOverlapingAndPlacment(instance: SolarInstance, newArray: Solar.ObjectRooftopSegment): boolean {
        const existSegments = instance.getObjects({ types: ["segment"] }) as Solar.ObjectRooftopSegment[];
        const existArrays = existSegments.filter(s => s.segmentType == "array");
        if (existArrays.length > 0) {
            for (const arr of existArrays) {
                if (arr.id == newArray.id) {
                    continue;
                }

                if (this._geomService.polygonIntersectsPolygon(arr.points, newArray.points)) {
                    this._dialogService.confirm({
                        title: `Overlaping in different areas`,
                        text: ` `,
                        okBtn: "Ok",
                        hideCancel: true
                    });
                    return false;
                }
            }

            // check the position
            for (const pnt of newArray.points) {
                const res = instance.surface.getElevationWithFilter(pnt.x, pnt.y, { types: ["roof"] });
                if (!res.object) {
                    this._dialogService.confirm({
                        title: `You can't place array outside the roof`,
                        text: ` `,
                        okBtn: "Ok",
                        hideCancel: true
                    });
                    return false;
                }
            }

            // check if it belong to the single roof part only
            //
            const testPoints: THREE.Vector3[] = [];
            for (const pnt of newArray.points) {
                const z = instance.surface.getElevationWithFilter(pnt.x, pnt.y, { types: ["roof"] }).elevation;
                testPoints.push(new THREE.Vector3(pnt.x, pnt.y, z));
            }

            const plane = new THREE.Plane();
            plane.setFromCoplanarPoints(
                testPoints[0].clone(),
                testPoints[1].clone(),
                testPoints[2].clone());


            for (let idx = 1; idx < testPoints.length; idx++) {
                const p1 = testPoints[idx - 1];
                const p2 = testPoints[idx];

                const p3 = p1.clone().add(p2).divideScalar(2);
                p3.z = instance.surface.getElevationWithFilter(p3.x, p3.y, { types: ["roof"] }).elevation;

                const p4 = p1.clone().add(p3).divideScalar(2);
                p4.z = instance.surface.getElevationWithFilter(p4.x, p4.y, { types: ["roof"] }).elevation;

                const p5 = p2.clone().add(p3).divideScalar(2);
                p5.z = instance.surface.getElevationWithFilter(p5.x, p5.y, { types: ["roof"] }).elevation;


                for (const p of [p1, p2, p3, p4, p5]) {
                    const dist = Math.abs(plane.distanceToPoint(p));
                    const tolerance = 0.001;
                    if (dist > tolerance) {
                        this._dialogService.confirm({
                            title: `You can't place array on different segements of the roof`,
                            text: ` `,
                            okBtn: "Ok",
                            hideCancel: true
                        });
                        return false;
                    }
                }
            }

        }

        return true;
    }

    /**
     * Transforms a roof object into a rooftop segment object.
     * @param roof - The roof object to transform.
     * @returns The transformed rooftop segment object.
     * @throws If the roof object or its custom points are missing.
     */
    public toAbstractSegment(roof: Solar.ObjectRoof): Solar.ObjectRooftopSegment {
        if (!roof || !roof.customPoints) {
            throw `can't transform the roof`
        }
        const roofTransform = this._geomService.getObjectTransform(roof);
        const worldPoints = this._geomService.transformPoints(roof.customPoints, roofTransform);

        const segment = {
            id: roof.id,
            name: roof.name,
            points: worldPoints
        } as Solar.ObjectRooftopSegment;

        this._geomService.setAutoAzimuth(segment);

        return segment;
    }


}