<div class="component">
    <div class="bar-wrapper"
         [class.primary]="primary">
        <div class="bar"
             [ngStyle]="{ 'width': percent + '%', 'margin-right': offset + '%' }"></div>
        <div class="bar-title">
            {{title | translate}}
        </div>
    </div>

    <div class="value">
        {{value}}
    </div>
</div>
