import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { AppTime } from './time';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnChanges {

    @Input() time: AppTime = { hr: 0, min: 0 };

    @Output() timeChange = new EventEmitter<{ hr: number, min: number }>();

    @Input() disabled = false;

    public selectedTime = { hr: 0, min: 0 };

    public hours: { text: string, hr: number, min: number }[] = [];

    constructor() {
        this.buildHours();
    }

    private buildHours() {
        for (let idx = 0; idx <= 24; idx++) {
            this.hours.push({
                text: (idx < 10 ? "0" : "") + `${idx}:00`,
                hr: idx,
                min: 0
            });
        }
    }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["time"] && this.time) {
            const inTime = this.hours.find(h => h.hr == this.time.hr);
            if (inTime) {
                this.selectedTime = inTime;
            }
        }
    }

    /**
     * Handles the change event of the time picker.
     * If a time is selected, it updates the internal time value and 
     * emits the timeChange event.
     */
    public onTimeChange() {
        if (!this.selectedTime) {
            return;
        }
        this.time = { hr: this.selectedTime.hr, min: this.selectedTime.min };
        this.timeChange.emit(this.time)
    }
}
