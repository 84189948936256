<div class="component"
     *ngIf="project && project.consumption">

    <div class="topbar">
        <h2>
            {{"Consumption" | translate}}
        </h2>
        <div class="buttons"
             *ngIf="region == 'cn'">
            <button *ngIf="hasImportData"
                    class="clear-btn"
                    mat-flat-button
                    color="primary"
                    (click)="clearImport()">
                <div class="btn-content">
                    <app-icon [src]="'icons/clear.svg' | assetUrl"></app-icon>
                    {{"Clear data" | translate}}
                </div>
            </button>
            <button mat-flat-button
                    color="primary"
                    (click)="importData()"
                    [disabled]="hasImportData">
                <div class="btn-content">
                    <app-icon [src]="'icons/import_excel.svg' | assetUrl"></app-icon>
                    {{"Electricity data" | translate}}
                </div>
            </button>
        </div>
    </div>


    <div *ngIf="chartData"
         class="consumption-chart">
        <div class="title">
            {{"Annual electricity consumption data" | translate}}
        </div>
        <div class="chart-wrapper">
            <ngx-charts-bar-vertical [results]="chartData"
                                     [scheme]="scheme"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [showYAxisLabel]="true"
                                     [roundEdges]="false"
                                     yAxisLabel="kWh">
            </ngx-charts-bar-vertical>
        </div>
    </div>

    <ng-container *ngIf="!chartData">

        <div class="label">
            {{"Enter your consumption:" | translate}}
        </div>

        <websolar-consumption-bill [project]="project"></websolar-consumption-bill>

        <div class="split"></div>

        <div class="label">
            {{"Consumption profile:" | translate}}
        </div>
        <websolar-consumption-profile [project]="project"></websolar-consumption-profile>

        <div class="split"></div>

    </ng-container>



    <div class="label">
        {{"Provide your usage rate:" | translate}}
    </div>

    <div class="rate-wrapper">
        <websolar-consumption-rate [project]="project"
                                   [rate]="project.consumption.rate"></websolar-consumption-rate>
    </div>

    <div class="split"></div>

    <div>
        <mat-checkbox [(ngModel)]="project.electrical.exportToGrid"
                      color="primary">{{"Export to Grid" | translate}}</mat-checkbox>
    </div>

    <ng-container *ngIf="project.electrical.exportToGrid">
        <div class="label">
            {{"Provide your export rate:" | translate}}
        </div>

        <div class="rate-wrapper">
            <websolar-consumption-rate [project]="project"
                                       [rate]="project.consumption.exportRate"></websolar-consumption-rate>
        </div>
    </ng-container>
</div>
