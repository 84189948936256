import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotifyService, Solar, SolarInstance, WebSolarReportService } from '@websolar/ng-websolar';
import { ConsumptionImportComponent } from '../consumption-import/consumption-import.component';
import { AIKO } from 'src/app/types/aiko.types';
import { Color } from '@swimlane/ngx-charts';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-consumption-panel',
    templateUrl: './consumption-panel.component.html',
    styleUrls: ['./consumption-panel.component.scss']
})
export class ConsumptionPanelComponent implements OnChanges {

    @Input() project!: AIKO.ProjectExt;

    @Input() instance!: SolarInstance;

    @Output() navigationRequest = new EventEmitter<Solar.MenuMode>();

    public hasImportData = false;

    public scheme = {
        domain: ['#fa5002']
    } as Color;

    public chartData?: { name: string, value: number }[];

    public region = environment.region;

    constructor(
        private _reportService: WebSolarReportService,
        private _matDialog: MatDialog,
        private _notify: NotifyService,
        private _translate: TranslateService
    ) { 
    }

    public ngOnChanges(changes: SimpleChanges): void {
        try {
            if (this.project) {
                if (!this.project.consumption ||
                    !this.project.consumption.monthsConsumptions) {
                    // set default 
                    this.project.consumption = this._reportService.getDefaultConsumption(this.project);
                }
                if (this.project.importedConsumption) {
                    this.hasImportData = true;
                    this.buildChartData();
                }
                this.estimateConsumption();
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    public navigateToNextStep() {
        this.navigationRequest.emit("Reports");
    }

    private estimateConsumption() {
        if (!this.instance) {
            return;
        }
        if (!this.project.consumption ||
            !this.project.consumption.isAuto) {
            return;
        }
        const info = this._reportService.getSegmentsInfo(this.instance)
        if (!info.area) {
            return;
        }
        // 90m2 is about 800kWh
        const consumePerMonth = Math.round((info.area / 90) * 800);
        this.project.consumption.monthsConsumptions = this._reportService.getMonthsConsumptions(consumePerMonth, this.project);
    }

    public async importData() {
        try {
            const dialogRef = this._matDialog.open(ConsumptionImportComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    project: this.project
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }

            this.buildChartData();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    private buildChartData() {
        if (!this.project.consumption) {
            return;
        }

        this.chartData = [];
        for (const record of this.project.consumption.monthsConsumptions) {
            this.chartData.push({
                name: this._translate.instant(moment().month(record.month).format("MMM")),
                value: record.value
            })
        }

        this.hasImportData = true;
        this.project.consumption.isAuto = false;
    }

    public clearImport() {
        if (!this.project.consumption) {
            return;
        }
        this.hasImportData = false;
        this.project.importedConsumption = undefined;
        this.chartData = undefined;
    }
}
