<div class="component">

    <h2 class="title">
        <span>{{"Selected inverters" | translate}}</span>

        <button mat-flat-button
                color="primary"
                (click)="add()">
            <mat-icon>add</mat-icon>
            {{"Add Inverter" | translate}}
        </button>
    </h2>


    <div class="table-wrapper">
        <table>
            <tr *ngFor="let inverter of selectedInverters">
                <td>
                    {{inverter.manufacturer}}
                </td>
                <td>
                    {{inverter.model}}
                </td>
                <td>
                    {{inverter.maxPower}} kW
                </td>
                <td>
                    <button mat-icon-button
                            (click)="deleteInv(inverter)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </tr>
        </table>
    </div>

    <div class="btns">
        <button (click)="close()"
                mat-stroked-button>
            {{"Close" | translate}}
        </button>

        <button (click)="confirm()"
                mat-flat-button
                [disabled]="selectedInverters.length == 0"
                color="primary">
            {{"Confirm" | translate}}
        </button>
    </div>

</div>
