
interface ErrorMessage {
  errorStatus: number
  errorMessage: unknown
}

export class MicroTool{
  public static isInMicroApp(): boolean {
    return window.__MICRO_APP_ENVIRONMENT__
  }
  public static errorWatcher(error: unknown): void {
    if (error === 'Authentication failed' && this.isInMicroApp()) {
      const errorMessage = {
        errorStatus: 401,
        errorMessage: error
      } as ErrorMessage
      window.microApp.forceDispatch(errorMessage)
    }
  }
}
