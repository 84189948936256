import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotifyService, Solar, SolarInstance, WebSolarOptimizerService } from '@websolar/ng-websolar';

@Component({
    selector: 'app-inverter-edit-fields',
    templateUrl: './inverter-edit-fields.component.html',
    styleUrls: ['./inverter-edit-fields.component.scss']
})
export class InverterEditFieldsComponent {

    public inverterObj: Solar.ObjectInverter;

    public optimizer?: Solar.Optimizer | null;

    public isLoading = false;

    private _instance: SolarInstance;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            instance: SolarInstance,
            inverter: Solar.ObjectInverter
        },
        private _notify: NotifyService,
        private _dialogRef: MatDialogRef<InverterEditFieldsComponent>,
        private _wsOptimizerService: WebSolarOptimizerService
    ) {
        this._instance = data.instance;
        this.inverterObj = data.inverter;
        this.optimizer = this.inverterObj.optimizer;
    }


    public close() {
        this._dialogRef.close(false);
    }

    public confirm() {
        // set optimizer
        this.inverterObj.optimizer = this.optimizer;

        this._dialogRef.close(true);
    }
}
