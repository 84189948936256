<div class="component"
     *ngIf="project && project.finance">
    <div *ngIf="showCost">

        <h3>
            {{"The Solar Tax Credit & System Cost" | translate}}
        </h3>


        <div class="total-cost">
            {{"System Cost" | translate}}: {{currency.sign}}{{netCost}}
        </div>

        <mat-checkbox class="hide-print"
                      [(ngModel)]="project.finance.useCustomNetCost"
                      (ngModelChange)="update()">
            {{"Custom System Cost" | translate}}
        </mat-checkbox>

        <div *ngIf="project.finance.useCustomNetCost">
            <mat-form-field>
                <mat-label>{{"Method of cost calculation" | translate}}</mat-label>
                <mat-select [(ngModel)]="project.finance.mode"
                            (ngModelChange)="onFinanceModeChange()">
                    <mat-option *ngFor="let mode of modes"
                                [value]="mode.id">
                        {{mode.name | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-container *ngIf="project.finance.useCustomNetCost">
            <mat-form-field *ngIf="project.finance.mode == 'total'">
                <span matPrefix>{{currency.sign}}</span>
                <input matInput
                       [(ngModel)]="project.finance.customNetCost"
                       (ngModelChange)="update()"
                       type="number"
                       [placeholder]="'Enter System Cost' | translate"
                       [min]="0">
            </mat-form-field>

            <mat-form-field *ngIf="project.finance.mode == 'pricePerWatt'">
                <span matPrefix>{{currency.sign}}</span>
                <input matInput
                       [(ngModel)]="pricePerWatt"
                       (ngModelChange)="onPerWattUpdate()"
                       type="number"
                       [placeholder]="'Enter Price Per Watt' | translate"
                       [min]="0">
            </mat-form-field>

            <div *ngIf="project.finance.mode == 'pricePerDevice'"
                 class="per-device-wrapper">
                <table mat-table
                       [dataSource]="bomSource">

                    <ng-container matColumnDef="device">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Device" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.name | translate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="model">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Model" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.model}} </td>
                    </ng-container>

                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Quantity" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.quantity}} </td>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Price" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let element"> {{element.price || 0}} </td>
                    </ng-container>

                    <ng-container matColumnDef="total">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Total Cost" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let element"> {{(element.price || 0) * (element.quantity || 0)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Operation" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let element">
                            <div class="btns-wrapper">
                                <img (click)="editRecord(element)"
                                     [src]="'icons/edit-box.png' | assetUrl">
                                <mat-icon *ngIf="element.userDefined"
                                          class="delete-btn"
                                          (click)="deleteDevice(element)">
                                    delete
                                </mat-icon>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="['device', 'model', 'quantity', 'price', 'total', 'operation']"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: ['device', 'model', 'quantity', 'price', 'total', 'operation'];">
                    </tr>
                </table>

                <div *ngIf="bomSource.length == 0"
                     class="loading-text">
                     {{"Loading" | translate}}...
                </div>

                <div>
                    <button mat-flat-button
                            color="primary"
                            (click)="editRecord()"
                            [disabled]="bomSource.length == 0">
                        {{"Add Device" | translate}}
                    </button>
                </div>
            </div>


        </ng-container>


        <mat-form-field>
            <input matInput
                   [(ngModel)]="
                        project.finance.taxRebate"
                   (ngModelChange)="update()"
                   type="number"
                   [placeholder]="'Enter your Tax rebate' | translate"
                   [min]="0"
                   [max]="99">
            <span matSuffix>%</span>
            <mat-hint>{{"Provide your tax rebate if applicable" | translate}}</mat-hint>
        </mat-form-field>
    </div>




</div>
