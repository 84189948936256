<div *ngIf="permitScope"
     class="component">

    <div class="package-card">
        <div class="site-plan"
             [ngStyle]="{'background-image': 'url(' + permitScope.content.sitePlan + ')'}">

            <div class="page-lbl">
                {{"Site Plan" | translate}}
            </div>
            <div class="scale-wrapper">
                <app-permit-scale [title]="'SITE PLAN' | translate"
                                  [scale]="siteScale"
                                  [north]="true"></app-permit-scale>
            </div>
        </div>
    </div>
</div>
