<div class="component">
    <div *ngIf="project && objects"
         class="layout-wrapper">
        <websolar-rooftop-layout [project]="project"
                                 [objects]="objects"
                                 [toolbar]="false"
                                 [mapControls]="false"
                                 [viewSwitched]="false"
                                 (instanceReady)="onInstanceReady($event)"
                                 (loadCompleted)="onLoadCompleted()"></websolar-rooftop-layout>
    </div>

    <div *ngIf="isLoading"
         class="loader-wrapper">
        {{"Loading" | translate}}...
    </div>
</div>
