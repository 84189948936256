import { Injectable } from "@angular/core";
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

@Injectable()
export class PdfService {
    public async export(options: {
        filename: string,
        inputs: HTMLElement[],
        pageWidth: number,
        pageHeight: number
    }) {

        // Documentation available here https://raw.githack.com/MrRio/jsPDF/master/docs/index.html

        const doc = new jsPDF({
            unit: "px",
            orientation: "landscape",
            format: [options.pageWidth, options.pageHeight]
        });

        let requests = [];

        for (const input of options.inputs) {

            // documentation: https://html2canvas.hertzen.com/configuration
            requests.push(html2canvas(input, {
                allowTaint: true,
                useCORS: true,
                windowWidth: options.pageWidth,
                windowHeight: options.pageHeight
            }));

        }

        const result: HTMLCanvasElement[] = await Promise.all(requests);

        for (const [index, canvas] of result.entries()) {
            if (index > 0) {
                doc.addPage();
            }

            console.log(`canvas size: `, canvas.width, canvas.height)

            let fileWidth = options.pageWidth;
            let fileHeight = (canvas.height * fileWidth) / canvas.width;

            console.log(`target img size: `, fileWidth, fileHeight)

            doc.addImage(canvas, "PNG", 0, 0, fileWidth, fileHeight, "", "MEDIUM");
        }

        doc.save(options.filename);
    }
}