<div class="component">
    <div class="header">
        <div *ngIf="!battery._id">
            {{"Create New Battery" | translate}}
        </div>
        <div *ngIf="battery._id">
            {{"Edit Battery" | translate}}
        </div>
        <button mat-icon-button
                (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div class="form"
         *ngIf="!isLoading">

        <mat-form-field>
            <mat-label>{{"Manufacturer" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="battery.manufacturer">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Model" | translate}}</mat-label>
            <input matInput
                   [(ngModel)]="battery.model">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"The capacity of the battery (kWh)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="battery.capacity">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Voltage" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="battery.voltage">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"The maximum power continuous (kW)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="battery.powerMaxContinuous">
        </mat-form-field>

        <mat-form-field>
            <mat-label>SOC (%)</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="SOCmin"
                   (ngModelChange)="onSOCChanged()">
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{"Efficiency (%)" | translate}}</mat-label>
            <input matInput
                   type="number"
                   [(ngModel)]="battery.efficiency">
        </mat-form-field>


        <div class="save-btn-wrapper">
            <button (click)="save()"
                    [disabled]="!isCanSave()"
                    mat-flat-button
                    color="primary">
                {{"Save Battery" | translate}}
            </button>
        </div>


    </div>
    <div *ngIf="isLoading"
         class="loader-wrapper">
        <mat-spinner></mat-spinner>
    </div>
</div>
