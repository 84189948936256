<div class="permit-package-page">

    <div class="top-buttons hide-print">
        <button mat-stroked-button
                color="primary"
                (click)="back()">
            <mat-icon fontSet="material-icons-outlined">arrow_back_ios</mat-icon>
            {{"Back to Project" | translate}}
        </button>

        <button mat-flat-button
                color="primary"
                (click)="exportToPdf()"
                [disabled]="isLoading">
            <mat-icon fontSet="material-icons-outlined">file_download</mat-icon>
            {{"Export PDF" | translate}}
        </button>
    </div>

    <div *ngIf="isExporting && !isLoading"
         class="exporter-loader">
        <div class="content">
            <div>
                {{"Exporting PDF ..." | translate}}
            </div>
            <div class="progress">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </div>

    <div class="loader"
         *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div *ngIf="!isLoading && permitScope"
         [class.export-view]="isExporting"
         #contentElement>

        <ng-container *ngFor="let page of pages; let idx = index;">
            <div class="logo-wrapper">
                <app-logo></app-logo>
            </div>
            <div class="project-name"
                 *ngIf="idx == 0">
                {{permitScope.project.name}}
            </div>

            <div [ngSwitch]="page.name"
                 class="pdf-page">

                <!-- Summary -->
                <app-permit-summary *ngSwitchCase="'summary'"
                                    [permitScope]="permitScope"></app-permit-summary>

                <!-- Site Plan -->
                <app-permit-site-plan *ngSwitchCase="'site-plan'"
                                      [permitScope]="permitScope"></app-permit-site-plan>

                <!-- Bill of Materials -->
                <app-permit-bom *ngSwitchCase="'bom'"
                                [permitScope]="permitScope"></app-permit-bom>

                <!-- Single Line Diagram -->
                <app-permit-sld *ngSwitchCase="'sld'"
                                [permitScope]="permitScope"></app-permit-sld>

                <!-- Attachment  -->
                <ng-container *ngSwitchCase="'attachment'">
                    <ng-container *ngIf="page.segment">
                        <app-permit-attachment-plan [permitScope]="permitScope"
                                                    [segment]="page.segment"></app-permit-attachment-plan>
                    </ng-container>
                </ng-container>

                <!-- Materials of Mounting System -->
                <app-permit-materials-mounting *ngSwitchCase="'material-mounting'"
                                               [permitScope]="permitScope"></app-permit-materials-mounting>

                <!-- Safety Signs -->
                <app-permit-safety-signs *ngSwitchCase="'safety-signs'"
                                         [permitScope]="permitScope"></app-permit-safety-signs>

            </div>

            <div *ngIf="!isExporting"
                 class="page-nr">{{"page" | translate}} - {{idx + 1}}</div>

        </ng-container>

    </div>


</div>
