<div class="component">
    <ng-container *ngIf="!isHasModules">
        <div class="note">
            {{"Before running a simulation, please select a module on the Design." | translate}}
        </div>
    </ng-container>

    <div *ngIf="!isReady && !errorMessage"
         class="progress-wrapper">

        <div>
            {{"Simulation is in progress. Your results will be ready soon ..." | translate}}
        </div>
        <app-loader></app-loader>
    </div>

    <div *ngIf="errorMessage">
        <div class="error-box">
            <mat-icon fontSet="material-icons-outlined">error</mat-icon>
            {{errorMessage | translate}}
        </div>
    </div>

    <ng-container *ngIf="isReady && isHasModules">

        <app-report-overview [project]="project"
                             [objects]="objects"
                             [showConsumption]="showConsumption"></app-report-overview>

    </ng-container>

</div>
