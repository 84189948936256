import { Component, Input } from '@angular/core';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
  selector: 'app-permit-sld',
  templateUrl: './permit-sld.component.html',
  styleUrls: ['./permit-sld.component.scss']
})
export class PermitSldComponent  {

    @Input() permitScope!: AIKO.PermitScope;


}
