import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from "../components/confirm/confirm.component";

@Injectable()
export class DialogService {

    constructor(
        private _dialog: MatDialog
    ) {

    }

    public getMatDialog() {
        return this._dialog;
    }

    public async confirm(opt: {
        title: string,
        text: string,
        okBtn?: string,
        type?: "alert",
        hideCancel?: boolean
    }): Promise<boolean> {
        const dialogRef = this._dialog.open(ConfirmComponent, {
            data: opt,
            autoFocus: false,
            hasBackdrop: true
        });
        const res = await dialogRef.afterClosed().toPromise();
        return res;
    }
}