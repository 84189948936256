import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { InverterEditWindowComponent } from '../inverter-edit-window/inverter-edit-window.component';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService, WebSolarInverterService } from '@websolar/ng-websolar';
import { CloneTool } from 'src/app/core/clone.tool';
import { UserStorageService } from 'src/app/services/user.storage.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-inverter-picker-window',
    templateUrl: './inverter-picker-window.component.html',
    styleUrls: ['./inverter-picker-window.component.scss']
})
export class InverterPickerWindowComponent implements AfterViewInit {

    @ViewChild("paginator") paginator!: MatPaginator;

    public searchText = "";

    public displayedColumns = ['manufacturer', 'model', 'description', 'max_power', 'max_voltage', 'min_voltage', "copy", "fav"];

    public pageOptions = [10, 20, 30];

    public resultsLength = 0;

    public isLoading = true;

    public pageInverters: AIKO.InverterExt[] = [];

    private _inverters: AIKO.InverterExt[] = [];

    private _favInverters: AIKO.InverterExt[] = [];

    private _loadedInverters: AIKO.InverterExt[] = [];

    private _timer: unknown;

    private _systemSize: number = 0;

    private _voltLevel: AIKO.VoltageLevel;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            systemSize: number,
            voltLevel: AIKO.VoltageLevel
        },
        private _dialogRef: MatDialogRef<InverterPickerWindowComponent>,
        private _inverterService: WebSolarInverterService,
        private _notify: NotifyService,
        private _userStorage: UserStorageService,
        private _matDialog: MatDialog,
        private _paginator: MatPaginatorIntl,
        private _translate: TranslateService
    ) {
        this._systemSize = data.systemSize || 0;
        console.log("data:", data);
        this._voltLevel = data.voltLevel || "380";

        if (window.innerWidth < 900) {
            this.displayedColumns = ['manufacturer', 'model', 'max_power'];
        }

        // update paginator lang
        this._paginator.itemsPerPageLabel = this._translate.instant("Items per page");
    }

    ngAfterViewInit(): void {
        this.reload();
    }

    public async reload(force?: boolean) {
        try {
            this.isLoading = true;
            this._inverters = [];
            if (force) {
                this._loadedInverters = [];
                this._inverters = [];
                this._favInverters = [];
            }

            await this.loadPage();
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public onSearch() {
        if (this._timer) {
            clearTimeout(this._timer as number)
        }

        this._timer = setTimeout(() => {
            this.paginator.pageIndex = 0;
            this.reload();
        }, 300);
    }


    public async loadPage() {
        try {
            this.isLoading = true;
            this.pageInverters = [];

            if (!this._loadedInverters.length) {
                this._loadedInverters = await this._inverterService.find({
                    limit: 5000
                });
                this._loadedInverters = this._loadedInverters.filter(i => {
                    if (!i.voltageLevel || i.voltageLevel == "380/220") {
                        return true;
                    }
                    return (i.voltageLevel == this._voltLevel);
                })
            }

            if (this.searchText) {
                this._inverters = this._loadedInverters.filter(m => m.model.toLowerCase().includes(this.searchText.toLowerCase()));
            }
            else {
                this._inverters = this._loadedInverters.slice();
            }

            this.resultsLength = this._inverters.length;

            if (!this._favInverters.length) {
                // load fav inverters
                const favIds = await this._userStorage.getValue("fav_inverters") as string[];
                if (favIds && favIds.length) {
                    this._favInverters = this._loadedInverters.filter(m => favIds.includes(m._id as string));
                    // sort by id
                    this._favInverters.sort((m1, m2) => {
                        return favIds.indexOf(m2._id as string) - favIds.indexOf(m1._id as string);
                    })
                }
            }


            this.updatePageInverters()
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    private getMaxInverterSize(): number | undefined {
        // disable for now
        return 0;

        // return this._systemSize ? (this._systemSize * 1.1) : 0;
    }

    public onSelect(inverter: AIKO.InverterExt) {
        this._dialogRef.close(inverter);

    }

    public async add() {
        try {
            const dialogRef = this._matDialog.open(InverterEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {}
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload(true);
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    public async copy(inverter: AIKO.InverterExt, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const newOnverter = CloneTool.clone(inverter);
            delete newOnverter._id;
            delete newOnverter.orgId;
            newOnverter.model += ` (${this._translate.instant("Copy")})`

            const dialogRef = this._matDialog.open(InverterEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    inverter: newOnverter
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload();
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public async edit(inverter: AIKO.InverterExt, evt: MouseEvent) {
        try {
            evt.stopPropagation();

            const dialogRef = this._matDialog.open(InverterEditWindowComponent, {
                autoFocus: false,
                hasBackdrop: true,
                data: {
                    inverter: inverter
                }
            });
            const res = await dialogRef.afterClosed().toPromise();
            if (!res) {
                return;
            }
            this.reload(true);
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    private updatePageInverters() {
        const skip = this.paginator.pageIndex * this.paginator.pageSize;
        this.pageInverters = this._inverters.slice(skip, skip + this.paginator.pageSize);

        // pin the fav modules on the top
        const favIds = this._favInverters.map(m => m._id as string);
        this.pageInverters = this.pageInverters.filter(m => !favIds.includes(m._id as string));
        this.pageInverters = this._favInverters.slice(0).concat(this.pageInverters);
    }


    public async markAsFav(inv: AIKO.InverterExt, evt: MouseEvent) {
        try {
            evt.stopPropagation();
            // add to fav list
            this._favInverters.splice(0, 0, inv);
            // update page modules
            this.updatePageInverters();

            const favModulesIds = await this._userStorage.getValue("fav_inverters") as string[] || [];
            favModulesIds.push(inv._id as string);
            await this._userStorage.setValue({ id: "fav_inverters", val: favModulesIds });
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    /**
     * Unsets a module from favorites.
     * 
     * @param inv - The module to unset from favorites.
     * @param evt - The mouse event that triggered the unset action.
     */
    public async unsetFav(inv: AIKO.InverterExt, evt: MouseEvent) {
        try {
            evt.stopPropagation();
            // unset from fav
            this._favInverters = this._favInverters.filter(m => m != inv);
            this.updatePageInverters();

            let favModulesIds = await this._userStorage.getValue("fav_inverters") as string[] || [];
            favModulesIds = favModulesIds.filter(id => id != inv._id);
            await this._userStorage.setValue({ id: "fav_inverters", val: favModulesIds });
        }
        catch (err) {
            this._notify.error(err);
        }
    }

    public isFav(inv: AIKO.InverterExt): boolean {
        return this._favInverters.includes(inv);
    }
}
