import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppMaterialModule } from '../app.material';
import { NgWebsolarModule } from '@websolar/ng-websolar';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FormsModule } from '@angular/forms';
import { LoadCalculationsTableComponent } from './load-calculations-table/load-calculations-table.component';
import { MountingEuropeComponent } from './mounting-europe/mounting-europe.component';
import { MountingPanelComponent } from './mounting-panel/mounting-panel.component';
import { AppSharedModule } from '../app.shared.module';
import { MountingCnComponent } from './mounting-cn/mounting-cn.component';

@NgModule({
    declarations: [
        LoadCalculationsTableComponent,
        MountingEuropeComponent,
        MountingPanelComponent,
        MountingCnComponent
    ],
    imports: [
        CommonModule,
        AppMaterialModule,
        NgWebsolarModule,
        NgxChartsModule,
        FormsModule,
        AppSharedModule
    ],
    exports: [
        LoadCalculationsTableComponent,
        MountingEuropeComponent,
        MountingPanelComponent
    ],
    providers: [],
})
export class AppMountingModule {}
