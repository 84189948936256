import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castAny'
})
export class CastPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(value: any): any {
    return value;
  }
}