import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { AIKO } from "../types/aiko.types";

@Injectable({
    providedIn: 'root'
})
export class AnnexService {
    constructor(
        private _http: HttpService
    ) { }

    public async findOne(search: {
        country?: string,
        skip?: number,
        limit?: number
    }): Promise<AIKO.Annex> {
        const items = await this._http.post(`/annex/find`, search) as AIKO.Annex[];
        return items[0];
    }

    public async count(search: {
        search?: string,
        model?: string,
        manufacturer?: string | string[],
        maxSize?: number
    }): Promise<number> {
        const res = await this._http.post(`/annex/count`, search) as { count: number };
        return res.count;
    }

    public async save(item: AIKO.Annex) {
        const res = await this._http.post(`/annex/save`, item) as { id: string };
        return res.id;
    }

    public async delete(options: { id?: string }) {
        return await this._http.post(`/annex/delete`, options);
    }
}