import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnChanges {

    /**
     * The source of the icon image.
     */
    @Input() src!: string;

    constructor(private _host: ElementRef) { }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     */
    public ngOnChanges(): void {
        if (this.src) {
            const host = this._host.nativeElement;
            host.style["mask-image"] = `url(${this.src})`;
            host.style["-webkit-mask-image"] = `url(${this.src})`;
        }
    }
}
