import { Component, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter, Input, NgZone, OnChanges, SimpleChanges } from '@angular/core';
import { Solar } from '@websolar/ng-websolar';


@Component({
    selector: 'app-google-location-search',
    templateUrl: './google-location-search.component.html',
    styleUrls: ['./google-location-search.component.scss']
})
export class GoogleLocationSearchComponent implements AfterViewInit, OnChanges {
    @ViewChild("search") _searchInput!: ElementRef<HTMLInputElement>;

    @Output() locationChange = new EventEmitter<Solar.GeoLocation>();

    @Input() placeholder = "";

    @Input() address = "";

    private _searchBox!: google.maps.places.SearchBox;

    constructor(private _ngZone: NgZone) {
    }

    /**
     * Lifecycle hook that is called when any data-bound property of the component changes.
     * 
     * @param changes - An object containing the changed properties and their current and previous values.
     */
    public ngOnChanges(changes: SimpleChanges): void {
        try {
            if (changes["address"]?.currentValue) {
                if (!this._searchInput || !this._searchInput.nativeElement) {
                    return;
                }
                console.log("address changed:", this.address)
                const input = this._searchInput.nativeElement as HTMLInputElement;
                input.value = this.address;
            }
        }
        catch (err) {
            console.error(err);
        }
    }

    public ngAfterViewInit(): void {
        const input = this._searchInput.nativeElement;
        this._searchBox = new google.maps.places.SearchBox(input);
        this._searchBox.addListener("places_changed", this.placesChanged.bind(this))
    }

    private placesChanged() {
        const places = this._searchBox.getPlaces();
        if (!places || places.length == 0) {
            return;
        }

        const place = places.find(p => p.geometry && p.geometry.location);
        if (!place || !place.geometry || !place.geometry.location) {
            return;
        }
        const location = {
            name: place.name,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        } as Solar.GeoLocation;

        this._ngZone.run(() => {
            this.locationChange.emit(location)
        })

    }

}
