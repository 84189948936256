<div *ngIf="permitScope"
     class="component">

    <div class="package-card">

        <div class="title">
            {{"Materials of Mounting System" | translate}}
        </div>

        <div class="table-wrapper">

            <table mat-table
                   [dataSource]="records"
                   class="table">

                <ng-container matColumnDef="tag">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"NO." | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{records.indexOf(record) + 1}} </td>
                </ng-container>

                <ng-container matColumnDef="device">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Device Name" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.name | translate}} </td>
                </ng-container>

                <ng-container matColumnDef="code">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Code" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record"> {{record.code}} </td>
                </ng-container>

                <ng-container matColumnDef="image">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Image" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record">
                        <img class="preview"
                             *ngIf="record.image"
                             [src]="record.image"
                             crossorigin="anonymous">
                    </td>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Weight" | translate}} ({{"kg" | translate}})</th>
                    <td mat-cell
                        *matCellDef="let record">
                        {{record.weight}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="total_weight">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Total Weight" | translate}} ({{"kg" | translate}})</th>
                    <td mat-cell
                        *matCellDef="let record">
                        {{record.totalWeight}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="quantity">
                    <th mat-header-cell
                        *matHeaderCellDef> {{"Quantity" | translate}} </th>
                    <td mat-cell
                        *matCellDef="let record">
                        {{record.quantity}}
                    </td>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="['tag', 'device', 'code', 'image', 'weight', 'quantity', 'total_weight']; sticky: true">
                </tr>
                <tr mat-row
                    *matRowDef="let row; columns: ['tag', 'device', 'code', 'image', 'weight', 'quantity', 'total_weight'];">
                </tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row"
                    *matNoDataRow>
                    <td class="mat-cell"
                        colspan="3">{{"No data matching" | translate}}</td>
                </tr>
            </table>
        </div>

    </div>
</div>
