<div class="component">

    <app-navbar></app-navbar>

    <div class="content">

        <h1 class="title">
            {{"Your projects" | translate}}
        </h1>

        <div class="toolbar">
            <div class="left-side">
                <div class="search">
                    <div class="lbl">{{"Search" | translate}}</div>
                    <app-input-search [(text)]="searchText"
                                      (textChange)="onSearchChange()"
                                      [placeholder]="'Search Project' | translate"></app-input-search>
                </div>
            </div>
            <div class="right-side">
                <button mat-flat-button
                        color="primary"
                        routerLink="/project/edit">
                    <mat-icon>add</mat-icon>
                    {{"New Project" | translate}}
                </button>
            </div>
        </div>


        <div class="projects-list">


            <div class="table-wrapper"
                 *ngIf="!isLoading">

                <table mat-table
                       [dataSource]="projects"
                       class="table">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Project" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let project"> {{project.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="date">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Created" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let project"> {{project.created | date:'dd MMM yyyy'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="location">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Location" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let project">
                            <a href="https://maps.google.com/?q={{project.location?.lat}},{{project.location?.lng}}"
                               target="_blank">
                                {{"Location" | translate}}
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell
                            *matHeaderCellDef> {{"Description" | translate}} </th>
                        <td mat-cell
                            *matCellDef="let project"> {{project.description}} </td>
                    </ng-container>


                    <ng-container matColumnDef="actions">
                        <th mat-header-cell
                            *matHeaderCellDef
                            class="action-th">
                            <span>{{"Action" | translate}}</span>
                        </th>
                        <td mat-cell
                            *matCellDef="let project">

                            <div class="btns">
                                <button mat-button
                                        color="primary"
                                        (click)="copyProject(project, $event)">
                                    {{"Copy" | translate}}
                                </button>

                                <button mat-button
                                        color="primary"
                                        (click)="deleteProject(project, $event)">
                                    {{"Delete" | translate}}
                                </button>
                            </div>

                        </td>
                    </ng-container>

                    <tr mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                    <tr mat-row
                        *matRowDef="let row; columns: displayedColumns;"
                        (click)="navigateToProject(row)"></tr>

                    <!-- Row shown when there is no matching data. -->
                    <tr class="mat-row"
                        *matNoDataRow>
                        <td class="mat-cell"
                            *ngIf="!isLoading"
                            colspan="3">{{"No data matching" | translate}}</td>
                    </tr>
                </table>
            </div>


            <div class="loader-wrapper"
                 *ngIf="isLoading">
                <mat-spinner></mat-spinner>
            </div>


            <!-- hide paginator to avoid component destroying -->
            <div [class.hide]="isLoading">
                <app-paginator #paginator
                               [length]="resultsLength"
                               [pageSize]="10"
                               [pageSizeOptions]="pageOptions"
                               (page)="loadPage()"></app-paginator>
            </div>
        </div>

    </div>

    <div *ngIf="noProjects"
         class="info">
        {{"You don't have any projects yet." | translate}} <a routerLink="/project-edit"> {{"Create a new project" | translate}}</a>
    </div>

</div>
