<div *ngIf="permitScope"
     class="component">

    <div class="package-card">
        <div class="sld">
            <div class="page-lbl">
                {{"Single-line Diagram" | translate}}
            </div>

            <div class="sld-img"
                 [style.background-image]="'url(' + permitScope.content.sld + ')'">
            </div>
        </div>
    </div>
</div>
