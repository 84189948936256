import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {

    public title = "";

    public text = "";

    public okBtn = "Ok";

    public type: "alert" | "default" | "";

    public hideCanel = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: {
            title: string,
            text: string,
            okBtn: string,
            type: "alert" | "default" | "",
            hideCancel: boolean
        },
        private _dialogRef: MatDialogRef<ConfirmComponent>
    ) {
        this.title = data.title;
        this.text = data.text;
        this.hideCanel = data.hideCancel;

        this.type = data.type;
        if (!this.type) {
            this.type = "default";
        }

        if (data.okBtn) {
            this.okBtn = data.okBtn;
        }
    }

    public accept() {
        try {

            if (!this.text) {
                throw `text is empty`
            }

            this._dialogRef.close(true)
        }
        catch (err) {
            console.error(err);
        }
    }

    public cancel() {
        this._dialogRef.close(false)
    }
}
