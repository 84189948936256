<div class="map-container"
     [ngSwitch]="type">
    <app-amap *ngSwitchCase="'amap'"
              [location]="location"
              [projectSize]="projectSize"
              (locationChange)="onLocationChange($event)"></app-amap>
    <app-google-map *ngSwitchCase="'google'"
                    [location]="location"
                    [projectSize]="projectSize"
                    (locationChange)="onLocationChange($event)"></app-google-map>
</div>
