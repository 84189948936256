import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Solar } from "@websolar/ng-websolar";
import { MemoryService } from "./memory.service";
import { environment } from "src/environments/environment";
import { AMapMapService } from "./amap.map.service";


@Injectable()
export class GeocodingService {

    constructor(
        private _http: HttpClient,
        private _memory: MemoryService,
        private _amapService: AMapMapService) { }

    /**
     * Retrieves the current geolocation
     * @returns {Promise<any>} A promise that resolves to the current geolocation.
     * @throws {Error} If there is an error retrieving the geolocation.
     */
    public async getCurrentGeolocation(): Promise<Solar.GeoLocation | null> {
        if (environment.region == "cn") {
            return await this._amapService.getCurrentGeolocationAMap();
        }
        else {
            return await this.getCurrentGeolocationGoogle();
        }
    }

    /**
     * Retrieves the current geolocation using the Google Geolocation API.
     * @returns {Promise<any>} A promise that resolves to the current geolocation.
     * @throws {Error} If there is an error retrieving the geolocation.
     */
    private async getCurrentGeolocationGoogle(): Promise<Solar.GeoLocation | null> {
        // documentation: https://developers.google.com/maps/documentation/geolocation/requests-geolocation#body
        const apiKey = this._memory.getItem("GOOGLE_API_KEY");
        if (!apiKey) {
            throw `GOOGLE API KEY IS INVALID`
        }
        const res = await this._http.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`,
            {

                considerIp: true,
                cellTowers: [],
                wifiAccessPoints: []
            }).toPromise() as { location: { lat: number, lng: number } };
        if (!res || !res.location) {
            return null;
        }
        return { lat: res.location.lat, lng: res.location.lng, name: "" };
    }

    public async getAddress(lat: number, lng: number): Promise<string> {
        if (environment.region == "cn") {
            return await this._amapService.getAddress(lat, lng);
        }
        else {
            return await this.getAddressGoogle(lat, lng);
        }
    }

    /**
     * Retrieves the address using Google Geocoding API based on the provided latitude and longitude.
     * @param lat - The latitude coordinate.
     * @param lng - The longitude coordinate.
     * @returns A Promise that resolves to a string representing the formatted address.
     * @throws Throws an error if the Google API key is invalid or missing.
     */
    private async getAddressGoogle(lat: number, lng: number): Promise<string> {
        const apiKey = this._memory.getItem("GOOGLE_API_KEY");
        if (!apiKey) {
            throw `GOOGLE API KEY IS INVALID`
        }

        const geocoder = new google.maps.Geocoder();
        const res = await geocoder.geocode({ location: new google.maps.LatLng(lat, lng) })

        return res?.results[0]?.formatted_address || "";
    }
}