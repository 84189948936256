import { Component, Input, OnChanges } from '@angular/core';
import { NotifyService } from '@websolar/ng-websolar';
import { BomService } from 'src/app/services/bom.service';
import { AIKO } from 'src/app/types/aiko.types';

@Component({
    selector: 'app-permit-bom',
    templateUrl: './permit-bom.component.html',
    styleUrls: ['./permit-bom.component.scss']
})
export class PermitBomComponent implements OnChanges {

    @Input() permitScope!: AIKO.PermitScope;

    public records: AIKO.BOMRecord[] = [];

    constructor(
        private _notify: NotifyService,
        private _bomService: BomService
    ) { }

    ngOnChanges(): void {
        if (this.permitScope) {
            this.init();
        }
    }

    private async init() {
        try {
            if (!this.permitScope) {
                return;
            }

            this.records = await this._bomService.getBOM(this.permitScope.project, this.permitScope.objects);
        }
        catch (err) {
            this._notify.error(err);
        }
    }

}
