import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { WebSolarAuthService } from '@websolar/ng-websolar';
import { Observable } from 'rxjs';
import {MicroTool} from "../core/micro.tool";

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private _authService: WebSolarAuthService, private _router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this._authService.getToken()) {
          if (MicroTool.isInMicroApp()) {
            const data = window.microApp.getData()
            const token = data.token
            this._authService.setToken(token)
            return true;
          } else {
              if (state.root.queryParams && state.root.queryParams["token"]) {
                const token = state.root.queryParams["token"];
                this._authService.setToken(token)
                return true;
              }

              // redirect user to host page
            //TODO: test CORS
              this._router.navigate(['/host']);
          }
            return false;
        }

        return true;
    }

}
