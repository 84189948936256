<div class="time-picker-component">
    <mat-form-field>
        <mat-label *ngIf="!selectedTime">
            <span class="placeholder-lbl">--:--</span>
        </mat-label>
        <mat-select [(ngModel)]="selectedTime"
                    (ngModelChange)="onTimeChange()"
                    [disabled]="disabled">
            <mat-option *ngFor="let state of hours"
                        [value]="state">{{state.text}}</mat-option>
        </mat-select>
        <div matSuffix>
            <mat-icon>schedule</mat-icon>
        </div>
    </mat-form-field>
</div>
