import * as THREE from "three";
import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { SolarInstance, Solar, NotifyService, WebSolarEventsService, WebSolarTransactionService, WebSolarGeometryService } from '@websolar/ng-websolar';
import { Subscription } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { TranslateService } from "@ngx-translate/core";
import { AIKO } from "src/app/types/aiko.types";
import { ToolbarTool } from "src/app/core/toolbar.tool";


@Component({
    selector: 'app-tree-list',
    templateUrl: './tree-list.component.html',
    styleUrls: ['./tree-list.component.scss']
})
export class TreeListComponent implements OnChanges, OnDestroy {

    @Input() project!: Solar.Project;

    @Input() instance!: SolarInstance;

    @Input() toolbarControl!: AIKO.ToolbarControl;

    public poles: Solar.ObjectElectricalPole[] = [];

    public trees: Solar.ObjectTree[] = [];

    public itemState: { [key: string]: { expanded: boolean, item: Solar.Object } } = {};

    private _subs: Subscription[] = [];

    constructor(
        private _notify: NotifyService,
        private _dialogService: DialogService,
        private _eventService: WebSolarEventsService,
        private _transactionService: WebSolarTransactionService,
        private _translate: TranslateService
    ) {
        // subscribe to events
        const sub = this._eventService.eventsAsObservable.subscribe((opt) => {
            if (opt.name == "tool_completed" ||
                opt.name == "object_deleted" ||
                opt.name == "undo" ||
                opt.name == "redo" ||
                opt.name == "project_loaded") {
                this.updateList();
            }
            else if (opt.name == "object_picked") {
                this.onObjectPicked(opt.params as Solar.Object);
            }
        });
        this._subs.push(sub);
    }

    public ngOnChanges(): void {
        try {
            this.updateList();
        }
        catch (err) {
            console.error();
        }
    }

    public ngOnDestroy(): void {
        for (const sub of this._subs) {
            sub.unsubscribe();
        }
    }

    private onObjectPicked(object: Solar.Object) {
        try {
            if (!this.itemState[object.id]) {
                return;
            }

            // collapse other segments
            for (const key of Object.keys(this.itemState)) {
                if (key != object.id) {
                    this.itemState[key].expanded = false;
                }
            }

            this.itemState[object.id].expanded = true;
        }
        catch (err) {
            console.error(err);
        }
    }

    /**
     * Updates the list of trees and poles.
     * Retrieves objects from the instance and initializes them if necessary.
     * Updates the toolbar control based on the number of trees and poles.
     */
    private updateList() {
        try {
            if (!this.instance) {
                return;
            }

            this.trees = this.instance.getObjects({ types: ["tree"] }) as Solar.ObjectTree[];
            for (const item of this.trees) {
                if (!item.treeType) {
                    item.treeType = 'round';
                }

                if (!item.name) {
                    switch (item.treeType) {
                        case "cone":
                            item.name = this._translate.instant("Cone Tree");
                            break;
                        case "round":
                            item.name = this._translate.instant("Round Tree");
                            break;
                        default:
                            break;
                    }
                }
                this.initObject(item);
            }

            this.poles = this.instance.getObjects({ types: ["electrical_pole"] }) as Solar.ObjectElectricalPole[];
            for (const item of this.poles) {
                if (!item.radius) {
                    item.radius = 0.1;
                }
                if (!item.wireRadius) {
                    item.wireRadius = 0.01;
                }
                if (!item.wireLength) {
                    item.wireLength = 50;
                }
                if (!item.name) {
                    item.name = this._translate.instant("Electrical Pole");
                }
                this.initObject(item);
            }

            ToolbarTool.disableAll(this.toolbarControl);

            if (this.trees.length == 0 && this.poles.length == 0) {
                this.instance.getHint().setMessage("pick the tree/electric pole add to the map");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
                this.toolbarControl.undoRedo = true;
                this.toolbarControl.delete = true;
            }
            else {
                this.instance.getHint().setMessage("");
                this.toolbarControl.save = true;
                this.toolbarControl.zoom = true;
                this.toolbarControl.undoRedo = true;
                this.toolbarControl.delete = true;
            }

        }
        catch (err) {
            console.error();
        }
    }


    /**
     * Toggles the expansion state of a keepout item.
     * 
     * @param keepout - The keepout object to toggle.
     */
    public toggle(keepout: Solar.Object) {
        if (!this.instance) {
            return;
        }

        this.itemState[keepout.id].expanded = !this.itemState[keepout.id].expanded;

        const obj3d = this.instance.get3dObjects({ id: keepout.id })[0];
        if (obj3d) {
            this.instance.objectEditor.attach(obj3d);
        }
    }

    /**
     * Initializes an object with the given item.
     */
    private initObject(item: Solar.Object) {
        this.itemState[item.id] = {
            expanded: false,
            item: item
        };
    }

    /**
     * Deletes an item from the tree list.
     * @param item - The item to be deleted.
     */
    public async onDelete(item: Solar.Object) {
        try {
            const confirm = await this._dialogService.confirm({
                title: `Delete`,
                text: `Are you sure you want to delete this item?`,
                okBtn: "Delete"
            })
            if (!confirm) {
                return;
            }
            // remove children
            this.instance.removeObjects({
                ownerId: item.id
            });

            // delete keepout as well
            this.instance.removeObjects({
                id: item.id
            });

            // remove from the list
            this.updateList();

            this.instance.rebuild(this.project);
        }
        catch (err) {
            this._notify.error(err);
        }
    }



    /**
     * Handles the change event when an item is selected.
     * 
     * @param obj - The selected object.
     */
    public onItemChange(obj: Solar.Object) {
        if (!this.instance) {
            return;
        }

        this.verifyParameters(obj);

        this.update3dObject(obj);

        this.instance.rebuild(this.project);
    }

    /**
     * Verifies and updates the parameters of the given object.
     * @param obj - The object to verify and update parameters for.
     */
    private verifyParameters(obj: Solar.Object) {
        if (obj.type == "tree") {
            const tree = obj as Solar.ObjectTree;
            if (tree.radius) {
                tree.radius = Math.max(tree.radius, 0.01);
            }
            if (tree.height) {
                tree.height = Math.max(tree.height, 0.01);
            }
            if (tree.topHeight) {
                tree.topHeight = Math.max(tree.topHeight, 0.01);
            }
            if (tree.topHeight) {
                tree.topHeight = Math.max(tree.topHeight, 0.01);
            }
        }
        else if (obj.type == "electrical_pole") {
            const electricalPole = obj as Solar.ObjectElectricalPole;
            if (electricalPole.wireLength) {
                electricalPole.wireLength = Math.max(electricalPole.wireLength, 0.01);
            }
            if (electricalPole.wireRadius) {
                electricalPole.wireRadius = Math.max(electricalPole.wireRadius, 0.01);
            }
            if (electricalPole.radius) {
                electricalPole.radius = Math.max(electricalPole.radius, 0.01);
            }
            if (electricalPole.height) {
                electricalPole.height = Math.max(electricalPole.height, 0.01);
            }
        }
    }

    /**
     * Updates the 3D object for a given tree.
     * 
     * @param tree - The tree object to update.
     * @returns The newly created 3D object.
     */
    private update3dObject(tree: Solar.Object) {
        if (!this.instance) {
            return;
        }

        // recreate a object on the drawing
        this.instance.removeObjects({ id: tree.id });
        const newObj = this.instance.createObject(tree, this.project.measurement);
        if (!newObj) {
            return;
        }
        this.instance.scene.add(newObj);
        return newObj;
    }

    /**
     * Creates a new keepout with the specified  type.
     */
    public createNew(treeType: Solar.TreeType) {
        this.runTool({ treeType: treeType })
    }

    /**
     * Creates a pole wire.
     */
    public createPoleWire() {
        this.instance.activateTool({ type: "electrical_pole", params: null }, this.project);
    }


    /**
     * Runs the tool to create a tree object.
     * 
     * @param options - The options for creating the tree object.
     * @returns A promise that resolves to the created tree object, or null if the process was cancelled.
     */
    public async runTool(options: { treeType: Solar.TreeType }): Promise<Solar.ObjectTree | null> {
        const editTypes = this.instance.objectEditor.getEditTypes();
        try {
            // cancel the previous
            this.instance.sendEscape();

            this.instance.objectEditor.disable();
            this.instance.objectTracker.disable();

            this.instance.getHint().setMessage("Pick the location")

            const solarObject = {
                id: this.instance.getUniqueId(),
                type: "tree",
                treeType: options.treeType,
                position: { x: 0, y: 0, z: 0 },
                height: 4,
                radius: 2,
                topHeight: 5
            } as Solar.ObjectTree;

            const success = await this.processTree(solarObject);
            if (!success) {
                return null;
            }

            // add the transaction
            this._transactionService.add({
                type: "add",
                objects: [solarObject]
            });

            this.updateList();

            return solarObject;
        }
        finally {
            this.instance.getHint().setMessage("");
            this.instance.objectEditor.enable(editTypes);
            this.instance.objectTracker.enable();
        }
    }


    /**
     * Processes a tree object and adds it to the scene.
     * @param tree - The tree object to be processed.
     * @returns A promise that resolves to a boolean indicating whether the tree was successfully processed.
     */
    private async processTree(tree: Solar.ObjectTree): Promise<boolean> {
        // add to scene
        const sceneObject = this.instance.createObject(tree);
        if (!sceneObject) {
            return false;
        }

        this.instance.scene.add(sceneObject);

        const point = await this.instance.pointPicker.queryPoint({
            endPoint: null,
            disableSnaping: true,
            callback: (newPos: Solar.Point) => {
                tree.position = { x: newPos.x, y: newPos.y, z: 0 };
                sceneObject.position.set(newPos.x, newPos.y, 0);
            }
        }) as THREE.Vector3;

        if (!point) {
            this.instance.scene.remove(sceneObject);
            return false;
        }

        this.instance.rebuild(this.project);

        // enable transform
        this.instance.objectEditor.attach(sceneObject);
        return true;
    }
}
