import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { AIKO } from "../types/aiko.types";

@Injectable({
    providedIn: 'root'
})
export class UserStorageService {
    constructor(
        private _http: HttpService
    ) { }

    public async getValue(id: string): Promise<unknown> {
        const result = await this._http.post(`/userStorage/get`, { id: id }) as { value: unknown };
        return result?.value || null;
    }

    public async setValue(item: {
        id: string;
        val: unknown,
        expiry?: Date | string
    }) {
        await this._http.post(`/userStorage/set`, item);
    }
}