<div class="component component-toolbar"
     *ngIf="project && instance && control">
    <div class="left-side">
        <ng-container>
            <button *ngIf="control.save"
                    mat-button
                    (click)="onSaveProject()"
                    [disabled]="isSaving">
                <mat-icon fontSet="material-icons-outlined">save</mat-icon>
                <span class="hide-sm">
                    {{"Save Project" | translate}}
                </span>
            </button>

            <div class="divider"></div>

            <button *ngIf="control.zoom"
                    class="hide-sm hide-md"
                    mat-icon-button
                    [matTooltip]="'Recenter the view' | translate"
                    (click)="zoomToExtent()">
                <mat-icon>zoom_out_map</mat-icon>
            </button>

            <button *ngIf="control.view3d && instance"
                    class="hide-sm"
                    mat-icon-button
                    [matTooltip]="'3D objects visibility' | translate"
                    (click)="toggle3d()">
                <mat-icon fontSet="material-icons-outlined">
                    {{instance.layers.objects3d ? 'visibility' : 'visibility_off'}}
                </mat-icon>
            </button>

            <button *ngIf="control.ruler"
                    class="hide-sm hide-md"
                    mat-icon-button
                    [matTooltip]="'Ruler' | translate"
                    (click)="activateRuler()"
                    [disabled]="disabledTools">
                <mat-icon>straighten</mat-icon>
            </button>
        </ng-container>

        <div class="divider"
             *ngIf="control.undoRedo"></div>

        <button *ngIf="control.undoRedo"
                class="hide-sm"
                mat-icon-button
                [matTooltip]="'Undo' | translate"
                (click)="undo()"
                [disabled]="disabledTools || !canUndo()">
            <mat-icon fontSet="material-icons-outlined">undo</mat-icon>
        </button>

        <button *ngIf="control.undoRedo"
                class="hide-sm"
                mat-icon-button
                [matTooltip]="'Redo' | translate"
                (click)="redo()"
                [disabled]="disabledTools || !canRedo()">
            <mat-icon fontSet="material-icons-outlined">redo</mat-icon>
        </button>


        <button *ngIf="control.delete"
                class="hide-sm"
                mat-icon-button
                [matTooltip]="'Delete object' | translate"
                (click)="activateTool('delete')"
                [disabled]="disabledTools">
            <mat-icon fontSet="material-icons-outlined">delete_forever</mat-icon>
        </button>

        <button *ngIf="control.copy"
                class="hide-sm"
                mat-icon-button
                [matTooltip]="'Copy keepout, tree or panel' | translate"
                (click)="copy()"
                [disabled]="disabledTools">
            <mat-icon fontSet="material-icons-outlined">copy</mat-icon>
        </button>

        <button *ngIf="control.reset"
                class="hide-sm"
                mat-icon-button
                [matTooltip]="'Restore segments' | translate"
                (click)="activateTool('restore')"
                [disabled]="disabledTools">
            <mat-icon fontSet="material-icons-outlined">restore</mat-icon>
        </button>


        <ng-container *ngIf="control.autoModelling">
            <div class="divider"></div>

            <mat-slide-toggle class="hide-sm"
                              [(ngModel)]="isAutoModeling"
                              (ngModelChange)="autoModellingChange()"
                              [matTooltip]="'Toggle auto modelling mode' | translate">
                <span class="auto-lbl toggle-lbl hide-md">
                    {{"Auto Modelling" | translate}}
                </span>
            </mat-slide-toggle>
        </ng-container>


    </div>

</div>
