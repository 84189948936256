<div class="component">
    <div class="left-side">
        <a class="logo"
           routerLink="/project/list">
            <app-logo></app-logo>
        </a>

        <div *ngIf="showLinks"
             class="links-wrapper">
            <a class="link"
               routerLink="/project/list"
               routerLinkActive="active">
                {{"Home" | translate}}
            </a>

            <a class="link hide-sm"
               routerLink="/project/edit"
               routerLinkActive="active">
                {{"New Project" | translate}}
            </a>

        </div>

    </div>

    <div class="right-side">

    </div>
</div>
