import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Solar, NotifyService, WebSolarModuleService } from '@websolar/ng-websolar';
import { UserStorageService } from 'src/app/services/user.storage.service';

@Component({
    selector: 'app-module-edit-window',
    templateUrl: './module-edit-window.component.html',
    styleUrls: ['./module-edit-window.component.scss']
})
export class ModuleEditWindowComponent {

    public module: Solar.Module;

    public isLoading = false;

    public isFav = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: { module: Solar.Module },
        private _dialogRef: MatDialogRef<ModuleEditWindowComponent>,
        private _notify: NotifyService,
        private _moduleService: WebSolarModuleService,
        private _userStorage: UserStorageService
    ) {
        this.module = data.module || this.getDefault();
        this.updateFav();
    }

    private async updateFav() {
        const favModulesIds = await this._userStorage.getValue("fav_modules") as string[] || [];
        if (this.module._id) {
            this.isFav = favModulesIds.includes(this.module._id);
        }
    }

    private getDefault(): Solar.Module {
        return {
            model: "",
            manufacturer: "",
            width: 1.5,
            height: 1,
            depth: 0.04,
            description: "",
            power: 270,
            isc: 9,
            ipmax: 8,
            misc: 0.05,
            technology: "Mono-c-Si",
            tvoc: -0.3,
            voc: 38,
            vpmax: 30,
            tpmax: -0.3,
            price: 400,
            weight: 30
        }
    }

    /**
     * Saves the module and updates the favorite state.
     * @returns {Promise<void>} A promise that resolves when the save operation is complete.
     */
    public async save() {
        try {
            this.isLoading = true;

            const id = await this._moduleService.save(this.module);

            await this.saveFavState(id);

            this._notify.success(`Module saved`);

            this._dialogRef.close(true);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    /**
     * Saves the favorite state of a module.
     * @param id - The ID of the module.
     */
    private async saveFavState(id: string) {
        let favModulesIds = await this._userStorage.getValue("fav_modules") as string[] || [];
        if (this.isFav) {
            if (!favModulesIds.includes(id)) {
                favModulesIds.push(id);
            }
        }
        else {
            favModulesIds = favModulesIds.filter(m => m != id);
        }
        await this._userStorage.setValue({ id: "fav_modules", val: favModulesIds });
    }

    public isCanSave() {
        if (!this.module.manufacturer ||
            !this.module.model ||
            typeof this.module.width != "number" ||
            typeof this.module.height != "number" ||
            typeof this.module.depth != "number" ||
            typeof this.module.voc != "number" ||
            typeof this.module.tpmax != "number" ||
            typeof this.module.power != "number" ||
            typeof this.module.tvoc != "number") {
            return false;
        }

        return true;
    }

    public close() {
        this._dialogRef.close(null);
    }
}
