import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Solar, WebSolarProjectService, NotifyService, WebSolarCurrencyService, LocalLock } from '@websolar/ng-websolar';
import { NumberTool } from 'src/app/core/number.tool';
import { AMapMapService } from 'src/app/services/amap.map.service';
import { GeocodingService } from 'src/app/services/geocoding.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-project-edit-page',
    templateUrl: './project-edit-page.component.html',
    styleUrls: ['./project-edit-page.component.scss']
})
export class ProjectEditPageComponent implements AfterViewInit {

    public bannerText = "";

    public isLoading = false;

    public project = {} as Solar.Project;

    public lngLat = "";

    public projectSizes: { id: Solar.ProjectSize, name: string }[] = [
        { id: "1x", name: "Residential" },
        { id: "2x", name: "Commercial I" },
        { id: "3x", name: "Commercial II" }
    ];

    public address = "";

    private _addressLock = new LocalLock();

    constructor(
        private _projectService: WebSolarProjectService,
        private _notify: NotifyService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private _geolocation: GeocodingService,
        private _amapService: AMapMapService,
        private _currencyService: WebSolarCurrencyService,
        private _ngZone: NgZone
    ) {
        if (!this.project.location) {
            this.project.location = {
                name: "",
                lng: 0,
                lat: 0
            }
        }
        if (!this.project.measurement) {
            this.project.measurement = "metric";
        }
        if (!this.project.projectSize) {
            this.project.projectSize = "1x";
        }
        if (!this.project.type) {
            this.project.type = "rooftop";
        }
        if (this.project.type == "ground") {
            // currently support only 3x
            this.project.projectSize = "3x";
        }
    }

    public async ngAfterViewInit() {
        try {
            const id = this._activatedRoute.snapshot.queryParams["id"]
            if (!id) {
                console.log("autoDectectLocation:")
                // detect a location by IP
                this.autoDectectLocation();

                return;
            }

            this.project = await this._projectService.findOne(id)
        }
        catch (err) {
            this._notify.error(err);
        }
    }


    private async autoDectectLocation() {
        try {
            const location = await this._geolocation.getCurrentGeolocation();
            if (location) {
                this.project.location = location;
            }
            this.bannerText = `Update your location by double clicking on the map`;
        }
        catch (err) {
            console.error(err);
        }
    }

    public onInputLocationChange(loc: Solar.GeoLocation) {
        this.bannerText = `Update your location by double clicking on the map`;
        this.project.location = loc;

        if (!this.project._id || !this.project.measurement) {
            // auto detect the measurement
            if (this.project.location.lng < -40 ||
                this.project.location.lat < 0) {
                this.project.measurement = "imperial";
            }
            else {
                this.project.measurement = "metric";
            }
        }

        this.lngLat = `${NumberTool.round(loc.lng, 6)}, ${NumberTool.round(loc.lat, 6)}`;
    }

    /**
     * Handles the change event when the map location is updated.
     * @param {Solar.GeoLocation} loc - The new map location.
     * @returns {Promise<void>} - A promise that resolves when the map location is updated.
     */
    public async onMapLocationChange(loc: Solar.GeoLocation) {
        this._ngZone.run(async () => {
            this.lngLat = `${NumberTool.round(loc.lng, 6)}, ${NumberTool.round(loc.lat, 6)}`;
            await this.updateLatLng(loc.lat, loc.lng);
        })
    }


    public onProjectTypeChange() {
        if (this.project.type == "ground") {
            this.project.projectSize = "3x";
        }
    }

    public async save() {
        try {
            this.isLoading = true;

            if (typeof this.project.location.lng != "number" ||
                !this.project.location.lng) {
                throw `invalid location`
            }
            if (typeof this.project.location.lat != "number" ||
                !this.project.location.lat) {
                throw `invalid location`
            }
            while (this.project.location.lng < -180) {
                this.project.location.lng += 360;
            }
            while (this.project.location.lng > 180) {
                this.project.location.lng -= 360;
            }

            // delete wheater and consumptions and DEM, because location changed
            this.project.weather = undefined;
            this.project.consumption = undefined;
            this.project.dem = undefined;
            this.project.demObj = undefined;

            if (this.project.type == "ground") {
                // set terrain as default
                this.project.mapType = "terrain";
                this.project.projectSize = "3x";
            }

            if (environment.region == "cn") {
                // set custom type
                this.project.mapType = "intergrated";
                const cny = this._currencyService.getList().find(t => t.code == "CNY");
                if (cny) {
                    this.project.currency = cny;
                }
                // load AMap as custom map
                await this._amapService.uploadStaticImage(this.project);
            }

            const res = await this._projectService.save({
                project: this.project
            });

            this._router.navigate([`/design/${res.id}`]);
        }
        catch (err) {
            this._notify.error(err);
        }
        finally {
            this.isLoading = false;
        }
    }

    public isValid() {
        if (!this.project.name ||
            !this.project.location) {
            return false;
        }
        return true;
    }

    /**
     * Handles the event when the user enters a longitude and latitude value.
     */
    public async onLngLatEnter() {
        if (!this.lngLat) {
            this._notify.error("Invalid position");
            return;
        }
        const parts = this.lngLat.split(",");
        if (!parts || parts.length != 2) {
            this._notify.error("Invalid position");
            return;
        }
        if (parts[0].split(".")[1]?.length > 6 ||
            parts[1].split(".")[1]?.length > 6) {
            this._notify.error("Input no more than 6 digit");
            return;
        }
        const lng = parseFloat(parts[0]);
        const lat = parseFloat(parts[1]);
        if (typeof lng != "number" || typeof lat != "number") {
            this._notify.error("Invalid position");
            return;
        }

        await this.updateLatLng(lat, lng);
    }


    /**
     * Updates the latitude and longitude of the project's location and retrieves the corresponding address.
     * @param lat The latitude value.
     * @param lng The longitude value.
     */
    private async updateLatLng(lat: number, lng: number) {
        try {
            await this._addressLock.lock();

            // get address
            let addr = "";
            try {
                addr = await this._geolocation.getAddress(lat, lng);
            }
            catch (err) {
                console.error(err);
            }

            this.project.location = {
                lat: lat,
                lng: lng,
                name: addr
            };

            this.address = addr || "N/A";
        }
        finally {
            this._addressLock.release();
        }
    }
}
