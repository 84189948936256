import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BatteryWindowComponent } from '../battery-window/battery-window.component';
import { NotifyService, Solar } from '@websolar/ng-websolar';

@Component({
    selector: 'app-battery-picker',
    templateUrl: './battery-picker.component.html',
    styleUrls: ['./battery-picker.component.scss']
})
export class BatteryPickerComponent implements OnChanges {

    @Input() battery: Solar.Battery | undefined;

    @Input() project!: Solar.Project;

    @Input() systemSize!: number;

    @Output() batteryChange = new EventEmitter<Solar.Battery>();

    public batteryName = "";

    public isLoading = false;

    constructor(
        private _matDialog: MatDialog,
        private _notify: NotifyService
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes["battery"]) {
            this.batteryName = this.displayFn(this.battery as Solar.Battery);
        }
    }


    public onChanged() {

    }

    public displayFn(obj: Solar.Battery | string) {
        if (!obj) {
            return "";
        }
        if (typeof obj == "string") {
            return obj;
        }
        const battery = obj as Solar.Battery;
        return `${battery.manufacturer} ${battery.model} [${battery.capacity}kWh]`;
    }


    public async openSearch(evt: MouseEvent) {

        evt.preventDefault();
        evt.stopPropagation();

        try {

            let batteries: string[] = [];
            const dialogRef = this._matDialog.open(BatteryWindowComponent, {
                autoFocus: true,
                hasBackdrop: true,
                maxWidth: "95vw",
                data: {
                    manufacturer: batteries
                }
            });
            const res = await dialogRef.afterClosed().toPromise() as Solar.Battery;
            if (!res) {
                return;
            }
            this.battery = res;
            this.batteryChange.emit(res);

            this.batteryName = this.displayFn(this.battery);
        }
        catch (err) {
            this._notify.error(err);
        }
    }
}
